import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ListGroup from "react-bootstrap/ListGroup";

import styles from "./AddressList.module.scss";
import { AddressTypes } from "../../../../models/types";

const formatAddressStr = address =>
  `${address.postcode}, ${address.quickviewAddress}`;

const AddressList = ({ addresses = [], onSelect }) => {
  return (
    <ListGroup variant="flush" className={classNames(styles.address_list)}>
      {addresses.map((address, i) => (
        <ListGroup.Item
          key={i}
          className={styles.result_list_item}
          onClick={() => onSelect(address)}
        >
          <p>{formatAddressStr(address)}</p>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

AddressList.propTypes = {
  onSelect: PropTypes.func,
  addresses: PropTypes.arrayOf(PropTypes.shape(AddressTypes.Address)),
};

export default AddressList;
