import moment from "moment";

import * as validateRegex from "./regex";
import { Fields } from "../../constants/forms";
import { isDateWithinLast10Months, isValidDate } from "./date";

const dateFormat = "DD/MM/YYYY";

export default values => {
  const errors = {};
  if (values.type === Fields.REFERENCE && !values[Fields.REFERENCE]) {
    errors[Fields.REFERENCE] = "Please enter a value for Customer Reference";
  }

  if (
    values.type === Fields.CALLING_CARD_REFERENCE &&
    (!values[Fields.CALLING_CARD_REFERENCE] ||
      !values[Fields.CALLING_CARD_REFERENCE].replace(/\n/g, ""))
  ) {
    errors[Fields.CALLING_CARD_REFERENCE] =
      "Please enter a value for Collection Reference";
  }

  if (
    values.type === Fields.CALLING_CARD_REFERENCE &&
    values[Fields.CALLING_CARD_REFERENCE] &&
    values[Fields.CALLING_CARD_REFERENCE]
      .split("\n")
      .filter(v => v)
      .find(v => ![10, 11, 7].includes(v.length))
  ) {
    errors[Fields.CALLING_CARD_REFERENCE] = "Invalid Reference";
  }

  if (values.type === Fields.COLLECTION_DATE) {
    if (!values[Fields.COLLECTION_DATE] && !values[Fields.COLLECTION_DATE]) {
      errors[Fields.COLLECTION_DATE] = "Please select a value";
    }

    const fromDateField = `${values[Fields.COLLECTION_DATE]}${
      Fields.FROM_DATE
    }`;
    const toDateField = `${values[Fields.COLLECTION_DATE]}${Fields.TO_DATE}`;

    if (
      (!values[fromDateField] && !values[toDateField]) ||
      !isValidDate(values[fromDateField])
    ) {
      errors[fromDateField] = "Please enter a value for From Date";
    }

    if (values[fromDateField]) {
      if (!isValidDate(values[fromDateField])) {
        errors[fromDateField] = "Please enter a valid value for From Date";
      }
      if (!isDateWithinLast10Months(values[fromDateField])) {
        errors[fromDateField] = "Please enter a date within the last 10 months";
      }
    }

    if (values[toDateField]) {
      if (!isValidDate(values[toDateField])) {
        errors[toDateField] = "Please enter a valid value for To Date";
      }
    }

    if (
      values[fromDateField] &&
      values[toDateField] &&
      moment(values[toDateField], dateFormat, true).diff(
        moment(values[fromDateField], dateFormat, true),
        "days"
      ) > 6
    ) {
      errors[fromDateField] = "Dates must be within 7 days range";
    }

    if (
      values[fromDateField] &&
      values[toDateField] &&
      moment(values[toDateField], dateFormat, true).diff(
        moment(values[fromDateField], dateFormat, true),
        "days"
      ) < 0
    ) {
      errors[toDateField] = "To Date must be greater than Start Date";
    }

    if (values.subtype === Fields.EMAIL && !values[Fields.EMAIL]) {
      errors[Fields.EMAIL] = "Please enter a value for Email";
    }

    if (
      values.subtype === Fields.EMAIL &&
      values[Fields.EMAIL] &&
      !values[Fields.EMAIL].match(validateRegex.EMAIL)
    ) {
      errors[Fields.EMAIL] = "Invalid Email";
    }

    if (values.subtype === Fields.PHONE && !values[Fields.PHONE]) {
      errors[Fields.PHONE] = "Please enter a value for Phone";
    }

    if (
      values.subtype === Fields.PHONE &&
      values[Fields.PHONE] &&
      !values[Fields.PHONE].match(validateRegex.PHONE_NUMBER)
    ) {
      errors[Fields.PHONE] = "Invalid Phone";
    }

    if (values.subtype === Fields.POSTCODE && !values[Fields.POSTCODE]) {
      errors[Fields.POSTCODE] = "Please enter a value for Postcode";
    }

    if (
      values.subtype === Fields.POSTCODE &&
      values[Fields.POSTCODE] &&
      !values[Fields.POSTCODE].match(validateRegex.POSTCODE)
    ) {
      errors[Fields.POSTCODE] = "Invalid Postcode";
    }
  }

  return errors;
};
