import { compose } from "recompose";
import { Footer } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { FOOTER } from "./constants/analytics";

export default compose(
  withTrack(FOOTER.LOAD),
  withTrackProps({
    onClick: FOOTER.CLICK_TERMS_AND_CONDITIONS,
  })
)(Footer);
