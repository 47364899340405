import React from "react";
import PropTypes from "prop-types";
import { useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";
import { COLLECTION_DASH_TYPE } from "@dpdgroupuk/redback-enums";

import NavLink from "../NavLink";
import {
  CREATE_COLLECTION,
  DASHBOARD,
  SEARCH,
  WATCH_LIST,
} from "../../constants/strings";
import * as routes from "../../router/constants";

const SecondaryMenu = ({ onSelect }) => {
  const { dpdDomain } = useMyDpdTheme();

  return (
    <div className="nav-secondary-menu">
      <a href={`${dpdDomain}/collection`} onClick={() => onSelect(0)}>
        {CREATE_COLLECTION}
      </a>
      <NavLink href={routes.SEARCH} name={SEARCH} onClick={() => onSelect(1)} />
      <NavLink
        href={routes.DASHBOARD}
        name={DASHBOARD}
        isActive={(_, { pathname }) =>
          pathname.includes("dashboard") &&
          !pathname.includes(COLLECTION_DASH_TYPE.WTCH)
        }
        onClick={() => onSelect(2)}
      />
      <NavLink
        href={`${routes.DASHBOARD}/${COLLECTION_DASH_TYPE.WTCH}`}
        name={WATCH_LIST}
        onClick={() => onSelect(3)}
      />
    </div>
  );
};

SecondaryMenu.propTypes = {
  onSelect: PropTypes.func,
};

export default SecondaryMenu;
