import { combineReducers } from "redux";

import { reducer as dashboard } from "./Dashboard";
import { reducer as collView } from "./Collection";
import { reducer as collEdit } from "./CollectionEdit";

export const uiReducers = combineReducers({
  dashboard,
  collView,
  collEdit,
});
