import { createSelector } from "reselect";

export const getData = state => state.collections;

export const getViewCollections = createSelector(
  getData,
  ({ collections }) => collections
);

export const getTotalCollectionsItems = createSelector(
  getData,
  ({ findByTotal }) => findByTotal
);

export const getFindByCode = createSelector(
  getData,
  ({ findByCode }) => findByCode
);
