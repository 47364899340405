import * as Sentry from "@sentry/browser";
import createSentryMiddleware from "redux-sentry-middleware";

const {
  REACT_APP_SENTRY_DSN,
  REACT_APP_SENTRY_RELEASE,
  REACT_APP_SENTRY_ENV,
} = process.env;

Sentry.init({
  debug: process.env.NODE_ENV === "development",
  enabled: process.env.NODE_ENV === "production",
  release: REACT_APP_SENTRY_RELEASE,
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  ignoreErrors: [
    // contextmenu error for electron (v.0.37.8)
    "Cannot read property 'nodeType' of undefined",
    "SecurityError",
    // Random plugins/extensions
    "top.GLOBALS",
    "Can't find variable: ga",
    /(ResizeObserver loop limit exceeded)/,
  ],
  ignoreUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
});

export const sentryMiddleware = createSentryMiddleware(Sentry, {
  filterBreadcrumbActions: action => !action.type.startsWith("@@redux-form"),
});

export default Sentry;
