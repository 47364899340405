import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Popover } from "@dpdgroupuk/mydpd-ui";

import "./overridedDropdownStyles.scss";
import styles from "./CustomDatePicker.module.scss";
import { delay } from "../utils";

const CustomDatePicker = ({
  label,
  placeholderText,
  input,
  classNameContainer,
  classNameLabel,
  classNameField,
  disabled,
  meta,
  dateFormat,
  ...inputProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);

  const onSelect = data => {
    setIsOpen(false);
    input.onChange(moment(data).format(dateFormat));
    delay(input.onBlur);
  };
  return (
    <div
      className={classNames(
        styles.container,
        classNameContainer && classNameContainer
      )}
    >
      <label
        htmlFor={label}
        className={classNames(styles.label, classNameLabel && classNameLabel)}
      >
        {label}
      </label>
      <div className={classNames(styles.content, classNameField)}>
        <div
          className={classNames(styles.icon, disabled && styles.icon_disabled)}
          onMouseDown={() => {
            !disabled && setIsOpen(!isOpen);
          }}
        />
        <Popover isOpen={meta.error && meta.touched} content={meta.error}>
          <DatePicker
            minDate={moment()
              .add(-10, "months")
              .toDate()}
            className={classNames(
              styles.input,
              meta.error && meta.touched && styles.invalid
            )}
            ref={inputRef}
            open={isOpen}
            disabled={disabled}
            disabledKeyboardNavigation
            dateFormat={dateFormat}
            value={input.value}
            customInput={<input />}
            maxLength={10}
            selected={
              moment(input.value, dateFormat, true).isValid()
                ? moment(input.value, dateFormat).toDate()
                : new Date()
            }
            onSelect={onSelect}
            placeholderText={placeholderText}
            onCalendarOpen={() => {
              inputRef.current.handleBlur = e => {
                inputRef.current.props.onBlur(e);
              };
            }}
            {...inputProps}
            onBlur={() => {
              delay(input.onBlur);
            }}
            onFocus={() => {
              setIsOpen(false);
              input.onFocus();
              inputRef.current.handleBlur = e => {
                inputRef.current.props.onBlur(e);
              };
            }}
            onChangeRaw={event => {
              input.onChange(event.target.value);
              event.preventDefault();
            }}
            onClickOutside={() => {
              setIsOpen(false);
              delay(input.onBlur);
            }}
          />
        </Popover>
      </div>
    </div>
  );
};

CustomDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  input: PropTypes.object,
  meta: PropTypes.object,
  onChange: PropTypes.func,
  placeholderText: PropTypes.string,
  inputProps: PropTypes.object,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameField: PropTypes.string,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string,
};

CustomDatePicker.defaultProps = {
  dateFormat: "DD/MM/YYYY",
};

export default CustomDatePicker;
