import { connect } from "react-redux";
import { compose, withProps } from "recompose";

import { searchCollections } from "./actions";
import { COLLECTIONS } from "../../router";
import { createLocationState, stringifyQuery } from "../../utils/query";

export default compose(
  connect(null, {
    searchCollections,
  }),
  withProps(({ history, searchCollections }) => ({
    searchHandler: async (values, fetchOptions) => {
      const {
        collectionCodes = [],
        findByCode,
        expiresIn,
      } = await searchCollections(values, undefined, fetchOptions);
      if (collectionCodes.length === 1) {
        history.push(`${COLLECTIONS}/${collectionCodes[0]}`);
      } else {
        history.push(
          `${COLLECTIONS}?${stringifyQuery(values)}`,
          createLocationState({
            searchFindByCode: findByCode,
            expiresIn,
          })
        );
      }
    },
  }))
);
