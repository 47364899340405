const createPageMeta = (interfaceId, rest = {}) => ({
  INTERFACE_ID: interfaceId,
  ...rest,
});

export const SEARCH_COLLECTION = createPageMeta("VPNWlnaD.AAAAZLc", {
  LOAD: "jE2yEXaD.AAAAX7J",
  ON_SEARCH: "4WkKEXaD.AAAAX74",
  ACCOUNT_SELECTION: "WUZKkXaD.AAAASfY",
  COLLECTION_DATE_SELECTION: "HqU7SXaD.AAAAafM",
  COLLECTION_REFERENCE_ENTRY: "zCrFTXaD.AAAAVYI",
  COLLECTION_REFERENCE_TOGGLE: "1NFV03aD.AAAAUrz",
  CREATION_DATE_SELECTION: "KcE_bXaD.AAAAR5H",
  CUSTOMER_REFERENCE_TOGGLE: "QzvV03aD.AAAAUtV",
  DATE_TOGGLE: "JtZeL3aD.AAAAVzJ",
  EMAIL_ADDRESS_ENTRY: "zczlTXaD.AAAAVY0",
  EMAIL_TOGGLE: "gnI503aD.AAAATgA",
  PHONE_NUMBER_ENTRY: "F5HlTXaD.AAAAVZB",
  PHONE_NUMBER_TOGGLE: "Iu9F03aD.AAAATxc",
  POSTCODE_ENTRY: "KFAUkXaD.AAAARv3",
  POSTCODE_TOGGLE: "LqBl03aD.AAAAUSg",
});

export const FIND_POSTCODE_MODAL = createPageMeta("MDynjXaD.AAAAQW6", {
  LOAD: "XT93jXaD.AAAAZ5S",
  CLICK_ADDRESS: "mYcGTXaD.AAAAQ7v",
  CLICK_CLEAR: "iD4PjXaD.AAAAZ75",
  CLICK_FIND_POSTCODE: "8d0PjXaD.AAAAZ8F",
});

export const SEARCH_RESULT_LIST = createPageMeta("yTFtdnaD.AAAAR9e");

export const DASHBOARD = createPageMeta("L8IWcXaD.AAAAZc5", {
  LOAD: "8GnAcPaD.AAAAczB",
  DASHBOARD: "L8IWcXaD.AAAAZc5",
  CLICK_CANCELLED_REBOOKED: "OJ0hcPaD.AAAAcm2",
  CLICK_CARD_LEFT: "SkIhcPaD.AAAAcmi",
  CLICK_DELAYED: "s5ghcPaD.AAAAcmZ",
  CLICK_OUT_FOR_COLLECTION: "QhXBcPaD.AAAAcmA",
  CLICK_PENDING: "e43ccPaD.AAAAayV",
  CLICK_UNSUCCESSFUL: "TPYhcPaD.AAAAcmt",
  CLICK_FAILED: "0JMxY06D.AAAATJz",
  CLICK_WATCHING: "KcChcPaD.AAAAcnD",
  CLICK_COLLECTED: "MIMvdk6D.AAAAYo8",
});

export const PENDING_COLLECTIONS_VIEW = createPageMeta("vGR2cXaD.AAAAZr.", {
  LOAD: "R..tyPaD.AAAAXCu",
});

export const CARD_LEFT_COLLECTIONS_VIEW = createPageMeta("Pa0xcXaD.AAAAZ4L", {
  LOAD: "_N5WKPaD.AAAAQLZ",
});

export const UNSUCCESSFUL_COLLECTIONS_VIEW = createPageMeta(
  "5KWxcXaD.AAAAZ5T",
  {
    LOAD: "3nBYKPaD.AAAAZbn",
  }
);

export const COLLECTED_COLLECTIONS_VIEW = createPageMeta("JypxcXaD.AAAAZ59", {
  LOAD: "sgR6KPaD.AAAAc2T",
});

export const FAILED_COLLECTIONS_VIEW = createPageMeta("E7glcXaD.AAAAZ7J", {
  LOAD: "Ok14KPaD.AAAAaZJ",
});

export const CANCELED_REBOOKED_COLLECTIONS_VIEW = createPageMeta(
  "1BylcXaD.AAAAZ7r",
  {
    LOAD: "IVxGKPaD.AAAAdWx",
  }
);

export const WATCHED_COLLECTIONS_VIEW = createPageMeta("amDbcXaD.AAAAZ8n", {
  LOAD: "55ZDyPaD.AAAAYlE",
});

export const OUT_FOR_COLLECTION = createPageMeta("2g4p9naD.AAAAVky", {
  LOAD: "LxuLlfaD.AAAAWzi",
});

export const GENERAL_VIEW = createPageMeta("XvD89naD.AAAAVWC", {
  LOAD: "5N.flfaD.AAAAYXd",
});

export const COLLECTION_DETAILS_COMPONENT = {
  LOAD: "hKgP8faD.AAAATGn",
  CLICK_QUANTITY: "HD6P8faD.AAAATx2",
};

export const COLLECTION_HISTORY_COMPONENT = {
  LOAD: "xV.dXvaD.AAAAWpr",
  CLICK_IMAGE: "QY.oofaD.AAAAXgx",
  CLICK_MAP: "IhxoofaD.AAAAXg2",
  CLICK_SHOW: "XsqwofaD.AAAAWEg",
  CLICK_HIDE: "OKwIofaD.AAAAWuK",
};

export const COLLECTION_OPTIONS_COMPONENT = {
  LOAD: "w2XSb_aD.AAAASZW",
  CLICK_ADD_TO_WATCH_LIST: "xtN6b_aD.AAAAT2q",
  CLICK_REMOVE_FROM_WATCH_LIST: "STjGb_aD.AAAAT5_",
  CLICK_CANCEL: "h.X6b_aD.AAAAT3b",
  CLICK_CHANGE_DATE: "hC0Gb_aD.AAAAT4M",
  CLICK_COLLECT_FROM_NEIGHBOUR: "boaGb_aD.AAAAT4n",
  CLICK_COLLECT_FROM_SAFE_PLACE: "39eGb_aD.AAAAT5E",
  CLICK_COLLECTION_INSTRUCTION: "fdlGb_aD.AAAAT5s",
  CLICK_REBOOK_COLLECTION: "kQdKJw6D.AAAARGX",
};

export const CHANGE_COLLECTION_DATE_COMPONENT = {
  LOAD: "jKUTN_aD.AAAAVQG",
  CLICK_CONTACT_NAME: "9_a0j_aD.AAAAYCf",
  CLICK_EMAIL: "tWW0j_aD.AAAAYCl",
  CLICK_SMS: "MeO0j_aD.AAAAYCr",
  CLICK_DATE: "7G_Yt_aD.AAAAV8F",
  CLICK_INSTRUCTIONS: "Yp5XD_aD.AAAAWiH",
  CLICK_SUBMIT: ".WlvD_aD.AAAAW77",
  CLICK_BACK: "pXqoj_aD.AAAAXvw",
};

export const REBOOK_COLLECTION_COMPONENT = {
  LOAD: "w7S4vg6D.AAAAStn",
  CLICK_CONTACT_NAME: "0Iy4vg6D.AAAASvX",
  CLICK_EMAIL: "h4y4vg6D.AAAASvv",
  CLICK_SMS: "1oy4vg6D.AAAASvj",
  CLICK_DATE: "mXS4vg6D.AAAASuH",
  CLICK_INSTRUCTIONS: "uPS4vg6D.AAAASuT",
  CLICK_SUBMIT: "AfS4vg6D.AAAASuf",
  CLICK_BACK: "eQy4vg6D.AAAASvN",
};

export const CHANGE_COLLECTION_INSTRUCTION_COMPONENT = {
  LOAD: "90HAb_aD.AAAAXDH",
  CLICK_CONTACT_NAME: "URWQb_aD.AAAAXUg",
  CLICK_EMAIL: ".JWQb_aD.AAAAXUs",
  CLICK_SMS: "wFWQb_aD.AAAAXVG",
  CLICK_INSTRUCTIONS: "gZWQb_aD.AAAAXU4",
  CLICK_SUBMIT: "Utkwb_aD.AAAAXXu",
  CLICK_BACK: "reWQb_aD.AAAAXUU",
};

export const COLLECT_FROM_NEIGHBOUR_COMPONENT = {
  LOAD: "KKOGT_aD.AAAAd_0",
  CHANGE_CONTACT_NAME: "YpQ2T_aD.AAAAed0",
  CHANGE_EMAIL_ADDRESS: "tZQ2T_aD.AAAAed_",
  CHANGE_MOBILE_NUMBER: "glQ2T_aD.AAAAeeK",
  SELECT_DATE: "Re9WT_aD.AAAAeRQ",
  SELECT_ADDRESS: "HsxHr_aD.AAAAVmg",
  CHANGE_INSTRUCTIONS: "jVQ2T_aD.AAAAeeV",
  CLICK_SUBMIT: "cDQ2T_aD.AAAAeet",
  CLICK_BACK: "ftQ2T_aD.AAAAeei",
};

export const CHANGE_SAFE_PLACE_COMPONENT = {
  LOAD: "g8_Oj_aD.AAAAa4Z",
  CLICK_CONTACT_NAME: "uMQej_aD.AAAAbk1",
  CLICK_EMAIL: "v8Qej_aD.AAAAbk_",
  CLICK_SMS: "TSQej_aD.AAAAblJ",
  CLICK_DATE: "iKQej_aD.AAAAblT",
  CLICK_INSTRUCTIONS: "AaQej_aD.AAAAbld",
  CLICK_SUBMIT: "UPo.j_aD.AAAAcXs",
  CLICK_BACK: "s0Qej_aD.AAAAbkr",
};

export const CANCEL_COLLECTION_COMPONENT = {
  LOAD: "KKOGT_aD.AAAAd_0",
  CHANGE_CONTACT_NAME: "cSWFY06D.AAAAbPL",
  CHANGE_EMAIL_ADDRESS: "MqWFY06D.AAAAbPX",
  CHANGE_MOBILE_NUMBER: "F6WFY06D.AAAAbPj",
  CLICK_SUBMIT: "KwAFY06D.AAAAbN1",
  CLICK_BACK: "euWFY06D.AAAAbP7",
};

export const MENU = {
  LOAD: "nb_PSXaD.AAAAdCJ",
  CLICK_ADDRESS_BOOK: "JpWuyXaD.AAAARFD",
  CLICK_COLLECTIONS: "2tiOyXaD.AAAAeTY",
  CLICK_DPD_LOGO: "3WQ5yXaD.AAAATDC",
  CLICK_DELIVERIES: "Op9OyXaD.AAAAeo4",
  CLICK_DEPOT_FINDER: "z1euyXaD.AAAARFo",
  CLICK_HELP: "n9FuyXaD.AAAARGk",
  CLICK_LOG_OFF: "h8tuyXaD.AAAARG6",
  CLICK_MYDPD: "ISlmyXaD.AAAAd2H",
  CLICK_SHIPPING: "UKB2yXaD.AAAAeF3",
  CLICK_SHOP_RETURNS: "vmquyXaD.AAAAREm",
};

export const COLLECTIONS_MENU = {
  CLICK_SEARCH: "yYWVyXaD.AAAAToT",
  CLICK_DASHBOARD: "Vy5zyXaD.AAAAUK3",
  CLICK_WATCH_LIST: "zQ0eTXaD.AAAASg0",
  CLICK_CREATE_COLLECTION: "EWdzyXaD.AAAAUMW",
};

export const MAP_VIEW = {
  LOAD: "7zXMCfaD.AAAAWqf",
  CLOSE_MAP: "TODO: not available",
};

export const ETA_BAR = {
  LOAD: "y_OQJfaD.AAAAdqY",
};

export const COLLECTION_STATUS = {
  LOAD: "CLDQCfaD.AAAAVW2",
};

export const SHIPMENT_DETAILS = {
  LOAD: "WQ_lJfaD.AAAASVS",
};

export const FOOTER = {
  LOAD: "PGqBTXaD.AAAAUAH",
  CLICK_TERMS_AND_CONDITIONS: "Y_uRTXaD.AAAAUXr",
};

export const SEARCH_FILTER_LIST = {
  LOAD: "3bVPyXaD.AAAAVfY",
  ACCOUNT_SELECTION: "VpZA43aD.AAAAV8J",
  CLICK_COLLECTED: "7MeK43aD.AAAAX62",
  CLICK_COLLECTION: "vdLPyXaD.AAAAVgD",
  CLICK_COLLECTION_COUNTRY: "IDUI43aD.AAAAWvV",
  CLICK_COLLECTION_DEPOTS: "iaMK43aD.AAAAX6C",
  CLICK_EXPORT: ".nypY3aD.AAAAWOM",
  CLICK_FILTER: "OMopM3aD.AAAATAX",
  CLICK_FIND_POSTCODE: "BabQ43aD.AAAAWhe",
  CLICK_NEXT_PAGE: "xt8ni3aD.AAAAbA6",
  CLICK_OUT_FOR_COLLECTION_TODAY: "ti6K43aD.AAAAX6e",
  CLICK_PREVIOUS_PAGE: "E3uni3aD.AAAAbBl",
  CLICK_REMOVE_FROM_WATCH_LIST: "5kZGJw6D.AAAARGc",
  CLICK_SORT_BY: "9fUa43aD.AAAAX7P",
};

export const SEARCH = {
  LOAD: "LD8ap46D.AAAAYev",
  CLICK_SEARCH: "zrDAM3aD.AAAAYOh",
  CLICK_SEARCH_SELECTOR: "VF.AM3aD.AAAAYD_",
};

export const COLLECTION_IMAGE_VIEW = {
  LOAD: "5YxbofaD.AAAAZy8",
  CLOSE_IMAGE: "i8qPofaD.AAAAb9n",
  CLICK_NEXT: "yvaPofaD.AAAAb9s",
  CLICK_PREV: "ygmPofaD.AAAAb9x",
};
