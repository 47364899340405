import React, { useEffect } from "react";
import { bindActionCreators, compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import {
  Field,
  formValueSelector,
  propTypes,
  reduxForm,
  SubmissionError,
  untouch,
} from "redux-form";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";
import { Page, withOverlay } from "@dpdgroupuk/mydpd-ui";

import {
  ACCOUNT,
  ALL_ACCOUNTS,
  COLLECTION_CALLING_CARD_REFERENCE,
  COLLECTION_SEARCH,
  CUSTOMER_REFERENCE,
  DATE,
  EMAIL_ADDRESS,
  FROM_DATE,
  PHONE_NUMBER,
  PLEASE_CHOOSE_A_FROM_DATE,
  PLEASE_CHOOSE_A_TO_DATE,
  POSTCODE,
  SEARCH,
  TO_DATE,
} from "../../../constants/strings";
import { COLLECTION_SEARCH_FORM, Fields } from "../../../constants/forms";
import Dropdown from "../../../components/Dropdown";
import PostcodeFinderInput from "../../../features/PostcodeFinder";
import InputWithTitle from "../../../components/InputWithTitle";
import TypedRadioInput from "../../../components/TypedRadioInput";
import CustomDatePicker from "../../../components/CustomDatePicker";
import FormBottomSection from "../../../components/FormBottomSection";
import validate from "../../../models/validators/findCollections";
import styles from "./Form.module.scss";
import {
  initializeForm,
  onCollectionDateChange,
  onStartDateChange,
  onSubTypeChange,
  onTypeChange,
} from "../actions";
import InnerLayout from "../../../components/InnerLayout";
import { SEARCH_COLLECTION } from "../../../constants/analytics";
import { COLLECTION_DATE_OPTIONS } from "../../../models/enum";
import { useAuthUser, withAuthUser } from "../../../components/AuthUser";
import { transformSearchFormValues } from "../model";
import { isValidStartDate } from "../selectors";
import withSearchHandler from "../withSearchHandler";

const Form = props => {
  const { username } = useAuthUser();
  useEffect(() => {
    props.initializeForm();
  }, []);

  return (
    <Page title={COLLECTION_SEARCH} username={username}>
      <InnerLayout>
        <div className={styles.content}>
          <div className={styles.account_container}>
            <Field
              component={Dropdown}
              label={`${ACCOUNT}:*`}
              name={Fields.ACCOUNT}
              placeholder={ALL_ACCOUNTS}
              values={props.accounts}
              onBlur={props.onFieldEntry}
            />
          </div>

          <Field
            name="type"
            value={Fields.CALLING_CARD_REFERENCE}
            type="radio"
            component={TypedRadioInput}
            onChange={props.onTypeChange}
          >
            <Field
              name={Fields.CALLING_CARD_REFERENCE}
              component={InputWithTitle}
              label={COLLECTION_CALLING_CARD_REFERENCE}
              multiline
              rows="3"
              maxLength={255}
              normalize={(value, previousValue) => {
                const v = value === undefined ? previousValue : value;
                return (v || "").replace(/[^0-9\n]/g, "");
              }}
              onBlur={props.onFieldEntry}
            />
          </Field>
          <Field
            name="type"
            value={Fields.REFERENCE}
            type="radio"
            component={TypedRadioInput}
            onChange={props.onTypeChange}
          >
            <Field
              name={Fields.REFERENCE}
              component={InputWithTitle}
              label={`${CUSTOMER_REFERENCE}:`}
              maxLength={25}
              onBlur={props.onFieldEntry}
            />
          </Field>

          <Field
            name="type"
            type="radio"
            value={Fields.COLLECTION_DATE}
            component={TypedRadioInput}
            onChange={props.onTypeChange}
          >
            {inputProps => (
              <div className={styles.container}>
                <Field
                  name={Fields.COLLECTION_DATE}
                  component={Dropdown}
                  placeholder="Please Select"
                  label={`${DATE}:`}
                  values={COLLECTION_DATE_OPTIONS}
                  disabled={inputProps.disabled}
                  onChange={props.onCollectionDateChange}
                />

                <div
                  className={classNames(
                    styles.date_content,
                    styles.inner_content
                  )}
                >
                  <Field
                    name={`${props.dateType}${Fields.FROM_DATE}`}
                    component={CustomDatePicker}
                    label={`${FROM_DATE}:`}
                    placeholderText={PLEASE_CHOOSE_A_FROM_DATE}
                    showDisabledMonthNavigation
                    disabled={inputProps.disabled || !props.dateType}
                    onChange={props.onStartDateChange}
                  />
                  <Field
                    name={`${props.dateType}${Fields.TO_DATE}`}
                    component={CustomDatePicker}
                    label={`${TO_DATE}:`}
                    placeholderText={PLEASE_CHOOSE_A_TO_DATE}
                    showDisabledMonthNavigation
                    disabled={inputProps.disabled || !props.dateType}
                  />
                </div>

                {!inputProps.disabled &&
                  props.dateType &&
                  props.isValidStartDate && (
                    <div
                      className={classNames(
                        styles.collection_date_content,
                        styles.inner_content
                      )}
                    >
                      <Field
                        name="subtype"
                        type="radio"
                        value={Fields.EMAIL}
                        component={TypedRadioInput}
                        onChange={props.onSubTypeChange}
                      >
                        <Field
                          name={Fields.EMAIL}
                          component={InputWithTitle}
                          label={`${EMAIL_ADDRESS}:`}
                          maxLength={255}
                          onBlur={props.onFieldEntry}
                        />
                      </Field>
                      <Field
                        name="subtype"
                        type="radio"
                        value={Fields.PHONE}
                        component={TypedRadioInput}
                        onChange={props.onSubTypeChange}
                      >
                        <Field
                          name={Fields.PHONE}
                          component={InputWithTitle}
                          type="tel"
                          label={`${PHONE_NUMBER}:`}
                          minLength={7}
                          maxLength={15}
                          onBlur={props.onFieldEntry}
                        />
                      </Field>
                      <Field
                        name="subtype"
                        type="radio"
                        value={Fields.POSTCODE}
                        component={TypedRadioInput}
                        onChange={props.onSubTypeChange}
                      >
                        {inputProps => (
                          <Field
                            name={Fields.POSTCODE}
                            component={PostcodeFinderInput}
                            label={`${POSTCODE}:`}
                            maxLength={255}
                            classNameField={styles.postcode_field}
                            disabled={inputProps.disabled}
                            onBlur={props.onFieldEntry}
                          />
                        )}
                      </Field>
                    </div>
                  )}
              </div>
            )}
          </Field>
        </div>
      </InnerLayout>

      <FormBottomSection
        text={SEARCH}
        disabled={props.submitting || props.invalid}
        onClick={props.handleSubmit(props.onSubmitSuccess)}
      >
        <div className={styles.error}>{props.error}</div>
      </FormBottomSection>
    </Page>
  );
};

Form.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.string),
  dateType: PropTypes.any,
  onTypeChange: PropTypes.func,
  onFieldEntry: PropTypes.func, // only for analytics
  onSearchPress: PropTypes.func,
  initializeForm: PropTypes.func,
  onSubTypeChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onCollectionDateChange: PropTypes.func,
  isValidStartDate: PropTypes.bool,
  ...propTypes,
};

const selector = formValueSelector(COLLECTION_SEARCH_FORM);

export default compose(
  reduxForm({
    form: COLLECTION_SEARCH_FORM,
    validate,
    onSubmitFail: (errors, dispatch) => {
      // redux-form marks all fields as touched on handleSubmit
      // We must show only first error on submit
      Object.keys(errors).forEach((field, index) => {
        if (index > 0) {
          dispatch(untouch(COLLECTION_SEARCH_FORM, field));
        }
      });
    },
  }),
  withOverlay,
  withAuthUser,
  withSearchHandler,
  connect(
    (state, { authUser }) => ({
      accounts: authUser.accounts,
      dateType: selector(state, Fields.COLLECTION_DATE),
      isValidStartDate: isValidStartDate(state),
    }),
    (dispatch, { overlay, history, searchHandler }) => ({
      ...bindActionCreators(
        {
          onTypeChange,
          initializeForm,
          onSubTypeChange,
          onStartDateChange,
          onCollectionDateChange,
        },
        dispatch
      ),

      onSubmitSuccess: overlay.showWhile(async values => {
        try {
          const formValues = transformSearchFormValues(values);
          await searchHandler(formValues);
        } catch (e) {
          throw new SubmissionError({
            _error: "No collections were found using that search criteria",
          });
        }
      }),
    })
  ),
  withTrack(trackProps(SEARCH_COLLECTION)),
  withTrackProps({
    onTypeChange: e => {
      switch (e.target.value) {
        case Fields.CALLING_CARD_REFERENCE:
          return SEARCH_COLLECTION.COLLECTION_REFERENCE_TOGGLE;
        case Fields.COLLECTION_DATE:
          return SEARCH_COLLECTION.DATE_TOGGLE;
        case Fields.REFERENCE:
          return SEARCH_COLLECTION.CUSTOMER_REFERENCE_TOGGLE;
      }
    },
    onSubTypeChange: e => {
      switch (e.target.value) {
        case Fields.EMAIL:
          return SEARCH_COLLECTION.EMAIL_TOGGLE;
        case Fields.POSTCODE:
          return SEARCH_COLLECTION.EMAIL_TOGGLE;
        case Fields.PHONE:
          return SEARCH_COLLECTION.PHONE_NUMBER_TOGGLE;
      }
    },
    onCollectionDateChange: e => {
      switch (e.target.value) {
        case "collection":
          return SEARCH_COLLECTION.COLLECTION_DATE_SELECTION;
        case "create":
          return SEARCH_COLLECTION.CREATION_DATE_SELECTION;
      }
    },
    onFieldEntry: (event, newValue, previousValue, name) => {
      switch (name) {
        case Fields.ACCOUNT:
          return SEARCH_COLLECTION.ACCOUNT_SELECTION;
        case Fields.CALLING_CARD_REFERENCE:
          return SEARCH_COLLECTION.COLLECTION_REFERENCE_ENTRY;
        case Fields.EMAIL:
          return SEARCH_COLLECTION.EMAIL_ADDRESS_ENTRY;
        case Fields.PHONE:
          return SEARCH_COLLECTION.PHONE_NUMBER_ENTRY;
        case Fields.POSTCODE:
          return SEARCH_COLLECTION.POSTCODE_ENTRY;
      }
    },
    onSubmitSuccess: SEARCH_COLLECTION.ON_SEARCH,
  })
)(Form);
