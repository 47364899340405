import { COLLECTION_DASH_TYPE } from "@dpdgroupuk/redback-enums";

const createDashboardStatEnum = (title, amount, id) => ({
  title,
  amount,
  id,
});

export const transformDashboardStats = stats => [
  createDashboardStatEnum(
    "Pending",
    stats.pendingTotal,
    COLLECTION_DASH_TYPE.PEND
  ),
  createDashboardStatEnum(
    "Card Left",
    stats.cardLeftTotal,
    COLLECTION_DASH_TYPE.CARD
  ),
  createDashboardStatEnum(
    "Unsuccessful",
    stats.unsuccessfulTotal,
    COLLECTION_DASH_TYPE.UNS
  ),
  createDashboardStatEnum(
    "Collected",
    stats.collectedTotal,
    COLLECTION_DASH_TYPE.COLL
  ),
  createDashboardStatEnum(
    "Failed",
    stats.failedTotal,
    COLLECTION_DASH_TYPE.FAIL
  ),
  createDashboardStatEnum(
    "Cancelled/ Rebooked",
    stats.canceledRebookedTotal,
    COLLECTION_DASH_TYPE.CANC
  ),
  createDashboardStatEnum(
    "Watching",
    stats.watchingTotal,
    COLLECTION_DASH_TYPE.WTCH
  ),
];
