import PropTypes from "prop-types";
import { values } from "lodash";
import {
  COLLECTION_STATUS,
  COLLECTION_TYPE,
  IMAGE_TYPE,
} from "@dpdgroupuk/redback-enums";

export const DeliveryAddress = {
  street: PropTypes.string,
  suburb: PropTypes.string,
  town: PropTypes.string,
  city: PropTypes.string,
  county: PropTypes.string,
  postcode: PropTypes.string,
  country: PropTypes.string,
  countryName: PropTypes.string,
  // undocumented
  organisation: PropTypes.string, // old delivery.deliveryName
};

export const Delivery = {
  quantity: PropTypes.number,
  weight: PropTypes.number,
  bulk: PropTypes.string,
  businessUnit: PropTypes.number,
  accountCode: PropTypes.string,
  customerReference: PropTypes.string,
  deliveryName: PropTypes.string,
  address: PropTypes.shape(DeliveryAddress),
  contact: PropTypes.string,
  notifyEmail: PropTypes.string,
  notifySms: PropTypes.string,
  phone: PropTypes.string,
  info: PropTypes.string,
  product: PropTypes.string,
  productName: PropTypes.string,
  service: PropTypes.string,
  serviceName: PropTypes.string,
  insurance: PropTypes.bool,
  insuranceValue: PropTypes.number,
  parcels: PropTypes.string,
  importReference: PropTypes.string,
  importParcels: PropTypes.string,
  position: PropTypes.string,
};

export const Customer = {
  name: PropTypes.string,
  logo: PropTypes.string,
  displayName: PropTypes.string,
};

export const CollectionAddress = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  postcode: PropTypes.string,
  street: PropTypes.string,
  suburb: PropTypes.string,
  city: PropTypes.string,
  countryName: PropTypes.string,
  // undocumented
  organisation: PropTypes.string,
  property: PropTypes.string,
  town: PropTypes.string,
  county: PropTypes.string,
};

export const CollectionEvent = {
  pos: PropTypes.number,
  offset: PropTypes.number,
  date: PropTypes.string,
  time: PropTypes.string,
  depotCode: PropTypes.string,
  depotName: PropTypes.string,
  depotType: PropTypes.string,
  routeNo: PropTypes.number,
  routeTime: PropTypes.number,
  stopNo: PropTypes.number,
  saturnCode: PropTypes.string,
  code: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  userCode: PropTypes.string,
  userName: PropTypes.string,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  notes: PropTypes.string,
  capturedName: PropTypes.string,
  cardNo: PropTypes.string,
  imageType: PropTypes.oneOf(values(IMAGE_TYPE)),
  imageKey: PropTypes.string,
  imagePath: PropTypes.string,
  imageCaption: PropTypes.string,
  imageNotes: PropTypes.string,
  createDate: PropTypes.string,
  createTime: PropTypes.string,
  origin: PropTypes.string,
  originName: PropTypes.string,
  userType: PropTypes.string,
  userTypeName: PropTypes.string,
  carrierCode: PropTypes.string,
  packetName: PropTypes.string,
};

export const Route = {
  totalStopsCompleted: PropTypes.number,
  routeStopSequence: PropTypes.number,
  timeToStop: PropTypes.string,
  driverDisplayName: PropTypes.string,
  routeStatus: PropTypes.string,
};

export const Collection = {
  createDate: PropTypes.string,
  createTime: PropTypes.string,
  account: PropTypes.string,
  collectionDate: PropTypes.string,
  customerReference: PropTypes.arrayOf(PropTypes.string),
  collectionReference: PropTypes.string,
  collectionType: PropTypes.string,
  collectionStatus: PropTypes.string,
  collectionStatusFull: PropTypes.string,
  collectionName: PropTypes.string,
  routeTime: PropTypes.number,
  readyTime: PropTypes.string,
  closeTime: PropTypes.string,
  etaStartTime: PropTypes.string,
  etaEndTime: PropTypes.string,
  contact: PropTypes.string,
  notifySms: PropTypes.string,
  notifyEmail: PropTypes.string,
  instructions: PropTypes.string,
  phoneNumber: PropTypes.string,
  deliveries: PropTypes.arrayOf(PropTypes.shape(Delivery)),
  customer: PropTypes.shape(Customer),
  collectionAddress: PropTypes.shape(CollectionAddress),
  collectedQuantity: PropTypes.number,
  deliverQuantity: PropTypes.number,
  collectionEvents: PropTypes.arrayOf(PropTypes.shape(CollectionEvent)),
  routeDetails: PropTypes.shape(Route),
  // additional params which are required on FE
  collectionStatusType: PropTypes.oneOf(values(COLLECTION_STATUS)),
  collectionTypeCode: PropTypes.oneOf(values(COLLECTION_TYPE)),
  collectionCode: PropTypes.string.isRequired,
  collectionNumber: PropTypes.string,
};
