import React from "react";
import { flatten } from "lodash";
import PropTypes from "prop-types";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { CellPropsTypes } from "@dpdgroupuk/mydpd-ui";

import { CollectionTypes } from "../../../../models/types";
import { COLLECTION_HISTORY_COMPONENT } from "../../../../constants/analytics";
import ImageView from "./CollectionImageView";
import { getImageUrl } from "../../model";
import photoImg from "../../../../assets/icons/photo.svg";

const ImageViewCell = ({ row, onClickCollectionImage }) => {
  const [showImage, setShowImage] = React.useState(false);
  const { imageType, imageKey, imageCaption } = row;
  const images = React.useMemo(() => {
    const imageProps = {
      imageTypes: flatten([imageType]),
      imageKeys: flatten([imageKey]),
      imageCaptions: flatten([imageCaption]),
    };
    return imageProps.imageKeys.map((imageKey, index) => ({
      src: getImageUrl(imageKey, imageProps.imageTypes[index]),
      title: imageProps.imageCaptions[index],
    }));
  }, [row]);

  return (
    <>
      {imageType && imageKey && (
        <img
          className="cursor-pointer"
          alt="img"
          src={photoImg}
          onClick={() => {
            onClickCollectionImage();
            setShowImage(true);
          }}
        />
      )}
      <ImageView
        images={images}
        show={showImage}
        carouselProps={{
          indicators: false,
        }}
        onClose={() => {
          setShowImage(false);
        }}
      />
    </>
  );
};

ImageViewCell.propTypes = {
  onClick: PropTypes.func,
  ...CellPropsTypes,
  row: PropTypes.shape(CollectionTypes.CollectionEvent),
};

export default withTrackProps({
  onClickCollectionImage: COLLECTION_HISTORY_COMPONENT.CLICK_IMAGE,
})(ImageViewCell);
