import React from "react";
import PropTypes from "prop-types";
import { BorderedTable } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { formatDDMMMYYYY } from "../../../../utils/date";
import { CollectionTypes } from "../../../../models/types";
import { formatFullAddressString } from "../../../../models/address";
import { getContactSmsPhoneEmail } from "../../../../models/collection";
import CollectionQuantityCell from "./CollectionQuantityCell";
import { COLLECTION_DETAILS_COMPONENT } from "../../../../constants/analytics";

const columns = [
  {
    text: "Created Date",
    render: ({ createDate }) => formatDDMMMYYYY(createDate),
    style: {
      width: "90px",
    },
  },
  {
    text: "Collection Date",
    render: ({ collectionDate }) => formatDDMMMYYYY(collectionDate),
    style: {
      width: "100px",
    },
  },
  {
    text: "Ready - Closed",
    render: ({ readyTime, closeTime }) => `${readyTime} - ${closeTime}`,
    style: {
      width: "100px",
    },
  },
  {
    text: "Customer ref",
    dataIndex: "customerReference",
    style: {
      width: "92px",
    },
  },
  {
    text: "Type",
    dataIndex: "collectionType",
    style: {
      width: "75px",
    },
  },
  {
    text: "Account",
    dataIndex: "account",
    style: {
      width: "72px",
    },
  },
  {
    text: "Address",
    render: ({ collectionAddress }) =>
      formatFullAddressString(collectionAddress),
    style: {
      width: "100px",
    },
  },
  {
    text: "Contact",
    render: getContactSmsPhoneEmail,
  },
  {
    text: "Instructions",
    dataIndex: "instructions",
    style: {
      width: "85px",
    },
  },
  {
    text: "Quantity",
    style: {
      width: "65px",
    },
    component: CollectionQuantityCell,
  },
];

const CollectionDetails = ({ collection }) => (
  <BorderedTable columns={columns} data={[collection]} />
);

CollectionDetails.propTypes = {
  collection: PropTypes.shape(CollectionTypes.Collection),
};

export default withTrack(COLLECTION_DETAILS_COMPONENT.LOAD)(CollectionDetails);
