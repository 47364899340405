import React from "react";
import PropTypes from "prop-types";
import { Field, propTypes, reduxForm } from "redux-form";
import Spinner from "react-bootstrap/Spinner";
import { Button } from "@dpdgroupuk/mydpd-ui";

import {
  COUNTY,
  LOCALITY,
  ORGANISATION,
  POSTCODE,
  PROPERTY,
  STREET,
  TOWN,
} from "../../../../constants/strings";
import { ADDRESS_LOOKUP_FORM, Fields } from "../../../../constants/forms";
import InputWithTitle from "../../../../components/InputWithTitle";
import styles from "./Form.module.scss";

const PostcodeFinderModal = ({
  onClear,
  onSubmit,
  submitting,
  invalid,
  valid,
  handleSubmit,
}) => {
  const onHandleKeyPress = React.useCallback(
    event => {
      if (event.key === "Enter") {
        if (!submitting && valid) {
          handleSubmit(onSubmit)();
        }
      }
    },
    [handleSubmit, onSubmit, submitting, valid]
  );

  return (
    <div className={styles.postcode_finder_form}>
      <Field
        name={Fields.ORGANISATION}
        component={InputWithTitle}
        label={`${ORGANISATION}:`}
        maxLength={35}
        onKeyPress={onHandleKeyPress}
      />
      <Field
        name={Fields.PROPERTY}
        component={InputWithTitle}
        label={`${PROPERTY}:`}
        maxLength={35}
        onKeyPress={onHandleKeyPress}
      />
      <Field
        name={Fields.STREET}
        component={InputWithTitle}
        label={`${STREET}:`}
        minLength={1}
        maxLength={35}
        onKeyPress={onHandleKeyPress}
      />
      <Field
        name={Fields.LOCALITY}
        component={InputWithTitle}
        label={`${LOCALITY}:`}
        maxLength={35}
      />
      <Field
        name={Fields.TOWN}
        component={InputWithTitle}
        label={`${TOWN}:`}
        minLength={1}
        maxLength={35}
        onKeyPress={onHandleKeyPress}
      />
      <Field
        name={Fields.COUNTY}
        component={InputWithTitle}
        label={`${COUNTY}:`}
        maxLength={35}
        onKeyPress={onHandleKeyPress}
      />
      <Field
        name={Fields.PARTIAL_POSTCODE}
        component={InputWithTitle}
        label={`${POSTCODE}:`}
        maxLength={8}
        onKeyPress={onHandleKeyPress}
      />
      <div className={styles.buttons}>
        <Button
          variant="danger"
          onClick={onClear}
          className={styles.clear_button}
        >
          Clear
        </Button>
        <Button
          variant="dark"
          onClick={handleSubmit(onSubmit)}
          className={styles.find_button}
          disabled={submitting || invalid}
        >
          {submitting ? "Finding..." : "Find Address"}
          {submitting && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          )}
        </Button>
      </div>
    </div>
  );
};

PostcodeFinderModal.propTypes = {
  onClear: PropTypes.func,
  ...propTypes,
};

export default reduxForm({
  form: ADDRESS_LOOKUP_FORM,
})(PostcodeFinderModal);
