import get from "lodash/get";

import { ActionTypes } from "./actions";

export const initialState = {
  collection: null,
  actions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COLLECTION.SUCCESS: {
      return {
        ...state,
        collection: get(action, "payload.data"),
      };
    }
    case ActionTypes.GET_COLLECTION_ACTIONS.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        actions: data,
      };
    }
    default:
      return state;
  }
};
