import { ActionTypes } from "./actions";

export const initialState = {
  findByTotal: 0,
  collections: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH.SUCCESS: {
      const { findByTotal, findByCode, collections } = action.payload.data;
      return {
        ...state,
        findByTotal,
        findByCode,
        collections,
      };
    }
    case ActionTypes.CLEAR: {
      return initialState;
    }
    default:
      return state;
  }
};
