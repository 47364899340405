import React from "react";
import PropTypes from "prop-types";

export default function ChangeDate({ fill = "#DC0032" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 68 68"
      style={{
        enableBackground: "new 0 0 68 68",
      }}
      xmlSpace="preserve"
    >
      <g id="Delivery_x5F_Change_x5F_date_x5F_blackred_x5F_pos_x5F_rgb">
        <g id="Change_Date_64_">
          <rect id="XMLID_2327_" fill="none" width="68" height="68" />
          <path
            fill="#414042"
            d="M31,17.995h-8c-0.552,0-1-0.448-1-1s0.448-1,1-1h8c0.552,0,1,0.448,1,1S31.552,17.995,31,17.995z"
          />
          <path
            fill="#414042"
            d="M43,17.995h-8c-0.552,0-1-0.448-1-1s0.448-1,1-1h8c0.553,0,1,0.448,1,1S43.553,17.995,43,17.995z"
          />
          <path
            fill="#414042"
            d="M52,56.006H16c-2.206,0-4-1.794-4-4V19.995c0-2.206,1.794-4,4-4h3c0.552,0,1,0.448,1,1
			s-0.448,1-1,1h-3c-1.103,0-2,0.897-2,2v32.011c0,1.103,0.897,2,2,2h36c1.103,0,2-0.897,2-2V19.995c0-1.103-0.897-2-2-2h-4.994
			c-0.553,0-1-0.448-1-1s0.447-1,1-1H52c2.206,0,4,1.794,4,4v32.011C56,54.212,54.206,56.006,52,56.006z"
          />
          <path
            fill="#414042"
            d="M47,23.995c-0.553,0-1-0.448-1-1V12.994c0-0.552,0.447-1,1-1s1,0.448,1,1v10.001
			C48,23.547,47.553,23.995,47,23.995z"
          />
          <path
            fill="#414042"
            d="M23,23.995c-0.552,0-1-0.448-1-1V12.994c0-0.552,0.448-1,1-1s1,0.448,1,1v10.001
			C24,23.547,23.552,23.995,23,23.995z"
          />
          <path
            fill="#414042"
            d="M35,23.995c-0.552,0-1-0.448-1-1V12.994c0-0.552,0.448-1,1-1c0.553,0,1,0.448,1,1v10.001
			C36,23.547,35.553,23.995,35,23.995z"
          />
          <path
            id="XMLID_2317_"
            fill="#414042"
            d="M22.501,33.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C21.382,31.497,22.501,32.616,22.501,33.997L22.501,33.997z"
          />
          <path
            id="XMLID_2316_"
            fill="#414042"
            d="M29.501,33.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C28.382,31.497,29.501,32.616,29.501,33.997L29.501,33.997z"
          />
          <path
            id="XMLID_2315_"
            fill="#414042"
            d="M36.501,33.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C35.382,31.497,36.501,32.616,36.501,33.997L36.501,33.997z"
          />
          <path
            id="XMLID_2314_"
            fill="#414042"
            d="M43.501,33.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C42.382,31.497,43.501,32.616,43.501,33.997L43.501,33.997z"
          />
          <path
            id="XMLID_2313_"
            fill="#414042"
            d="M50.501,33.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C49.382,31.497,50.501,32.616,50.501,33.997L50.501,33.997z"
          />
          <path
            id="XMLID_2312_"
            fill="#414042"
            d="M22.501,40.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C21.382,38.497,22.501,39.616,22.501,40.997L22.501,40.997z"
          />
          <path
            id="XMLID_2311_"
            fill="#414042"
            d="M29.501,40.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C28.382,38.497,29.501,39.616,29.501,40.997L29.501,40.997z"
          />
          <path
            id="XMLID_2310_"
            fill="#414042"
            d="M36.501,40.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C35.382,38.497,36.501,39.616,36.501,40.997L36.501,40.997z"
          />
          <path
            id="XMLID_2309_"
            fill={fill}
            d="M43.501,40.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C42.382,38.497,43.501,39.616,43.501,40.997L43.501,40.997z"
          />
          <path
            id="XMLID_2308_"
            fill="#414042"
            d="M50.501,40.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C49.382,38.497,50.501,39.616,50.501,40.997L50.501,40.997z"
          />
          <path
            id="XMLID_2307_"
            fill="#414042"
            d="M22.501,47.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C21.382,45.497,22.501,46.616,22.501,47.997L22.501,47.997z"
          />
          <path
            id="XMLID_2306_"
            fill="#414042"
            d="M29.501,47.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C28.382,45.497,29.501,46.616,29.501,47.997L29.501,47.997z"
          />
          <path
            id="XMLID_2305_"
            fill="#414042"
            d="M36.501,47.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C35.382,45.497,36.501,46.616,36.501,47.997L36.501,47.997z"
          />
          <path
            id="XMLID_2304_"
            fill="#414042"
            d="M43.501,47.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C42.382,45.497,43.501,46.616,43.501,47.997L43.501,47.997z"
          />
          <path
            id="XMLID_2303_"
            fill="#414042"
            d="M50.501,47.997c0,1.381-1.119,2.5-2.5,2.5l0,0c-1.381,0-2.5-1.119-2.5-2.5l0,0
			c0-1.381,1.119-2.5,2.5-2.5l0,0C49.382,45.497,50.501,46.616,50.501,47.997L50.501,47.997z"
          />
        </g>
      </g>
      <g id="Layer_1" />
    </svg>
  );
}

ChangeDate.propTypes = {
  fill: PropTypes.string,
};
