import React from "react";
import PropTypes from "prop-types";
import { Button } from "@dpdgroupuk/mydpd-ui";

import Title from "../../../../components/Title";
import NotificationDetails from "../NotificationDetails";
import styles from "./EditPanel.module.scss";

const EditPanel = ({ title, onBackClick, onSubmit, error, children }) => (
  <>
    <Title title={title} containerStyle={styles.title} />
    <div className={styles.container}>
      <div className={styles.body}>
        {children}
        <NotificationDetails />
      </div>
      <div className={styles.footer}>
        <Button variant="dark" onClick={onBackClick}>
          Back to options
        </Button>
        <Button className={styles.submit_button} onClick={onSubmit}>
          Submit
        </Button>
      </div>
      <div className={styles.error}>{error}</div>
    </div>
  </>
);

EditPanel.propTypes = {
  title: PropTypes.string.isRequired,
  onBackClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  error: PropTypes.any,
  children: PropTypes.node,
};

export default EditPanel;
