import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { Section } from "@dpdgroupuk/mydpd-ui";

import { onCollectFromSafePlaceSubmit } from "./actions";
import EditPanel from "./components/EditPanel";
import SafePlaceOptions from "./components/SafePlaceOptions";
import TermsAndConditions from "./components/TermsAndConditions";
import DayPickerWithInstruction from "./components/DayPickerWithInstruction";
import { collectEditValidators } from "../../models/validators";
import styles from "./CollectFromSafePlace.module.scss";
import {
  hadleInputFocus,
  loadActionDates,
  withCollectionEdit,
  withInitialCollectionDate,
} from "./hocs";
import { CHANGE_SAFE_PLACE_COMPONENT } from "../../constants/analytics";

const TermsDescription = () => (
  <>
    Choose a safe, secure and concealed place to leave the parcel
    <br />
    Ensure that the parcel is adequately packaged with the delivery address
    clearly visible
    <br />
    Confirm full responsibility for the parcel left
  </>
);

const CollectFromSafePlace = ({
  onBackClick,
  handleSubmit,
  onSubmit,
  error,
  ...rest
}) => {
  return (
    <Section title="Collect from safe place">
      <EditPanel
        title={"Please collect the parcel from the following safe place"}
        onBackClick={onBackClick}
        onSubmit={handleSubmit(onSubmit)}
        error={error}
      >
        <div>
          <SafePlaceOptions />
          <DayPickerWithInstruction
            {...rest}
            className={styles.column_reverse}
          />
          <Field
            name="agreement"
            type="radio"
            value={"true"}
            label={"Please confirm that the following is accepted:"}
            description={<TermsDescription />}
            component={TermsAndConditions}
          />
        </div>
      </EditPanel>
    </Section>
  );
};

CollectFromSafePlace.defaultProps = {
  onBackClick: () => null,
  onSubmit: () => null,
};

CollectFromSafePlace.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  onBackClick: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  loadActionDates,
  withInitialCollectionDate,
  withCollectionEdit({
    onSubmit: onCollectFromSafePlaceSubmit,
    validate: collectEditValidators.safePlaceValidator,
    onChange: (values, dispatch, { untouch }, previousValues) => {
      values.safePlaceCode !== previousValues.safePlaceCode &&
        untouch("instructions");
    },
  }),
  withTrack(CHANGE_SAFE_PLACE_COMPONENT.LOAD),
  withTrackProps({
    onBackClick: () => CHANGE_SAFE_PLACE_COMPONENT.CLICK_BACK,
    onSubmit: () => CHANGE_SAFE_PLACE_COMPONENT.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case "sms":
          return CHANGE_SAFE_PLACE_COMPONENT.CLICK_SMS;
        case "email":
          return CHANGE_SAFE_PLACE_COMPONENT.CLICK_EMAIL;
        case "contactName":
          return CHANGE_SAFE_PLACE_COMPONENT.CLICK_CONTACT_NAME;
        case "collectionDate":
          return CHANGE_SAFE_PLACE_COMPONENT.CLICK_DATE;
        case "instructions":
          return CHANGE_SAFE_PLACE_COMPONENT.CLICK_INSTRUCTIONS;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(CollectFromSafePlace);
