import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "@dpdgroupuk/mydpd-ui";

import styles from "./Footer.module.scss";

const Footer = ({
  isItemSelected,
  exportLink,
  onRemoveClick,
  onExportPress,
}) => (
  <Row className="h-100">
    <Col className="d-flex justify-content-end align-items-center ">
      {isItemSelected && (
        <Button
          className={styles.remove_button}
          onClick={onRemoveClick}
          variant="dark"
        >
          Remove from Watchlist
        </Button>
      )}
      <Button href={exportLink} variant="danger" onClick={onExportPress}>
        Extract
      </Button>
    </Col>
  </Row>
);

Footer.defaultProps = {
  onExportPress: noop,
};

Footer.propTypes = {
  isItemSelected: PropTypes.bool,
  exportLink: PropTypes.string.isRequired,
  onRemoveClick: PropTypes.func,
  onExportPress: PropTypes.func,
};

export default Footer;
