import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { Button, Page } from "@dpdgroupuk/mydpd-ui";

import SearchFilterForm from "../../containers/FilterForm";
import { COLLECTION_SEARCH } from "../../constants/strings";
import SearchResult, { CollectionListPropTypes } from "./CollectionList";
import Footer from "../../components/Footer";
import {
  areEmptyFilters,
  getExportCSVLink,
  getFiltersInitialValues,
  getQueryPagination,
} from "../../utils/query";
import { useAuthUser } from "../../components/AuthUser";

const CollectionListView = params => {
  const {
    pageTitle,
    enums,
    collections,
    location,
    totalCount,
    onReset,
    onFilter,
    onNext,
    onPrevious,
    onClickRow,
    onExportPress,
    onFieldEntry,
    findByCode,
  } = params;

  const { username } = useAuthUser();
  const { page, pageSize, filters, emptyFilters } = React.useMemo(() => {
    return {
      ...getQueryPagination(location),
      filters: getFiltersInitialValues(location),
      emptyFilters: areEmptyFilters(location),
    };
  }, [location]);

  const footer = React.useMemo(
    () => (
      <Footer>
        <Button
          href={getExportCSVLink(findByCode)}
          onClick={onExportPress}
          variant="danger"
        >
          Extract
        </Button>
      </Footer>
    ),
    [findByCode, onExportPress]
  );

  return (
    <Page title={pageTitle} footer={footer} username={username}>
      <Page.Section>
        <SearchFilterForm
          emptyFilters={emptyFilters}
          initialValues={filters}
          enums={enums}
          onReset={onReset}
          onFilter={onFilter}
          onFieldEntry={onFieldEntry}
        />
      </Page.Section>
      <Page.Section title="Results">
        <SearchResult
          onClickRow={onClickRow}
          page={page}
          totalCount={totalCount}
          pageSize={pageSize}
          onNext={onNext}
          onPrevious={onPrevious}
          collections={collections}
        />
      </Page.Section>
    </Page>
  );
};

const { page, pageSize, ...SearchListPropTypes } = CollectionListPropTypes;

CollectionListView.propTypes = {
  ...SearchListPropTypes,
  ...SearchFilterForm.propTypes,
  ...ReactRouterPropTypes,
  pageTitle: PropTypes.string,
};

CollectionListView.defaultProps = {
  pageTitle: COLLECTION_SEARCH,
  onExportPress: noop,
};

export default CollectionListView;
