import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Lottie from "lottie-react-web";
import Card from "react-bootstrap/Card";

import styles from "./NoResults.module.scss";

const NoResults = ({ description }) => {
  return (
    <Card className={styles.container}>
      <Card.Body>
        <Lottie
          className={styles.lottie}
          options={{
            autoplay: true,
            animationData: require("../../assets/lottie/binoculars.json"),
            loop: true,
          }}
        />
      </Card.Body>
      <Card.Body>
        <Card.Text className={classNames("text-center", styles.text)}>
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export const propTypes = {
  description: PropTypes.string,
};

NoResults.propTypes = propTypes;

export default NoResults;
