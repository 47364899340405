import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { branch, compose, renderNothing } from "recompose";
import { COLLECTION_ACTION_CODE } from "@dpdgroupuk/redback-enums";
import ReactRouterPropTypes from "react-router-prop-types";

import CancelCollection from "./CancelCollection";
import ChangeCollectionDate from "./ChangeCollectionDate";
import CollectFromSafePlace from "./CollectFromSafePlace";
import ChangeCollectionInstructions from "./ChangeCollectionInstructions";
import CollectFromNeighbour from "./CollectFromNeighbour";
import RebookCollection from "./RebookCollection";
import { CollectionTypes } from "../../models/types";

const CollectionEdit = props => {
  const { actionCode } = props.match.params;
  switch (actionCode) {
    case COLLECTION_ACTION_CODE.CAN:
      return <CancelCollection {...props} />;
    case COLLECTION_ACTION_CODE.CHD:
      return <ChangeCollectionDate {...props} />;
    case COLLECTION_ACTION_CODE.SFP:
      return <CollectFromSafePlace {...props} />;
    case COLLECTION_ACTION_CODE.CFN:
      return <CollectFromNeighbour {...props} />;
    case COLLECTION_ACTION_CODE.INS:
      return <ChangeCollectionInstructions {...props} />;
    case COLLECTION_ACTION_CODE.REB:
      return <RebookCollection {...props} />;
    default:
      return <div>{actionCode}</div>;
  }
};

CollectionEdit.propTypes = {
  collection: PropTypes.shape(CollectionTypes.Collection),
  reloadCollectionFn: PropTypes.func.isRequired,
  ...ReactRouterPropTypes,
};

export default compose(
  withRouter,
  branch(
    ({ actions, match }) =>
      !actions.availableActions.includes(match.params.actionCode),
    renderNothing
  )
)(CollectionEdit);
