import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Popover } from "@dpdgroupuk/mydpd-ui";

import styles from "./InputWithTitle.module.scss";
import { delay } from "../utils";

const InputWithTitle = ({
  label,
  type,
  classNameContainer,
  classNameLabelContainer,
  classNameLabel,
  classNameField,
  meta,
  input,
  required,
  multiline,
  subLabel,
  disabled,
  ...inputProps
}) => {
  const popover = useRef(null);
  useEffect(() => {
    meta.error &&
      popover &&
      popover.current.props.isOpen &&
      popover.current.updatePopover(true);
  }, [meta.error, meta.touched]);

  return (
    <div
      className={classNames(
        styles.fieldContainer,
        multiline && styles.multilineFieldContainer,
        classNameContainer && classNameContainer
      )}
    >
      <div
        className={classNames(
          styles.fieldLabelContainer,
          classNameLabelContainer && classNameLabelContainer
        )}
      >
        <div
          className={classNames(
            styles.fieldLabel,
            classNameLabel && classNameLabel
          )}
        >
          {required && <span className={styles.required}>*</span>}
          {label}
        </div>
        {subLabel && (
          <div
            className={classNames(
              styles.fieldLabel,
              classNameLabel && classNameLabel
            )}
          >
            {subLabel}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.field, classNameField && classNameField)}
      >
        <Popover
          ref={popover}
          isOpen={meta.error && meta.touched}
          content={meta.error}
          disableReposition
        >
          {multiline ? (
            <textarea
              className={classNames(
                meta.error && meta.touched && styles.invalid
              )}
              {...inputProps}
              {...input}
              disabled={disabled}
              onBlur={() => delay(input.onBlur)}
            />
          ) : (
            <input
              type={type}
              className={classNames(
                meta.error && meta.touched && styles.invalid
              )}
              {...inputProps}
              {...input}
              disabled={disabled}
              onDrop={e => e.preventDefault()}
              onBlur={() => delay(input.onBlur)}
            />
          )}
        </Popover>
      </div>
    </div>
  );
};

InputWithTitle.defaultProps = {
  type: "text",
  multiline: false,
  required: false,
};

InputWithTitle.propTypes = {
  label: PropTypes.string,
  multiline: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  meta: PropTypes.object,
  input: PropTypes.object,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameField: PropTypes.string,
  classNameLabelContainer: PropTypes.string,
  subLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

export default InputWithTitle;
