import { combineReducers } from "redux";
import { reducer as form } from "redux-form";

import { uiReducers } from "../pages";
import { featuresReducers } from "../features";
import { AuthReducer } from "../containers/Auth";
import { CollectionReducer } from "./collections";

export default combineReducers({
  form,
  collections: CollectionReducer,
  auth: AuthReducer,
  ui: uiReducers,
  features: featuresReducers,
});
