import { ActionTypes } from "./actions";

export const initialState = {
  dates: [],
  addresses: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ACTION_DATES.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        dates: data,
      };
    }
    case ActionTypes.GET_NEIGHBOUR_ADDRESSES.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        addresses: data,
      };
    }
    default:
      return state;
  }
};
