import React from "react";
import PropTypes from "prop-types";
import { Header } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { compose } from "recompose";

import SecondaryMenu from "./components/SecondaryMenu";
import SearchInput from "./containers/SearchInput";
import { COLLECTIONS_MENU, MENU } from "./constants/analytics";

export const AppHeader = ({ onSecondaryMenuSelect, ...headerProps }) => {
  const secondary = React.useMemo(
    () => (
      <>
        <SecondaryMenu onSelect={onSecondaryMenuSelect} />
        <SearchInput />
      </>
    ),
    []
  );
  return <Header activeIndex={2} secondary={secondary} {...headerProps} />;
};

AppHeader.propTypes = {
  onPrimaryMenuSelect: PropTypes.func,
  onSecondaryMenuSelect: PropTypes.func,
};

const PRIMARY_INDEX_TO_ACTION_ID = {
  0: MENU.CLICK_MYDPD,
  1: MENU.CLICK_SHIPPING,
  2: MENU.CLICK_COLLECTIONS,
  3: MENU.CLICK_DELIVERIES,
  4: MENU.CLICK_SHOP_RETURNS,
  5: MENU.CLICK_ADDRESS_BOOK,
  6: MENU.CLICK_DEPOT_FINDER,
};

const SECONDARY_INDEX_TO_ACTION_ID = {
  0: COLLECTIONS_MENU.CLICK_CREATE_COLLECTION,
  1: COLLECTIONS_MENU.CLICK_SEARCH,
  2: COLLECTIONS_MENU.CLICK_DASHBOARD,
  3: COLLECTIONS_MENU.CLICK_WATCH_LIST,
};

export default compose(
  withTrack(MENU.LOAD),
  withTrackProps({
    onPrimaryMenuSelect: index => PRIMARY_INDEX_TO_ACTION_ID[index],
    onSecondaryMenuSelect: index => SECONDARY_INDEX_TO_ACTION_ID[index],
    onClickDpdLogo: MENU.CLICK_DPD_LOGO,
    onClickHelp: MENU.CLICK_HELP,
    onClickLogOff: MENU.CLICK_LOG_OFF,
  })
)(AppHeader);
