import React from "react";
import { Router } from "react-router";
import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";
import FeedbackProvider from "@dpdgroupuk/react-sentry-feedback";
import { Layout, ThemeProvider } from "@dpdgroupuk/mydpd-ui";

import { history, Routes } from "./router";
import Tracker from "./tracker";
import Sentry from "./sentry";
import { ErrorBoundary } from "./components/Error";
import AuthUserProvider from "./containers/Auth";
import Header from "./Header";
import Footer from "./Footer";

function App() {
  const onAuthStateChanged = React.useCallback(user => {
    if (user) {
      Sentry.setUser(user);
    }
  }, []);
  return (
    <ThemeProvider>
      <FeedbackProvider
        hub={Sentry.getCurrentHub()}
        friendlyProjectName="Collections"
      >
        <TrackerProvider tracker={Tracker}>
          <ErrorBoundary>
            <Router history={history}>
              <Layout header={<Header />} footer={<Footer />}>
                <AuthUserProvider onAuthStateChanged={onAuthStateChanged}>
                  <Routes />
                </AuthUserProvider>
              </Layout>
            </Router>
          </ErrorBoundary>
        </TrackerProvider>
      </FeedbackProvider>
    </ThemeProvider>
  );
}
export default App;
