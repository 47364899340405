import { SAFE_PLACE_TYPE } from "@dpdgroupuk/redback-enums";

import { EMAIL, PHONE_NUMBER } from "./regex";
import { COLLECTION_DATE_IS_MANDATORY } from "../../constants/strings";

export const notificationDetailsValidator = values => {
  const errors = {};

  if (!values.contactName) {
    errors.contactName = "Required";
  }

  if (values.sms && !PHONE_NUMBER.test(values.sms)) {
    errors.sms = "Invalid Phone number";
  }

  if (values.email && !EMAIL.test(values.email)) {
    errors.email = "Invalid Email";
  }

  return errors;
};

export const safePlaceValidator = values => {
  const errors = notificationDetailsValidator(values);

  if (!values.safePlaceCode) {
    errors.safePlaceCode = "Please select a safe place";
  }

  if (values.safePlaceCode === SAFE_PLACE_TYPE.OTH && !values.instructions) {
    errors.instructions =
      "You have selected 'Other', please provide \n additional instructions";
  }

  if (!values.agreement) {
    errors.agreement = "Please accept the terms and conditions";
  }

  return errors;
};

export const collectFromNeighbourValidator = values => {
  const errors = notificationDetailsValidator(values);

  if (!values.addressKey) {
    errors.addressKey = "Neighbour must be selected";
  }

  if (!values.agreement) {
    errors.agreement = "Please accept the terms and conditions";
  }

  return errors;
};

export const changeCollectionDateValidator = values => {
  const errors = notificationDetailsValidator(values);

  if (!values.collectionDate) {
    errors.collectionDate = COLLECTION_DATE_IS_MANDATORY;
  }

  return errors;
};

export const changeCollectionInstructions = values => {
  const errors = notificationDetailsValidator(values);

  if (!values.instructions) {
    errors.instructions = "Please add instructions";
  }

  return errors;
};
