import { compose } from "recompose";
import { withLoader } from "@dpdgroupuk/mydpd-ui";

import { getSearchQuery } from "../../utils/query";
import withSearchHandler from "./withSearchHandler";
import NoSearchResultsView from "./NoSearchResultsView";
import withLoaderHandlers from "../../HOCS/withLoaderHandlers";

export default compose(
  withSearchHandler,
  withLoaderHandlers,
  withLoader({
    loadFn: ({ location, searchHandler, setFailureState }, fetchOptions) => {
      const query = getSearchQuery(location);
      return searchHandler(query, fetchOptions);
    },
    ErrorComponent: NoSearchResultsView,
  })
)();
