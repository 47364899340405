import React from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import {
  ContextPropTypes,
  withFeedback,
} from "@dpdgroupuk/react-sentry-feedback";

import ErrorAlert from "./ErrorAlert";

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    component: PropTypes.elementType.isRequired,
    feedback: ContextPropTypes,
  };

  state = {
    eventId: null,
    error: null,
  };

  componentDidCatch(error, info) {
    Sentry.withScope(scope => {
      scope.setExtras(info);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, error });
    });
  }

  onCloseReportFeedbackDialog = () => {
    window.location.reload();
  };

  onReportFeedbackClick = () => {
    const { eventId } = this.state;
    this.props.feedback.showReportDialog({
      eventId,
      onClose: this.onCloseReportFeedbackDialog,
    });
  };

  render() {
    const { component: Component, feedback, children, ...props } = this.props;

    if (this.state.error) {
      return (
        <Component
          error={this.state.error}
          onReportFeedbackClick={
            process.env.NODE_ENV === "production"
              ? this.onReportFeedbackClick
              : null
          }
          {...props}
        />
      );
    }

    return children;
  }
}

ErrorBoundary.defaultProps = {
  component: ErrorAlert,
};

export default withFeedback(ErrorBoundary);
