import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { BorderlessTable, Paginator } from "@dpdgroupuk/mydpd-ui";

import { WATCH_LIST_COLLECTION_FORM } from "../../../constants/forms";
import { ReduxifiedCheckbox } from "../../../components/Checkbox";
import { CollectionListColumns } from "../../../features/CollectionListView";
import styles from "./WatchList.module.scss";

const FormCheckbox = ({ collectionCode }) => (
  <Field type="checkbox" name={collectionCode} component={ReduxifiedCheckbox} />
);

FormCheckbox.propTypes = {
  collectionCode: PropTypes.string,
};

const columns = [
  {
    text: "",
    render: FormCheckbox,
    onClick: event => {
      event.stopPropagation();
    },
    geyKey: ({ collectionCode }) => collectionCode,
    style: {
      width: "25px",
    },
  },
  ...CollectionListColumns,
];

const WatchList = ({
  collections,
  page,
  totalCount,
  pageSize,
  onPrevious,
  onNext,
  onClickRow,
}) => {
  return (
    <div className={styles.container}>
      <BorderlessTable
        columns={columns}
        data={collections}
        onClickRow={onClickRow}
      />
      <div className={styles.spacer} />
      <Paginator
        page={page}
        pageSize={pageSize}
        totalCount={totalCount}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
};

export const propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object),
  onClickRow: PropTypes.func,
  ...Paginator.propTypes,
};

WatchList.propTypes = propTypes;

export default reduxForm({
  form: WATCH_LIST_COLLECTION_FORM,
})(WatchList);
