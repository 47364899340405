import React from "react";
import PropTypes from "prop-types";
import { BorderedTable } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { formatDDMMMYYYY } from "../../../../utils/date";
import { CollectionTypes } from "../../../../models/types";
import ImageViewCell from "./ImageViewCell";
import MapViewCell from "./MapViewCell";
import { COLLECTION_HISTORY_COMPONENT } from "../../../../constants/analytics";

const columns = [
  {
    text: "Date",
    render: ({ createDate }) => formatDDMMMYYYY(createDate),
    style: {
      width: "100px",
    },
  },
  {
    text: "Time",
    render: ({ time }) => time && time.match(/^[0-9]{2}[:][0-9]{2}/)[0],
    style: {
      width: "55px",
    },
  },
  {
    text: "Location",
    dataIndex: "depotName",
    style: {
      width: "155px",
    },
  },
  {
    text: "Event",
    dataIndex: "text",
  },
  {
    text: "Map",
    component: MapViewCell,
    style: {
      width: "55px",
    },
  },
  {
    text: "Image",
    component: ImageViewCell,
    style: {
      width: "55px",
    },
  },
];

const CollectionHistory = ({ data }) => (
  <BorderedTable columns={columns} data={data} />
);

CollectionHistory.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape(CollectionTypes.CollectionEvent)),
};

export default withTrack(COLLECTION_HISTORY_COMPONENT.LOAD)(CollectionHistory);
