import React from "react";
import PropTypes from "prop-types";
import { COLLECTION_STATUS } from "@dpdgroupuk/redback-enums";

import OutForCollection from "./OutForCollection";
import GeneralView from "./GeneralView";
import { CollectionTypes } from "../../models/types";

const CollectionView = ({ collection, children, ...rest }) => {
  const View =
    collection.collectionStatusType === COLLECTION_STATUS.OUT_FOR_COLLECTION
      ? OutForCollection
      : GeneralView;
  return (
    <View collection={collection} {...rest}>
      {children}
    </View>
  );
};

CollectionView.propTypes = {
  collection: PropTypes.shape(CollectionTypes.Collection),
  children: PropTypes.node,
};

export default CollectionView;
