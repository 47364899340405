import React, { useState } from "react";
import { noop } from "lodash";
import { change } from "redux-form";

import FindPostcode, { propTypes } from "../../components/FindPostcode";
import AddressLookup from "./PostcodeFinderModal";

const { onFindClick, ...FindPostcodePropTypes } = propTypes;

const PostcodeFinderInput = ({ input, meta, onFindClick, ...rest }) => {
  const [addressLookupVisible, setAddressLookupVisible] = useState(false);
  const onFindBtnClick = React.useCallback(() => {
    setAddressLookupVisible(true);
    onFindClick();
  }, [onFindClick, setAddressLookupVisible]);
  return (
    <>
      <FindPostcode
        input={input}
        meta={meta}
        {...rest}
        onFindClick={onFindBtnClick}
      />
      <AddressLookup
        show={addressLookupVisible}
        onClose={() => setAddressLookupVisible(false)}
        onSelect={address => {
          meta.dispatch(change(meta.form, input.name, address.postcode));
          setAddressLookupVisible(false);
        }}
      />
    </>
  );
};

PostcodeFinderInput.defaultProps = {
  onFindClick: noop,
};

PostcodeFinderInput.propTypes = {
  ...FindPostcodePropTypes,
};

export default PostcodeFinderInput;
