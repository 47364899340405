import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";
import { reset } from "redux-form";

import { collectionApi } from "../../apis";
import { pageToStartPos } from "../../models/collection";
import { COLLECTION_SEARCH_FILTER_FORM } from "../../constants/forms";
import { transformQuery } from "../../utils/query";

export const resetFilters = () => reset(COLLECTION_SEARCH_FILTER_FORM);

export const ActionTypes = createActionTypes("COLLECTIONS", {
  FETCH: createAsyncActionTypes("FETCH"),
  FILTER: createAsyncActionTypes("FILTER"),
  SEARCH: createAsyncActionTypes("SEARCH"),
  SEARCH_BY_TYPE: createAsyncActionTypes("SEARCH_BY_TYPE"),
  CLEAR: "CLEAR",
});

export const clearFindByCodeCache = () => ({
  type: ActionTypes.CLEAR,
});

export const fetchCollections = createAsyncAction(
  (findByCode, { page, pageSize }, fetchOptions) =>
    collectionApi.getCollectionsPage(
      {
        findByCode,
        startPos: pageToStartPos(page, pageSize),
        endPos: page * pageSize,
      },
      fetchOptions
    ),
  ActionTypes.FETCH
);

export const filtersCollections = createAsyncAction(
  (findByCode, values = {}, fetchOptions) => {
    return collectionApi
      .filterCollections(transformQuery(findByCode, values), fetchOptions)
      .then(({ data }) => data);
  },
  ActionTypes.FILTER
);

export const searchCollections = createAsyncAction((query, _, fetchOptions) => {
  return collectionApi
    .searchCollections(query, fetchOptions)
    .then(({ data }) => data);
}, ActionTypes.SEARCH);

export const searchCollectionsByType = createAsyncAction(
  (values = {}, collectionType, fetchOptions) => {
    return collectionApi
      .getCollectionFindByCodeByType(collectionType, values, fetchOptions)
      .then(({ data }) => data);
  },
  ActionTypes.SEARCH_BY_TYPE
);
