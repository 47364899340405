import React from "react";
import { compose } from "recompose";
import PropTypes from "prop-types";
import { chunk } from "lodash";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { COLLECTION_ACTION_CODE } from "@dpdgroupuk/redback-enums";

import { COLLECTION_OPTIONS_COMPONENT } from "../../../../constants/analytics";
import OptionItem from "./OptionItem";
import styles from "./CollectionOptions.module.scss";

const CollectionOptions = ({ options, onOptionClick }) => (
  <div>
    {chunk(options, 4).map((row, i) => (
      <div className={styles.options_row} key={i}>
        {row.map((option, k) => (
          <div className={styles.option_wrapper} key={k}>
            <OptionItem {...option} onClick={() => onOptionClick(option)} />
          </div>
        ))}
      </div>
    ))}
  </div>
);

CollectionOptions.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  onOptionClick: PropTypes.func,
};

export default compose(
  withTrack(COLLECTION_OPTIONS_COMPONENT.LOAD),
  withTrackProps({
    onOptionClick: ({ action }) => {
      switch (action) {
        case COLLECTION_ACTION_CODE.WTC:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_ADD_TO_WATCH_LIST;
        case COLLECTION_ACTION_CODE.WTR:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_REMOVE_FROM_WATCH_LIST;
        case COLLECTION_ACTION_CODE.CAN:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_CANCEL;
        case COLLECTION_ACTION_CODE.CHD:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_CHANGE_DATE;
        case COLLECTION_ACTION_CODE.CFN:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_COLLECT_FROM_NEIGHBOUR;
        case COLLECTION_ACTION_CODE.SFP:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_COLLECT_FROM_SAFE_PLACE;
        case COLLECTION_ACTION_CODE.INS:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_COLLECTION_INSTRUCTION;
        case COLLECTION_ACTION_CODE.REB:
          return COLLECTION_OPTIONS_COMPONENT.CLICK_REBOOK_COLLECTION;
      }
    },
  })
)(CollectionOptions);
