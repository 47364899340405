import React from "react";
import PropTypes from "prop-types";

import styles from "./Checkbox.module.scss";

const Checkbox = props => (
  <label className={styles.container}>
    <input className={styles.chekbox} type="checkbox" {...props} />
    <span className={styles.checkmark}></span>
  </label>
);

export const ReduxifiedCheckbox = ({ input, ...inputProps }) => (
  <Checkbox {...input} {...inputProps} />
);

ReduxifiedCheckbox.propTypes = {
  input: PropTypes.object,
};

export default Checkbox;
