import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";

import styles from "./DashboardStats.module.scss";
import Tile from "../Tile";

const DashboardStats = ({ stats, onClickTile }) => (
  <Row className={styles.container}>
    {stats.map(({ title, amount, id }) => (
      <Tile
        key={title}
        title={title}
        amount={amount}
        disabled={!amount}
        onClick={() => onClickTile({ title, amount, id })}
      />
    ))}
  </Row>
);

DashboardStats.propTypes = {
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      amount: PropTypes.number,
    })
  ),
  onClickTile: PropTypes.func,
};

export default DashboardStats;
