import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { Button, Page } from "@dpdgroupuk/mydpd-ui";

import { COLLECTION_SEARCH } from "../../constants/strings";
import NoResults from "../../components/NoResults";
import { useAuthUser } from "../../components/AuthUser";
import Footer from "../../components/Footer";
import { SEARCH } from "../../router";

const NoSearchResultsView = ({ pageTitle, history }) => {
  const footer = React.useMemo(
    () => (
      <Footer>
        <Button onClick={history.goBack}>Go to Search</Button>
      </Footer>
    ),
    [history]
  );
  const { username } = useAuthUser();
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      history.push(SEARCH);
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  });
  return (
    <Page
      title={pageTitle}
      classNameBody="mx-auto"
      username={username}
      footer={footer}
    >
      <NoResults description="Oops, we can't find what you are looking for. Please try again." />
    </Page>
  );
};

NoSearchResultsView.propTypes = {
  ...ReactRouterPropTypes,
  pageTitle: PropTypes.string,
};

NoSearchResultsView.defaultProps = {
  pageTitle: COLLECTION_SEARCH,
};

export default NoSearchResultsView;
