import React from "react";
import { Route, Switch } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { COLLECTION_ACTION_CODE } from "@dpdgroupuk/redback-enums";
import { compose, lifecycle, withHandlers } from "recompose";
import ReactRouterPropTypes from "react-router-prop-types";
import { withTracker } from "@dpdgroupuk/react-event-tracker";
import {
  Page,
  withLoader,
  withOverlay,
  withPrompt,
} from "@dpdgroupuk/mydpd-ui";

import { transformActions } from "./model";
import { fetchCollection, fetchCollectionActions } from "./actions";
import { getCollection, getCollectionActions } from "./selectors";
import { CollectionTypes } from "../../models/types";
import { collectionApi } from "../../apis";
import CollectionView from "./CollectionView";
import CollectionOptions from "./components/CollectionOptions";
import CollectionEdit from "../CollectionEdit/CollectionEditPage";
import {
  ACTION_CAN_NOT_BE_COMPLETED,
  ADD_COLLECTION_TO_WTC,
  REMOVE_COLLECTION_FROM_WTC,
} from "../../constants/strings";
import withLoaderHandlers from "../../HOCS/withLoaderHandlers";

const CollectionPage = ({
  collection,
  actions,
  onOptionClick,
  match,
  reloadFn,
  ...rest
}) => {
  return (
    <CollectionView collection={collection}>
      <Switch>
        <Route exact path={match.path}>
          <Page.Section title="Available Options">
            <CollectionOptions
              {...rest}
              options={transformActions(actions)}
              onOptionClick={onOptionClick}
            />
          </Page.Section>
        </Route>
        <Route match={match} path={`${match.path}/:actionCode`}>
          <CollectionEdit
            {...rest}
            actions={actions}
            collection={collection}
            reloadCollectionFn={reloadFn}
          />
        </Route>
      </Switch>
    </CollectionView>
  );
};

CollectionPage.propTypes = {
  collection: PropTypes.shape(CollectionTypes.Collection),
  actions: PropTypes.object,
  onOptionClick: PropTypes.any,
  reloadFn: PropTypes.func,
  ...ReactRouterPropTypes,
};

const updateWatchList = (
  dispatch,
  prompt,
  request,
  message
) => collectionCode => {
  prompt.showConfirmation(message, () =>
    request(collectionCode)
      .then(() => dispatch(fetchCollectionActions(collectionCode)))
      .catch(() => {
        prompt.showInfo(ACTION_CAN_NOT_BE_COMPLETED);
      })
  );
};

export default compose(
  connect(),
  withLoaderHandlers,
  withLoader({
    loadFn: ({ dispatch, match }, fetchOptions) =>
      Promise.all([
        dispatch(fetchCollection(match.params.collectionCode, fetchOptions)),
        dispatch(
          fetchCollectionActions(match.params.collectionCode, fetchOptions)
        ),
      ]),
  }),
  withOverlay,
  withPrompt,
  withHandlers({
    onOptionClick: ({ prompt, match, history, dispatch }) => option => {
      switch (option.action) {
        case COLLECTION_ACTION_CODE.WTC:
          updateWatchList(
            dispatch,
            prompt,
            collectionApi.addCollectionToWatchList,
            ADD_COLLECTION_TO_WTC
          )(match.params.collectionCode);
          break;
        case COLLECTION_ACTION_CODE.WTR:
          updateWatchList(
            dispatch,
            prompt,
            collectionApi.removeCollectionFromWatchList,
            REMOVE_COLLECTION_FROM_WTC
          )(match.params.collectionCode);
          break;
        default:
          history.push(
            `/collections/${match.params.collectionCode}/${option.action}`
          );
          break;
      }
    },
  }),
  connect(state => ({
    collection: getCollection(state),
    actions: getCollectionActions(state),
  })),
  withTracker,
  lifecycle({
    componentDidMount() {
      this.props.tracker.setContext({
        collectionCode: this.props.match.params.collectionCode,
      });
    },
    componentDidUpdate(prevProps) {
      prevProps.match.params.collectionCode !==
        this.props.match.params.collectionCode && this.props.reloadFn(true);
    },
  })
)(CollectionPage);
