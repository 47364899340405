import React from "react";
import PropTypes from "prop-types";
import { BorderlessTable, Paginator } from "@dpdgroupuk/mydpd-ui";

import styles from "./CollectionList.module.scss";
import { formatDDMMMYYYY } from "../../../utils/date";
import { formatStreetCityPostcodeAddressString } from "../../../models/address";
import { QuantityCell } from "../../../components/Table/Cells";

export const columns = [
  {
    text: "Collection Date",
    render: ({ collectionDate }) => formatDDMMMYYYY(collectionDate),
    style: {
      width: "95px",
    },
  },
  {
    text: "Customer Ref",
    dataIndex: "customerReference",
    style: {
      width: "100px",
    },
  },
  {
    text: "Account",
    dataIndex: "account",
    style: {
      width: "60px",
    },
  },
  {
    text: "Collection Ref",
    dataIndex: "collectionReference",
    style: {
      width: "100px",
    },
  },
  {
    text: "Collection Type",
    dataIndex: "collectionType",
    style: {
      width: "90px",
    },
  },
  {
    text: "Ready",
    dataIndex: "readyTime",
    style: {
      width: "50px",
    },
  },
  {
    text: "Closed",
    dataIndex: "closeTime",
    style: {
      width: "50px",
    },
  },

  {
    text: "Status",
    dataIndex: "collectionStatusFull",
    style: {
      width: "160px",
    },
  },
  {
    text: "Collection Address",
    render: ({ collectionAddress }) =>
      formatStreetCityPostcodeAddressString(collectionAddress),
    style: {
      width: "130px",
    },
  },
  {
    text: "Quantity",
    style: {
      width: "65px",
    },
    component: QuantityCell,
  },
];

const CollectionList = ({
  collections,
  page,
  totalCount,
  pageSize,
  onPrevious,
  onNext,
  onClickRow,
}) => {
  return (
    <div className={styles.container}>
      <BorderlessTable
        columns={columns}
        data={collections}
        onClickRow={onClickRow}
      />
      <div className={styles.spacer} />
      <Paginator
        page={page}
        pageSize={pageSize}
        totalCount={totalCount}
        onNext={onNext}
        onPrevious={onPrevious}
      />
    </div>
  );
};

export const propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object),
  onClickRow: PropTypes.func,
  ...Paginator.propTypes,
};

CollectionList.propTypes = propTypes;

export default CollectionList;
