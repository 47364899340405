import React from "react";
import PropTypes from "prop-types";
import ReactRouterPropTypes from "react-router-prop-types";
import { branch, renderComponent } from "recompose";
import { Page } from "@dpdgroupuk/mydpd-ui";

import SearchFilterForm from "../../containers/FilterForm";
import { COLLECTION_SEARCH } from "../../constants/strings";
import WatchList, { CollectionListPropTypes } from "./WatchList";
import Footer from "./Footer";
import {
  areEmptyFilters,
  getExportCSVLink,
  getFilters,
  getQueryPagination,
} from "../../utils/query";
import { NoResultsView } from "../../features/CollectionListView";
import { useAuthUser } from "../../components/AuthUser";

const WatchListCollectionView = params => {
  const {
    pageTitle,
    enums,
    collections,
    findByCode,
    location,
    totalCount,
    onReset,
    onFilter,
    onNext,
    onPrevious,
    onClickRow,
    selectedItems,
    onRemoveClick,
    onExportPress,
    onFieldEntry,
  } = params;

  const { username } = useAuthUser();

  const { page, pageSize, filters, emptyFilters } = React.useMemo(() => {
    return {
      ...getQueryPagination(location),
      filters: getFilters(location),
      emptyFilters: areEmptyFilters(location),
    };
  }, [location]);

  return (
    <Page
      title={pageTitle}
      username={username}
      footer={
        <Footer
          isItemSelected={!!selectedItems.length}
          onRemoveClick={() => onRemoveClick(selectedItems)}
          exportLink={getExportCSVLink(findByCode)}
          onExportPress={onExportPress}
        />
      }
    >
      <Page.Section>
        <SearchFilterForm
          emptyFilters={emptyFilters}
          initialValues={filters}
          enums={enums}
          onReset={onReset}
          onFilter={onFilter}
          onFieldEntry={onFieldEntry}
        />
      </Page.Section>
      <Page.Section title="Results">
        <WatchList
          onClickRow={onClickRow}
          page={page}
          totalCount={totalCount}
          pageSize={pageSize}
          onNext={onNext}
          onPrevious={onPrevious}
          collections={collections}
        />
      </Page.Section>
    </Page>
  );
};

const { page, pageSize, ...SearchListPropTypes } = CollectionListPropTypes;

WatchListCollectionView.propTypes = {
  ...SearchListPropTypes,
  ...SearchFilterForm.propTypes,
  ...ReactRouterPropTypes,
  pageTitle: PropTypes.string,
};

WatchListCollectionView.defaultProps = {
  pageTitle: COLLECTION_SEARCH,
};

export default branch(
  props => props.collections.length === 0,
  renderComponent(NoResultsView)
)(WatchListCollectionView);
