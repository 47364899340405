import { getFormValues, initialize } from "redux-form";

import { COLLECTION_SEARCH_FORM, Fields } from "../../constants/forms";
import { CollectionActions } from "../../redux/collections";

const dateFields = [
  Fields.FROM_DATE,
  Fields.TO_DATE,
  Fields.CREATE_START_DATE,
  Fields.CREATE_END_DATE,
  Fields.COLLECTION_START_DATE,
  Fields.COLLECTION_END_DATE,
];
const subFields = [Fields.EMAIL, Fields.PHONE, Fields.POSTCODE];
const fields = [Fields.REFERENCE, Fields.CALLING_CARD_REFERENCE];

export const initializeForm = () => dispatch => {
  dispatch(
    initialize(COLLECTION_SEARCH_FORM, { type: Fields.CALLING_CARD_REFERENCE })
  );
};

export const reinitializeForm = data => (dispatch, getState) => {
  const formData = getFormValues(COLLECTION_SEARCH_FORM)(getState());

  if (!formData) return;

  data.forEach(field => {
    formData[field] = undefined;
  });
  dispatch(initialize(COLLECTION_SEARCH_FORM, formData));
};

export const onTypeChange = () => dispatch => {
  dispatch(
    reinitializeForm([
      ...fields,
      ...subFields,
      ...dateFields,
      Fields.COLLECTION_DATE,
      "subtype",
    ])
  );
};

export const onCollectionDateChange = () => dispatch => {
  dispatch(reinitializeForm([...subFields, ...dateFields, "subtype"]));
};

export const onSubTypeChange = () => dispatch => {
  dispatch(reinitializeForm([...fields, ...subFields]));
};

export const onStartDateChange = (event, newValue) => dispatch => {
  if (!newValue) {
    dispatch(reinitializeForm([...subFields, "subtype"]));
  }
};

export const searchCollections = CollectionActions.searchCollections;
