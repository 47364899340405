import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import {
  Field,
  getFormValues,
  propTypes,
  reduxForm,
  reset,
  SubmissionError,
} from "redux-form";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose, lifecycle } from "recompose";
import { withMyDpdTheme, withOverlay } from "@dpdgroupuk/mydpd-ui";

import Dropdown from "../../components/Dropdown";
import InputWithTitle from "../../components/InputWithTitle";
import { COLLECTION_REFERENCE_SEARCH_FORM } from "../../constants/forms";
import * as regex from "../../models/validators/regex";
import {
  COL,
  COLLECTION_SEARCH,
  COLLECTIONS,
  DEL,
  DELIVERIES,
  DELIVERY_SEARCH,
} from "./constants";
import "./SearchInput.scss";
import { CollectionActions } from "../../redux/collections";
import * as Routes from "../../router/constants";
import { createLocationState, stringifyQuery } from "../../utils/query";
import withSearchAnalytics from "./withSearchAnalytics";

const SearchInput = ({
  searchType,
  searchText,
  handleSubmit,
  onSubmit,
  submitting,
  onSearchTypeChange,
}) => {
  const onHandleKeyPress = React.useCallback(
    event => {
      if (event.key === "Enter" && !submitting && searchText) {
        handleSubmit(onSubmit)();
      }
    },
    [handleSubmit, onSubmit]
  );
  return (
    <div className="nav-bar-form">
      <Field
        classNameContainer="dropdown-container"
        name="searchType"
        component={Dropdown}
        values={[
          {
            value: COL,
            label: COLLECTIONS,
          },
          {
            value: DEL,
            label: DELIVERIES,
          },
        ]}
        onChange={onSearchTypeChange}
      />
      <Field
        type="text"
        size="25"
        maxLength="100"
        placeholder={searchType === DEL ? DELIVERY_SEARCH : COLLECTION_SEARCH}
        classNameField="input-container"
        classNameContainer="search"
        name="searchText"
        component={InputWithTitle}
        onKeyPress={onHandleKeyPress}
        normalize={(value, previousValue) => {
          const v = value === undefined ? previousValue : value;
          return (v || "").replace(/\s/g, "");
        }}
      />
      <button
        className="search-submit"
        onClick={handleSubmit(onSubmit)}
        disabled={submitting || !searchText}
      />
    </div>
  );
};

SearchInput.defaultProps = {
  onSearchTypeChange: noop,
  searchType: COL,
  searchText: "",
};

SearchInput.propTypes = {
  onSearchTypeChange: PropTypes.func,
  searchType: PropTypes.string,
  searchText: PropTypes.string,
  ...propTypes,
};

const formValuesSelector = getFormValues(COLLECTION_REFERENCE_SEARCH_FORM);

export default compose(
  connect(formValuesSelector),
  withRouter,
  withOverlay,
  withMyDpdTheme,
  reduxForm({
    form: COLLECTION_REFERENCE_SEARCH_FORM,
    initialValues: { searchType: COL },
    onSubmit: async (
      { searchType, searchText },
      dispatch,
      { history, overlay, theme }
    ) => {
      if (!searchText) return;

      const query = {};

      if (searchType === COL) {
        query.reference = searchText;
      } else if (searchType === DEL) {
        if (
          [8, 10, 14, 15].includes(searchText.length) &&
          regex.NUMERIC.test(searchText.slice(0, 14))
        ) {
          query.deliveryReference = searchText;
        } else if (regex.POSTCODE.test(searchText)) {
          query.postcode = searchText;
        } else if (regex.EMAIL.test(searchText)) {
          query.notificationEmail = searchText;
        } else if (searchText[0] === "+" || regex.NUMERIC.test(searchText)) {
          query.notificationPhone = searchText;
        } else {
          query.shippingRef1 = searchText;
        }
      }

      if (searchType === COL) {
        try {
          overlay.show();
          const { collectionCodes, findByCode, expiresIn } = await dispatch(
            CollectionActions.searchCollections(query)
          );
          let newLocation = "";

          if (collectionCodes.length === 1) {
            newLocation = `${Routes.COLLECTIONS}/${collectionCodes[0]}`;
          } else {
            newLocation = `${Routes.COLLECTIONS}?${stringifyQuery(
              query,
              true
            )}`;
          }

          if (
            newLocation ===
            history.location.pathname + history.location.search
          ) {
            dispatch(reset(COLLECTION_REFERENCE_SEARCH_FORM));
          } else if (newLocation) {
            history.push(
              newLocation,
              createLocationState({
                searchFindByCode: findByCode,
                expiresIn,
              })
            );
          }
          overlay.hide();
        } catch (e) {
          overlay.hide();
          throw new SubmissionError({
            searchText: "Reference not found",
          });
        }
      } else {
        const header = Object.keys(query)[0];
        const urlQuery = stringifyQuery({
          ...query,
          header,
        });
        dispatch(reset(COLLECTION_REFERENCE_SEARCH_FORM));
        window.location.href = `${theme.dpdDomain}/deliverysearch/#results&${urlQuery}`;
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.location.key !== prevProps.location.key) {
        this.props.dispatch(reset(COLLECTION_REFERENCE_SEARCH_FORM));
      }
    },
  }),
  withSearchAnalytics
)(SearchInput);
