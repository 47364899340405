import { isEmpty, omitBy, pick, uniq } from "lodash";

import { Fields } from "../../constants/forms";

const fields = [
  Fields.ACCOUNT,
  Fields.REFERENCE,
  Fields.CALLING_CARD_REFERENCE,
  Fields.EMAIL,
  Fields.PHONE,
  Fields.POSTCODE,
];

const emptyValues = {};
export const transformSearchFormValues = (formData = emptyValues) =>
  omitBy(
    {
      ...pick(formData, [
        ...fields,
        `${formData[Fields.COLLECTION_DATE]}${Fields.FROM_DATE}`,
        `${formData[Fields.COLLECTION_DATE]}${Fields.TO_DATE}`,
      ]),
      [Fields.CALLING_CARD_REFERENCE]: formData[Fields.CALLING_CARD_REFERENCE]
        ? uniq(
            formData[Fields.CALLING_CARD_REFERENCE].split("\n").filter(v => v)
          ).join(",")
        : undefined,
    },
    isEmpty
  );
