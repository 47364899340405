import React from "react";
import PropTypes from "prop-types";
import { Button } from "@dpdgroupuk/mydpd-ui";

import styles from "./FormBottomSection.module.scss";

const FormBottomSection = ({ onClick, text, disabled, children }) => {
  return (
    <div className={styles.bottomSection}>
      <div>
        <Button onClick={onClick} disabled={disabled}>
          {text}
        </Button>
      </div>
      {children}
    </div>
  );
};

FormBottomSection.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default FormBottomSection;
