import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Modal } from "@dpdgroupuk/mydpd-ui";

import { ADDRESS_LOOKUP } from "../../constants/strings";
import "./PostcodeFinderModal.scss";
import { getAddresses, getError } from "./selectors";
import { destroy, search } from "./actions";
import { FIND_POSTCODE_MODAL } from "../../constants/analytics";
import { AddressTypes } from "../../models/types";
import ResultsPanel from "./components/ResultsPanel/Results";
import Form from "./components/Form";

const PostcodeFinderModal = ({
  show,
  addresses,
  error,
  onClose,
  onSelect,
  onSubmit,
  destroy,
}) => {
  const onHide = React.useCallback(() => {
    onClose();
    destroy();
  }, [destroy, onClose]);

  const onSelectAddress = React.useCallback(
    address => {
      onSelect(address);
      onHide();
    },
    [onSelect, onHide]
  );

  return (
    <Modal id="postcode-finder-modal" show={show} onHide={onHide}>
      <Modal.Header variant="brand" closeButton>
        {ADDRESS_LOOKUP}
      </Modal.Header>
      <Modal.Body className="postcode-finder-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="left-content">
                <Form onSubmit={onSubmit} onClear={destroy} />
              </div>
            </Col>
            <Col>
              <div className="right-content">
                <ResultsPanel
                  addresses={addresses}
                  error={error}
                  onSelect={onSelectAddress}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

PostcodeFinderModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  search: PropTypes.func,
  destroy: PropTypes.func,
  error: PropTypes.string,
  onSelect: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(PropTypes.shape(AddressTypes.Address)),
};

const mapStateToProps = state => ({
  addresses: getAddresses(state),
  error: getError(state),
});

const mapDispatchToProps = {
  onSubmit: search,
  destroy,
};

export default compose(
  withTrack(trackProps(FIND_POSTCODE_MODAL)),
  connect(mapStateToProps, mapDispatchToProps),
  withTrackProps({
    destroy: FIND_POSTCODE_MODAL.CLICK_CLEAR,
    onSubmit: FIND_POSTCODE_MODAL.CLICK_FIND_POSTCODE,
    onSelect: FIND_POSTCODE_MODAL.CLICK_ADDRESS,
  })
)(PostcodeFinderModal);
