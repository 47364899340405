import React from "react";
import PropTypes from "prop-types";

const Garage = ({ fill }) => (
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 25 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 7</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Safe-place---Mobile---Dark---1"
        transform="translate(-94.000000, -443.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Group-7" transform="translate(94.000000, 443.000000)">
          <path
            d="M7.27224677,21.0305944 C7.06372436,21.0305944 6.89449359,21.1808951 6.89449359,21.3660923 L6.89449359,22.278906 L5.00550635,22.278906 L5.00550635,20.7354979 C5.00550635,20.5503007 4.83627559,20.4 4.62775318,20.4 C4.41923077,20.4 4.25,20.5503007 4.25,20.7354979 L4.25,22.6145021 C4.25,22.7996993 4.41923077,22.95 4.62775318,22.95 L7.27224677,22.95 C7.48076918,22.95 7.65,22.7996993 7.65,22.6145021 L7.65,21.3661906 C7.65011063,21.1809934 7.48076918,21.0305944 7.27224677,21.0305944 Z"
            id="Shape"
          />
          <path
            d="M20.0222468,20.4 C19.8137244,20.4 19.6444936,20.5503007 19.6444936,20.7354979 L19.6444936,22.278906 L17.7555064,22.278906 L17.7555064,21.4125882 C17.7555064,21.227391 17.5862756,21.0770903 17.3777532,21.0770903 C17.1692308,21.0770903 17,21.227391 17,21.4125882 L17,22.6145021 C17,22.7996993 17.1692308,22.95 17.3777532,22.95 L20.0222468,22.95 C20.2307692,22.95 20.4,22.7996993 20.4,22.6145021 L20.4,20.7354979 C20.4001106,20.5503007 20.2308799,20.4 20.0222468,20.4 Z"
            id="Shape"
          />
          <path
            d="M20.0136724,17.85 C19.8004168,17.85 19.6273448,18.0211304 19.6273448,18.2319935 L19.6273448,19.1385707 C19.6273448,19.8816282 19.0161027,20.4860129 18.2646145,20.4860129 L5.53538546,20.4860129 C4.78412369,20.4860129 4.17265524,19.8816282 4.17265524,19.1385707 L4.17265524,18.2319935 C4.17265524,18.0211304 3.99958318,17.85 3.78632762,17.85 C3.57307206,17.85 3.4,18.0211304 3.4,18.2319935 L3.4,19.1385707 C3.4,20.3027948 4.35795214,21.25 5.53538546,21.25 L18.2646145,21.25 C19.4420479,21.25 20.4,20.3027948 20.4,19.1385707 L20.4,18.2319935 C20.4,18.0212423 20.2269279,17.85 20.0136724,17.85 Z"
            id="Shape"
          />
          <path
            d="M18.6962817,14.8310237 L18.5612758,13.7889075 C18.3591514,12.2275291 17.1270718,11.05 15.6952845,11.05 L8.95059321,11.05 C7.51902564,11.05 6.286946,12.2274094 6.08460196,13.7889075 L5.95377039,14.799176 C5.92455025,15.0241457 6.06834413,15.2323534 6.27497233,15.2640815 C6.48160052,15.2970068 6.67262999,15.1392047 6.70163043,14.9139955 L6.832462,13.9037271 C6.9824075,12.7459532 7.89306551,11.8730132 8.95059321,11.8730132 L15.6952845,11.8730132 C16.7530319,11.8730132 17.6635801,12.7461926 17.8134157,13.9037271 L17.9484216,14.9458433 C17.9750054,15.1511776 18.136595,15.3 18.3219122,15.3 C18.3393784,15.3 18.3571741,15.2985633 18.3751896,15.2959292 C18.5814882,15.2642012 18.7252821,15.0562329 18.6962817,14.8310237 Z"
            id="Shape"
          />
          <path
            d="M19.1595877,15.4935891 L19.0657483,15.4070836 C18.8306406,15.1915824 18.5379162,14.923132 18.4419262,14.6866036 C18.3959687,14.5728608 18.2945452,14.4879895 18.1710486,14.4599896 C18.0472123,14.4318808 17.9176031,14.4639118 17.823311,14.5457325 C17.3142694,14.987194 16.8733717,15.1672868 16.3013932,15.1672868 L7.49860684,15.1672868 C6.92662835,15.1672868 6.48573062,14.9870851 5.97668901,14.5457325 C5.88239689,14.4639118 5.75256128,14.4323166 5.62895144,14.4599896 C5.5054548,14.4882074 5.40425773,14.5730787 5.35807384,14.6866036 C5.26231017,14.923132 4.96958577,15.1915824 4.73425166,15.4070836 L4.64041232,15.4935891 C3.82030859,16.2542713 3.38009004,17.2713105 3.40069168,18.3579682 C3.42197249,19.4838476 4.42284995,20.4 5.63200773,20.4 L18.1683319,20.4 C19.3774896,20.4 20.3783671,19.4838476 20.3993083,18.3581861 C20.41991,17.2715284 19.9796914,16.2541623 19.1595877,15.4935891 Z M18.1681055,19.6562049 L5.63178134,19.6562049 C4.84110852,19.6562049 4.18672347,19.0676622 4.17302677,18.3442406 C4.15638699,17.4667651 4.51261424,16.6445267 5.17628135,16.0290736 L5.2670644,15.9457276 C5.4459138,15.7817593 5.6517038,15.5931685 5.82059197,15.3812627 C6.34763172,15.7494015 6.86923807,15.9108639 7.49838045,15.9109729 L16.3011668,15.9109729 C16.9309883,15.9109729 17.4523683,15.7494015 17.979408,15.3812627 C18.1482962,15.5932775 18.353973,15.7817593 18.5329356,15.9457276 L18.6237187,16.0290736 C19.2872726,16.6443088 19.6434998,17.4665472 19.6269732,18.3443496 C19.6130501,19.0677712 18.9586651,19.6562049 18.1681055,19.6562049 Z"
            id="Shape"
          />
          <path
            d="M7.59333333,17 L5.47774089,17 C5.26922526,17 5.1,17.1586487 5.1,17.3541321 L5.1,17.85 C5.1,18.3187866 5.50684896,18.7 6.00666667,18.7 L7.59333333,18.7 C8.0933724,18.7 8.5,18.3187866 8.5,17.85 C8.5,17.3812134 8.0933724,17 7.59333333,17 Z M7.59333333,17.9916321 L6.00666667,17.9916321 C5.92332682,17.9916321 5.85559245,17.9280273 5.85559245,17.85 L5.85559245,17.7083679 L7.59333333,17.7083679 C7.67667318,17.7083679 7.74440755,17.7717651 7.74440755,17.85 C7.74440755,17.9282349 7.67667318,17.9916321 7.59333333,17.9916321 Z"
            id="Shape"
          />
          <path
            d="M19.1722591,17 L17.0566667,17 C16.5566276,17 16.15,17.3812134 16.15,17.85 C16.15,18.3187866 16.5566276,18.7 17.0566667,18.7 L18.6433333,18.7 C19.1433724,18.7 19.55,18.3185791 19.55,17.85 L19.55,17.3541321 C19.55,17.1586487 19.3807747,17 19.1722591,17 Z M18.7945182,17.85 C18.7945182,17.9282349 18.7266732,17.9916321 18.643444,17.9916321 L17.0567773,17.9916321 C16.9734375,17.9916321 16.9057031,17.9282349 16.9057031,17.85 C16.9057031,17.7717651 16.9735482,17.7083679 17.0567773,17.7083679 L18.7945182,17.7083679 L18.7945182,17.85 L18.7945182,17.85 Z"
            id="Shape"
          />
          <path
            d="M10.625,17 C10.390397,17 10.2,17.2076892 10.2,17.4635993 L10.2,19.0864007 C10.2,19.3423108 10.390397,19.55 10.625,19.55 C10.859852,19.55 11.05,19.3423108 11.05,19.0864007 L11.05,17.4635993 C11.05,17.2078251 10.859603,17 10.625,17 Z"
            id="Shape"
          />
          <path
            d="M12.325,17 C12.090397,17 11.9,17.2076892 11.9,17.4635993 L11.9,19.0864007 C11.9,19.3423108 12.090397,19.55 12.325,19.55 C12.559603,19.55 12.75,19.3423108 12.75,19.0864007 L12.75,17.4635993 C12.75,17.2078251 12.559603,17 12.325,17 Z"
            id="Shape"
          />
          <path
            d="M14.025,17 C13.790397,17 13.6,17.2076892 13.6,17.4635993 L13.6,19.0864007 C13.6,19.3423108 13.790397,19.55 14.025,19.55 C14.259603,19.55 14.45,19.3423108 14.45,19.0864007 L14.45,17.4635993 C14.45,17.2078251 14.259603,17 14.025,17 Z"
            id="Shape"
          />
          <path
            d="M2.9750207,2.55 L1.27497924,2.55 C1.04038771,2.55 0.85,2.72573425 0.85,2.94227012 L0.85,22.5577299 C0.85,22.7742657 1.04038771,22.95 1.27497924,22.95 L2.9750207,22.95 C3.20961223,22.95 3.4,22.7742657 3.4,22.5577299 L3.4,2.94227012 C3.40012446,2.72573425 3.20973675,2.55 2.9750207,2.55 Z M2.55004147,22.1654598 L1.69995847,22.1654598 L1.69995847,3.33465517 L2.55004147,3.33465517 L2.55004147,22.1654598 Z"
            id="Shape"
          />
          <path
            d="M23.3750207,2.55 L21.6749792,2.55 C21.4403877,2.55 21.25,2.72573425 21.25,2.94227012 L21.25,22.5577299 C21.25,22.7742657 21.4403877,22.95 21.6749792,22.95 L23.3750207,22.95 C23.6096122,22.95 23.8,22.7742657 23.8,22.5577299 L23.8,2.94227012 C23.8001245,2.72573425 23.6097367,2.55 23.3750207,2.55 Z M22.9500415,22.1654598 L22.0999585,22.1654598 L22.0999585,3.33465517 L22.9500415,3.33465517 L22.9500415,22.1654598 Z"
            id="Shape"
          />
          <path
            d="M23.5072863,2.65482333 L12.4147151,0.0104588141 C12.355771,-0.00348627138 12.2943617,-0.00348627138 12.2353056,0.0104588141 L1.14284638,2.65482333 C0.954472025,2.69997884 0.829748255,2.87672727 0.852720737,3.06675673 C0.875693219,3.25678619 1.03885387,3.4 1.23249509,3.4 L23.4175255,3.4 C23.6112788,3.4 23.7745515,3.25678619 23.7972999,3.06675673 C23.8201603,2.87672727 23.6956607,2.69975749 23.5072863,2.65482333 Z M4.44740995,2.64441985 L12.3250663,0.766481665 L20.2028348,2.64441985 L4.44740995,2.64441985 Z"
            id="Shape"
          />
          <polygon
            id="Shape"
            points="21.25 5.76243721 2.55 5.76243721 2.55 5.1 21.25 5.1"
          />
          <polygon
            id="Shape-Copy"
            points="21.25 7.46243721 2.55 7.46243721 2.55 6.8 21.25 6.8"
          />
          <polygon
            id="Shape-Copy-2"
            points="21.25 10.0124372 2.55 10.0124372 2.55 9.35 21.25 9.35"
          />
          <path
            d="M24.2648808,22.1 L0.385119225,22.1 C0.172530705,22.1 0,22.290397 0,22.525 C0,22.759603 0.172530705,22.95 0.385119225,22.95 L24.2648808,22.95 C24.4774693,22.95 24.65,22.759603 24.65,22.525 C24.65,22.290397 24.4774693,22.1 24.2648808,22.1 Z"
            id="Shape"
          />
        </g>
      </g>
    </g>
  </svg>
);

Garage.defaultProps = {
  fill: "#c3c3c3",
};

Garage.propTypes = {
  fill: PropTypes.string,
};

export default Garage;
