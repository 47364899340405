import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import styles from "./OptionItem.module.scss";

const OptionItem = ({ icon: Icon, title, disabled, onClick }) => {
  const theme = useMyDpdTheme();
  return (
    <div
      className={classNames(styles.card, disabled && styles.card_disabled)}
      onClick={() => !disabled && onClick()}
    >
      <div className={styles.icon_container}>
        <Icon alt={title} fill={theme.palette.brand} />
      </div>
      {title}
    </div>
  );
};

OptionItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default OptionItem;
