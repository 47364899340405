import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { reduxForm, propTypes as reduxFormPropTypes } from "redux-form";
import { Button } from "@dpdgroupuk/mydpd-ui";

import Dropdown from "../../../components/Dropdown";
import * as M from "../../../constants/strings";
import { DASHBOARD_FORM, Fields } from "../../../constants/forms";
import CustomDatePicker from "../../../components/CustomDatePicker";
import styles from "./FilterForm.module.scss";
import Field from "../../../components/Field";
import { validators } from "../../../models";
import { omitNilValues } from "../../../utils/object";

const FilterForm = props => {
  const { handleSubmit, onValidateSuccess, accounts } = props;

  return (
    <Container fluid className={styles.filter_form}>
      <Row>
        <Col>
          <Field
            component={Dropdown}
            placeholder={M.ALL_ACCOUNTS}
            label={`${M.ACCOUNT}:`}
            classNameField={styles.input}
            classNameContainer={styles.col_type}
            name={Fields.ACCOUNT}
            values={accounts}
          />
        </Col>
        <Col md="auto">
          <Field
            name={Fields.DATE}
            component={CustomDatePicker}
            classNameField={styles.input}
            classNameContainer={styles.col_type}
            classNameLabel={styles.date_input_label}
            label={`${M.DATE}:`}
            placeholderText={M.COLLECTION_DATE}
            showDisabledMonthNavigation
          />
        </Col>
        <Col xs lg={1}>
          <Button onClick={handleSubmit(onValidateSuccess)}>Filter</Button>
        </Col>
      </Row>
    </Container>
  );
};

FilterForm.defaultProps = {
  onFilter: () => null,
};

FilterForm.propTypes = {
  ...reduxFormPropTypes,
  onFilter: PropTypes.func,
};

export default reduxForm({
  form: DASHBOARD_FORM,
  validate: validators.dashboardValidator,
  enableReinitialize: true,
  onValidateSuccess: (_, __, { dirty, values, onFilter }) => {
    dirty && onFilter(omitNilValues(values));
  },
})(FilterForm);
