import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import Progress from "./Progress/Progress";
import Status from "./Status/Status";
import styles from "./EtaBar.module.scss";
import { ETA_BAR } from "../../../../constants/analytics";

const EtaBar = ({
  driverPosition,
  deliveryStartPosition,
  endTime,
  startTime,
  routeStatus,
}) => {
  return (
    <Container className={styles.container}>
      <Row>
        <Col lg={8}>
          <Progress
            currentPosition={driverPosition}
            deliveryStartPosition={deliveryStartPosition}
            windowEndTime={endTime}
            windowStartTime={startTime}
          />
        </Col>
        <Col />
      </Row>
      <Row>
        <Col>
          <Status routeStatus={routeStatus} />
        </Col>
      </Row>
    </Container>
  );
};

EtaBar.propTypes = {
  driverPosition: PropTypes.number,
  deliveryStartPosition: PropTypes.number,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  routeStatus: PropTypes.string,
};

export default withTrack(ETA_BAR.LOAD)(EtaBar);
