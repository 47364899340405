import React from "react";
import PropTypes from "prop-types";
import { Popover, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

const Option = ({
  input,
  value,
  SvgIcon,
  title,
  meta,
  withPopover,
  ...inputProps
}) => {
  const theme = useMyDpdTheme();
  const icon = React.useMemo(() => {
    if (SvgIcon) {
      return <SvgIcon fill={input.checked ? theme.palette.brand : "#c3c3c3"} />;
    }
    return null;
  }, [theme, SvgIcon, input.checked]);

  return (
    <Popover
      disableReposition
      isOpen={withPopover && meta.error && meta.touched}
      content={meta.error}
    >
      <label key={value} className="safe-place-row" htmlFor={value}>
        <div className={icon ? "hidden" : "icon"}>
          <input id={value} {...input} {...inputProps} />
        </div>
        {icon}
        <div className="title">{title}</div>
      </label>
    </Popover>
  );
};

Option.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  value: PropTypes.string,
  src: PropTypes.string,
  SvgIcon: PropTypes.elementType,
  title: PropTypes.string,
  withPopover: PropTypes.bool,
};

export default Option;
