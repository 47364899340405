import FetchClient from "@dpdgroupuk/fetch-client";

import { getApiOrigin } from "../utils/config";
import { postpone } from "../utils/promise";

export const APIS_ORIGIN_URL = getApiOrigin();

class CCFetchClient extends FetchClient {
  _handleResponse(res, requestOptions) {
    if (res.status === 401) {
      // the current page will not be saved in session
      return res.json().then(result => {
        window.location.replace(result.authorizationUrl);
        // wait for location to be redirected
        return postpone(1000);
      });
    }
    return super._handleResponse(res, requestOptions);
  }

  // pass _ query param to all get request to invalidate cache(IE problem)
  get({ query = {}, ...params }) {
    return super.get({
      ...params,
      query: {
        ...query,
        _: Date.now(),
      },
    });
  }
}

export default new CCFetchClient({
  mode: "cors",
  credentials: "include",
  baseUrl: APIS_ORIGIN_URL,
});
