import React from "react";
import { Field as ReduxFromField } from "redux-form";

import styles from "./FilterForm.module.scss";

const Field = ({ ...props }) => (
  <ReduxFromField
    classNameContainer={styles.form_field}
    classNameLabel={styles.form_label}
    classNameField={styles.form_value}
    {...props}
  />
);

export default Field;
