import { joinStringsWithComma } from "../utils/string";

export const formatStreetCityPostcodeAddressString = ({
  street,
  city,
  postcode,
}) => joinStringsWithComma([street, city, postcode]);

export const formatFullAddressString = ({
  organisation,
  street,
  suburb,
  town,
  city,
  county,
  postcode,
}) =>
  joinStringsWithComma([
    organisation,
    street,
    suburb,
    town,
    city,
    county,
    postcode,
  ]);
