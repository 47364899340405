import React from "react";
import { chunk } from "lodash";
import { Field } from "redux-form";
import classNames from "classnames";
import { SAFE_PLACE_TYPE } from "@dpdgroupuk/redback-enums";

import { Garage, Porch, RearPorch, Shed } from "../../../../components/Icon";
import { getSafePlaceObjects } from "../../../../models/enum";
import SafePlaceOption from "./SafePlaceOption";
import "./SafePlaceOptions.scss";

export const SvgIcons = {
  [SAFE_PLACE_TYPE.GAR]: Garage,
  [SAFE_PLACE_TYPE.FPC]: Porch,
  [SAFE_PLACE_TYPE.RPC]: RearPorch,
  [SAFE_PLACE_TYPE.OBL]: Shed,
};

const SafePlaceOptions = () => {
  const options = getSafePlaceObjects();

  return (
    <div className="safe-place-view">
      <div className="header">
        To choose a safe place, please click on an icon below
      </div>
      {chunk(options, 2).map((items, i) => (
        <div key={i} className={classNames("safe-place-row", "bottom-margin")}>
          {items.map(({ name, type }) => (
            <Field
              key={type}
              name="safePlaceCode"
              type="radio"
              value={type}
              title={name}
              SvgIcon={SvgIcons[type]}
              withPopover={type === options[0].type}
              component={SafePlaceOption}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default SafePlaceOptions;
