export const pageToStartPos = (page, pageSize) => (page - 1) * pageSize + 1;

export const getContactSmsPhoneEmail = ({
  contact,
  notifySms,
  phoneNumber,
  notifyEmail,
}) => {
  return [contact, notifySms, phoneNumber, notifyEmail]
    .filter(v => v)
    .join("\n");
};

export const getLiability = ({ insurance, insuranceValue }) => {
  if (insurance) {
    return `Yes\n£${insuranceValue}`;
  }

  return "No";
};

export const calculateDeliveriesQuantity = deliveries =>
  deliveries.reduce((acc, delivery) => acc + delivery.quantity, 0);

export const getQuantityUrl = (collectionNumber, domain) =>
  `${domain}/tracking/trackingSearch.do?appmode=app&search.javascriptValidated=2&search.searchType=6&search.collectionRequestNumber=${collectionNumber}`;

export const getNotificationDetails = collection => {
  const { contact, notifyEmail, notifySms } = collection || {};
  return {
    contactName: contact,
    email: notifyEmail,
    sms: notifySms,
  };
};
