import React from "react";
import PropTypes from "prop-types";

export default function Rebook({ fill = "#DC0032" }) {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Climate_Change_blackred_pos_rgb</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Available-inflights"
          transform="translate(-753.000000, -376.000000)"
          fillRule="nonzero"
        >
          <g
            id="Climate_Change_blackred_pos_rgb"
            transform="translate(753.000000, 376.000000)"
          >
            <g
              id="Turn_Right"
              transform="translate(0.000000, 18.000000)"
              fill={fill}
            >
              <g id="_Group_">
                <path
                  d="M5.43272727,5.58 L0.752727273,5.41090909 C0.451481046,5.41090909 0.207272727,5.16670077 0.207272727,4.86545455 C0.207272727,4.56420832 0.451481046,4.32 0.752727273,4.32 L4.34181818,4.45090909 L4.47272727,0.861818182 C4.4795478,0.666946042 4.58981327,0.490515466 4.76198776,0.398986143 C4.93416224,0.307456821 5.14208834,0.314734235 5.3074423,0.418077052 C5.47279626,0.52141987 5.57045689,0.70512786 5.56363636,0.9 L5.43272727,5.58 Z"
                  id="Path"
                />
              </g>
            </g>
            <g id="Speedlimit_10" fill="#414042">
              <path
                d="M13.5489487,27.0666667 C12.3164302,27.059249 11.0906537,26.8841381 9.90535897,26.5461538 C9.62794919,26.4685366 9.46598535,26.1807303 9.54360259,25.9033205 C9.62121984,25.6259107 9.90902611,25.4639469 10.1864359,25.5415641 C15.0455368,26.8825061 20.2344502,25.1717445 23.3432428,21.2038237 C26.4520354,17.2359029 26.8715099,11.7883742 24.4068462,7.39128205 C24.3138655,7.2294957 24.3142598,7.03039436 24.4078806,6.86897757 C24.5015013,6.70756079 24.6741253,6.6083516 24.8607267,6.60872013 C25.0473281,6.60909072 25.2195578,6.70898288 25.3125385,6.87076923 C27.6808909,11.0581106 27.6483329,16.1879578 25.2270211,20.3449002 C22.8057093,24.5018426 18.3596543,27.0609416 13.5489487,27.0666667 L13.5489487,27.0666667 Z"
                id="Path"
              />
              <path
                d="M2.20176923,20.4509487 C2.0139869,20.4506251 1.84093628,20.3491816 1.74892308,20.1854872 C-0.933659815,15.4263374 -0.491730506,9.52100277 2.86931358,5.21413151 C6.23035767,0.907260251 11.8512985,-0.956419822 17.1196667,0.489282051 C17.3970765,0.565461944 17.5602055,0.852103023 17.4840256,1.12951282 C17.4078457,1.40692262 17.1212047,1.57005169 16.8437949,1.49387179 C11.9804516,0.158786313 6.79134054,1.87895048 3.6885367,5.8547736 C0.585732857,9.83059672 0.177901065,15.2821572 2.65461538,19.6753846 C2.74513425,19.8364193 2.74351923,20.0333669 2.65037165,20.1928955 C2.55722407,20.3524242 2.38650079,20.4506307 2.20176923,20.4509487 L2.20176923,20.4509487 Z"
                id="Path"
              />
            </g>
            <g
              id="Turn_Right-2"
              transform="translate(21.000000, 4.000000)"
              fill={fill}
            >
              <g id="_Group_2">
                <path
                  d="M0.998181818,5.6672773 L0.998181818,5.6672773 C0.696935591,5.6672773 0.452727273,5.42306441 0.452727273,5.12181818 L0.654545455,0.414545455 L5.44363636,0.834545455 C5.63850851,0.851109582 5.80974094,0.970378519 5.89283205,1.14742481 C5.97592316,1.3244711 5.95824945,1.53239721 5.84646841,1.69287936 C5.73468738,1.85336152 5.54578123,1.94201868 5.35090909,1.92545455 L1.69636364,1.60363636 L1.54363636,5.17090909 C1.51816201,5.45283477 1.28125374,5.6684213 0.998181818,5.6672773 Z"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

Rebook.propTypes = {
  fill: PropTypes.string,
};
