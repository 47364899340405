import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { withRouter } from "react-router";
import { isNil, omitBy } from "lodash";
import {
  trackProps,
  withTrack,
  withTrackProps,
} from "@dpdgroupuk/react-event-tracker";
import { COLLECTION_DASH_TYPE } from "@dpdgroupuk/redback-enums";
import {
  Page,
  withLoader,
  withOverlay,
  withPrompt,
} from "@dpdgroupuk/mydpd-ui";

import { DASHBOARD as TITLE } from "../../constants/strings";
import Filter from "./Filter";
import DashboardStats from "./DashboardStats";
import { fetchDashboardStats } from "./actions";
import { CollectionActions } from "../../redux/collections";
import { getDashboardStats } from "./selectors";
import { DASHBOARD } from "../../constants/analytics";
import { COLLECTIONS } from "../../router";
import { useAuthUser, withAuthUser } from "../../components/AuthUser";
import {
  createLocationState,
  getSearchQuery,
  stringifyQuery,
} from "../../utils/query";
import withLoaderHandlers from "../../HOCS/withLoaderHandlers";

export const getFilterValues = location => getSearchQuery(location);

const Dashboard = ({ stats, location, onFilter, authUser, onClickTile }) => {
  const { username } = useAuthUser();
  const filters = React.useMemo(() => getFilterValues(location), [location]);
  return (
    <Page title={TITLE} username={username}>
      <Page.Section>
        <Filter
          onFilter={onFilter}
          initialValues={filters}
          accounts={authUser.accounts}
        />
      </Page.Section>
      <Page.Section title="For Your Attention">
        <DashboardStats stats={stats} onClickTile={onClickTile} />
      </Page.Section>
    </Page>
  );
};

Dashboard.propTypes = {
  ...DashboardStats.propTypes,
  onFilter: PropTypes.func,
};

Dashboard.defaultProps = {
  stats: [],
};

const mapStateToProps = state => ({
  stats: getDashboardStats(state),
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    history,
    overlay,
    location,
    fetchDashboardStats,
    prompt,
    fetchCollectionFindByCodeByType,
  } = ownProps;

  return {
    onFilter: overlay.showWhile(async formValues => {
      const query = formValues;
      try {
        await fetchDashboardStats(query);

        history.push({
          search: stringifyQuery(query),
        });
      } catch (e) {
        prompt.showError(e.message);
      }
    }),
    onClick: overlay.showWhile(async ({ id: collectionType, amount }) => {
      const query = getFilterValues(location);
      const {
        collectionCodes = [],
        findByCode: searchFindByCode,
        expiresIn,
      } = await fetchCollectionFindByCodeByType(query, collectionType);
      if (amount === 1) {
        history.push(`${COLLECTIONS}/${collectionCodes[0]}`);
      } else {
        history.push(
          `/dashboard/${collectionType}?${stringifyQuery(query)}`,
          createLocationState({
            searchFindByCode,
            expiresIn,
          })
        );
      }
    }),
  };
};

export default compose(
  withRouter,
  withAuthUser,
  connect(mapStateToProps, {
    fetchDashboardStats,
    fetchCollectionFindByCodeByType: CollectionActions.searchCollectionsByType,
  }),
  withLoaderHandlers,
  withLoader({
    loadFn: ({ fetchDashboardStats, location }, fetchOptions) => {
      const query = getFilterValues(location);

      return fetchDashboardStats(query, fetchOptions);
    },
  }),
  withOverlay,
  withPrompt,
  connect(null, mapDispatchToProps),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (prevProps.location.search !== this.props.location.search) {
        const { reloadFn, overlay } = this.props;

        overlay.show();
        reloadFn(true).finally(overlay.hide);
      }
    },
  }),
  withHandlers({
    onClickTile: ({ onClick, filters }) => stat =>
      onClick(stat, omitBy(filters, isNil)),
  }),
  withTrack(trackProps(DASHBOARD)),
  withTrackProps({
    onClickTile: ({ id }) => {
      switch (id) {
        case COLLECTION_DASH_TYPE.CANC:
          return DASHBOARD.CLICK_CANCELLED_REBOOKED;
        case COLLECTION_DASH_TYPE.COLL:
          return DASHBOARD.CLICK_COLLECTED;
        case COLLECTION_DASH_TYPE.CARD:
          return DASHBOARD.CLICK_CARD_LEFT;
        case COLLECTION_DASH_TYPE.PEND:
          return DASHBOARD.CLICK_PENDING;
        case COLLECTION_DASH_TYPE.UNS:
          return DASHBOARD.CLICK_UNSUCCESSFUL;
        case COLLECTION_DASH_TYPE.WTCH:
          return DASHBOARD.CLICK_WATCHING;
        case COLLECTION_DASH_TYPE.OFC:
          return DASHBOARD.CLICK_OUT_FOR_COLLECTION;
        case COLLECTION_DASH_TYPE.FAIL:
          return DASHBOARD.CLICK_FAILED;
      }
    },
  })
)(Dashboard);
