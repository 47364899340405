import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { collectionApi, locationApi } from "../../apis";

export const ActionTypes = createActionTypes("EDIT_COLL", {
  GET_NEIGHBOUR_ADDRESSES: createAsyncActionTypes("GET_NEIGHBOUR_ADDRESSES"),
  GET_ACTION_DATES: createAsyncActionTypes("GET_ACTION_DATES"),
  CAN_SUBMIT: createAsyncActionTypes("CAN_SUBMIT"),
  SFP_SUBMIT: createAsyncActionTypes("SFP_SUBMIT"),
  CFN_SUBMIT: createAsyncActionTypes("CFN_SUBMIT"),
  CHD_SUBMIT: createAsyncActionTypes("CHD_SUBMIT"),
  INS_SUBMIT: createAsyncActionTypes("INS_SUBMIT"),
  RBK_SUBMIT: createAsyncActionTypes("RBK_SUBMIT"),
});

const createSubmitAsyncAction = (apiMethod, actionType) =>
  createAsyncAction(
    (collectionCode, values) =>
      apiMethod(collectionCode, values).then(({ data }) => data.collectionCode),
    actionType
  );

export const fetchActionDates = createAsyncAction(
  collectionApi.getCollectionActionDates,
  ActionTypes.GET_ACTION_DATES
);

export const fetchNeighbourAddresses = createAsyncAction(
  ({ postcode, street }, options) =>
    locationApi.searchAddresses(
      {
        postcode,
        street,
        searchPageSize: 100,
      },
      options
    ),
  ActionTypes.GET_NEIGHBOUR_ADDRESSES
);

export const onCancelSubmit = createSubmitAsyncAction(
  collectionApi.cancelCollection,
  ActionTypes.CAN_SUBMIT
);

export const onChangeCollectionDateSubmit = createSubmitAsyncAction(
  collectionApi.changeCollectionDate,
  ActionTypes.CHD_SUBMIT
);

export const onRebookCollectionSubmit = createSubmitAsyncAction(
  collectionApi.changeCollectionDate,
  ActionTypes.RBK_SUBMIT
);

export const onCollectFromSafePlaceSubmit = createSubmitAsyncAction(
  collectionApi.changeSafePlace,
  ActionTypes.SFP_SUBMIT
);

export const onCollectFromNeighbourSubmit = createSubmitAsyncAction(
  collectionApi.collectFromNeighbour,
  ActionTypes.CFN_SUBMIT
);

export const onChangeCollectionInstructionsSubmit = createSubmitAsyncAction(
  collectionApi.changeInstructions,
  ActionTypes.INS_SUBMIT
);
