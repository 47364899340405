import { connect } from "react-redux";
import { compose } from "recompose";
import { withLoader } from "@dpdgroupuk/mydpd-ui";

import { fetchActionDates } from "../actions";
import { getActionDates } from "../selectors";
import withLoaderHandlers from "../../../HOCS/withLoaderHandlers";

export const loadActionDates = compose(
  connect(),
  withLoaderHandlers,
  withLoader({
    loadFn: ({ dispatch, match }, fetchOptions) =>
      dispatch(fetchActionDates(match.params, fetchOptions)),
  }),
  connect(state => ({
    dates: getActionDates(state),
  }))
);
