import branch from "recompose/branch";
import renderComponent from "recompose/renderComponent";
import { COLLECTION_DASH_TYPE } from "@dpdgroupuk/redback-enums";

import DashboardCollectionsResult from "./DashboardCollectionsResult";
import DashboardWatchListCollections from "../DashboardWatchList/DashboardWatchList";

export default branch(
  ({ match }) => match.params.collectionType === COLLECTION_DASH_TYPE.WTCH,
  renderComponent(DashboardWatchListCollections)
)(DashboardCollectionsResult);
