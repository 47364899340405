import React from "react";
import PropTypes from "prop-types";
import { compose, withProps } from "recompose";
import { Trackable, trackProps } from "@dpdgroupuk/react-event-tracker";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import CollectionListView, {
  withCollectionAnalytics,
  withCollectionHandlers,
} from "../../features/CollectionListView";
import { CollectionActions } from "../../redux/collections";
import { getTitle, trackLoadAction } from "./utils";

const TrackableCollectionPage = ({ loadId, interfaceId, ...rest }) => {
  const pageTitle = getTitle(rest.match.params.collectionType);

  return (
    <Trackable loadId={loadId} interfaceId={interfaceId}>
      <CollectionListView {...rest} pageTitle={pageTitle} />
    </Trackable>
  );
};

TrackableCollectionPage.propTypes = {
  loadId: PropTypes.string,
  interfaceId: PropTypes.string,
  watchListItems: PropTypes.array,
};

const mapDispatchToProps = (dispatch, { match }) => ({
  ...bindActionCreators(CollectionActions, dispatch),
  searchCollections: (query, fetchOptions) =>
    dispatch(
      CollectionActions.searchCollectionsByType(
        query,
        match.params.collectionType,
        fetchOptions
      )
    ),
});

export default compose(
  connect(null, mapDispatchToProps),
  withCollectionHandlers,
  withProps(({ match }) => ({
    ...trackLoadAction(match.params.collectionType, trackProps),
  })),
  withCollectionAnalytics
)(TrackableCollectionPage);
