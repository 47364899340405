import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { collectionApi } from "../../apis";

export const ActionTypes = createActionTypes("DASHBOARD_STATS", {
  FETCH_DASHBOARD_STATS: createAsyncActionTypes("FETCH_DASHBOARD_STATS"),
});

export const fetchDashboardStats = createAsyncAction(
  (query = {}, fetchOptions) =>
    collectionApi.getDashboardStats(query, fetchOptions),
  ActionTypes.FETCH_DASHBOARD_STATS
);
