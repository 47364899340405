import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Popover } from "@dpdgroupuk/mydpd-ui";

import styles from "./Dropdown.module.scss";

const Dropdown = ({
  label,
  classNameContainer,
  classNameLabel,
  classNameField,
  values,
  required,
  placeholder,
  input,
  meta,
  disabled,
}) => {
  const options = React.useMemo(
    () =>
      values.map(value => {
        if (typeof value === "object" && value !== null) {
          return value;
        }
        return { label: value, value };
      }),
    [values]
  );
  const currentValue = React.useMemo(() => {
    if (
      input.value &&
      options.find(option => option.value.toString() === input.value.toString())
    ) {
      return input.value;
    }
    return "";
  }, [input.value, options]);

  return (
    <div
      className={classNames(
        styles.fieldContainer,
        classNameContainer && classNameContainer
      )}
    >
      <div
        className={classNames(
          styles.fieldLabel,
          classNameLabel && classNameLabel
        )}
      >
        {required && <span className={styles.required}>*</span>}
        {label}
      </div>
      <div
        className={classNames(styles.field, classNameField && classNameField)}
      >
        <Popover isOpen={meta.error && meta.touched} content={meta.error}>
          <select
            {...input}
            disabled={disabled}
            className={classNames(meta.error && meta.touched && styles.invalid)}
            value={currentValue}
          >
            {!!placeholder && (
              <option label={placeholder} value={""}>
                {placeholder}
              </option>
            )}
            {options.map(option => (
              <option
                key={option.value + option.label}
                value={option.value}
                label={option.label}
                className={classNames(styles.selectOption)}
              >
                {option.label}
              </option>
            ))}
          </select>
        </Popover>
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  required: false,
  values: [],
};

export const valuesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.any,
  })
);

Dropdown.propTypes = {
  label: PropTypes.string,
  values: valuesPropType,
  required: PropTypes.bool,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameField: PropTypes.string,
  placeholder: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  disabled: PropTypes.bool,
};

export default Dropdown;
