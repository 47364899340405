import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { Section } from "@dpdgroupuk/mydpd-ui";

import { onChangeCollectionInstructionsSubmit } from "./actions";
import EditPanel from "./components/EditPanel";
import InputWithTitle from "../../components/InputWithTitle";
import { collectEditValidators } from "../../models/validators";
import { hadleInputFocus, withCollectionEdit } from "./hocs";
import styles from "./ChangeCollectionInstructions.module.scss";
import { INSTRUCTION_PLACEHOLDER } from "../../constants/strings";
import { CHANGE_COLLECTION_INSTRUCTION_COMPONENT } from "../../constants/analytics";

const ChangeCollectionInstructions = ({
  onBackClick,
  handleSubmit,
  onSubmit,
  error,
}) => {
  return (
    <Section title="Collection instructions">
      <EditPanel
        title={
          "Help our driver to find the exact location of the collection such as directions, access codes, or useful instructions such as knock loudly"
        }
        onBackClick={onBackClick}
        onSubmit={handleSubmit(onSubmit)}
        error={error}
      >
        <Field
          name={"instructions"}
          classNameContainer={styles.instructions_container}
          classNameField={styles.instructions}
          component={InputWithTitle}
          multiline
          maxLength={200}
          rows="8"
          placeholder={INSTRUCTION_PLACEHOLDER}
        />
      </EditPanel>
    </Section>
  );
};

ChangeCollectionInstructions.defaultProps = {
  onBackClick: () => null,
  onSubmit: () => null,
};

ChangeCollectionInstructions.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  onBackClick: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  withCollectionEdit({
    onSubmit: onChangeCollectionInstructionsSubmit,
    validate: collectEditValidators.changeCollectionInstructions,
  }),
  withTrack(CHANGE_COLLECTION_INSTRUCTION_COMPONENT.LOAD),
  withTrackProps({
    onBackClick: () => CHANGE_COLLECTION_INSTRUCTION_COMPONENT.CLICK_BACK,
    onSubmit: () => CHANGE_COLLECTION_INSTRUCTION_COMPONENT.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case "sms":
          return CHANGE_COLLECTION_INSTRUCTION_COMPONENT.CLICK_SMS;
        case "email":
          return CHANGE_COLLECTION_INSTRUCTION_COMPONENT.CLICK_EMAIL;
        case "contactName":
          return CHANGE_COLLECTION_INSTRUCTION_COMPONENT.CLICK_CONTACT_NAME;
        case "instructions":
          return CHANGE_COLLECTION_INSTRUCTION_COMPONENT.CLICK_INSTRUCTIONS;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(ChangeCollectionInstructions);
