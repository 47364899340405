import { createSelector } from "reselect";

export const getPostcodeFinder = state => state.features.postcodeFinder;

export const getAddresses = createSelector(
  getPostcodeFinder,
  ({ addresses }) => addresses
);

export const getError = createSelector(getPostcodeFinder, ({ error }) => error);
