import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { propTypes as ReduxFormPropTypes } from "redux-form";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { Section } from "@dpdgroupuk/mydpd-ui";

import { REBOOK_COLLECTION_COMPONENT } from "../../constants/analytics";
import { onRebookCollectionSubmit } from "./actions";
import EditPanel from "./components/EditPanel";
import DayPickerWithInstruction from "./components/DayPickerWithInstruction";
import { collectEditValidators } from "../../models/validators";
import { hadleInputFocus, loadActionDates, withCollectionEdit } from "./hocs";
import { PLEASE_CHOOSE_A_DATE_BELOW } from "../../constants/strings";

const RebookCollection = ({
  onBackClick,
  handleSubmit,
  onSubmit,
  error,
  ...rest
}) => {
  return (
    <Section title="Rebook Collection">
      <EditPanel
        title={"Please collect the parcel on the following date"}
        onBackClick={onBackClick}
        onSubmit={handleSubmit(onSubmit)}
        error={error}
      >
        <DayPickerWithInstruction
          {...rest}
          title={PLEASE_CHOOSE_A_DATE_BELOW}
          defaultHeaderDate={rest.collection.collectionDate}
        />
      </EditPanel>
    </Section>
  );
};

RebookCollection.defaultProps = {
  onBackClick: () => null,
  onSubmit: () => null,
};

RebookCollection.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  onBackClick: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  loadActionDates,
  withCollectionEdit({
    onSubmit: onRebookCollectionSubmit,
    validate: collectEditValidators.changeCollectionDateValidator,
  }),
  withTrack(REBOOK_COLLECTION_COMPONENT.LOAD),
  withTrackProps({
    onBackClick: () => REBOOK_COLLECTION_COMPONENT.CLICK_BACK,
    onSubmit: () => REBOOK_COLLECTION_COMPONENT.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case "sms":
          return REBOOK_COLLECTION_COMPONENT.CLICK_SMS;
        case "email":
          return REBOOK_COLLECTION_COMPONENT.CLICK_EMAIL;
        case "contactName":
          return REBOOK_COLLECTION_COMPONENT.CLICK_CONTACT_NAME;
        case "collectionDate":
          return REBOOK_COLLECTION_COMPONENT.CLICK_DATE;
        case "instructions":
          return REBOOK_COLLECTION_COMPONENT.CLICK_INSTRUCTIONS;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(RebookCollection);
