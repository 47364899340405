import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FallBackImage, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { CollectionTypes } from "../../../../models/types";
import { COLLECTION_STATUS } from "../../../../constants/analytics";
import styles from "./CollectionStatus.module.scss";

const CollectionStatus = ({ className, collection }) => {
  const { logoImg } = useMyDpdTheme();
  return (
    <Container className={classNames(styles.container, className)}>
      <Row>
        <Col lg={8}>
          <span
            className={styles.tracking_status}
            dangerouslySetInnerHTML={{
              __html: collection.collectionStatusFull,
            }}
          />
        </Col>
        <Col lg={4} className="text-right m-auto">
          <FallBackImage
            className={styles.customer_logo}
            src={collection.customer.logo || logoImg}
            fallBackSrc={logoImg}
            alt={collection.customer.displayName}
          />
        </Col>
      </Row>
    </Container>
  );
};

CollectionStatus.propTypes = {
  className: PropTypes.string,
  collection: PropTypes.shape(CollectionTypes.Collection),
};

export default withTrack(COLLECTION_STATUS.LOAD)(CollectionStatus);
