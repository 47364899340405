import firebase from "firebase/app";
import { ConsoleLogger, Tracker } from "@dpdgroupuk/react-event-tracker";

export const setUserProperties = user => {
  firebase.analytics().setUserId(user.uid);
  firebase.analytics().setUserProperties(user);
};

export default new Tracker({
  projectRef: "CC",
  analytics: ConsoleLogger,
});
