import React from "react";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import classNames from "classnames";

import styles from "./Tile.module.scss";

const Tile = ({ title, amount, disabled, onClick }) => {
  return (
    <Col
      className={classNames(styles.container, disabled && styles.disabled)}
      onClick={onClick}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.amount}>{amount}</span>
    </Col>
  );
};

Tile.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Tile.defaultProps = {
  onClick: () => null,
};

export default Tile;
