import React from "react";
import PropTypes from "prop-types";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Footer = ({ children }) => (
  <Row className="h-100 align-items-center">
    <Col className="text-right">{children}</Col>
  </Row>
);

Footer.propTypes = {
  children: PropTypes.node,
};

export default Footer;
