import { ActionTypes } from "./actions";

export const initialState = {
  stats: {},
  watchListItems: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_DASHBOARD_STATS.SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        stats: { ...data },
      };
    }
    default:
      return state;
  }
};
