import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./TypedRadioInput.module.scss";

const TypedRadioInput = ({
  label,
  classNameLabel,
  value,
  input,
  children,
  ...inputProps
}) => {
  return (
    <div className={classNames(styles.typed_label, classNameLabel)}>
      <input type="radio" value {...input} {...inputProps} />
      {typeof children === "function"
        ? children({ disabled: !input.checked })
        : React.cloneElement(children, { disabled: !input.checked })}
    </div>
  );
};

TypedRadioInput.propTypes = {
  label: PropTypes.string,
  input: PropTypes.object,
  value: PropTypes.string,
  classNameContainer: PropTypes.string,
  classNameLabel: PropTypes.string,
  children: PropTypes.any,
};

export default TypedRadioInput;
