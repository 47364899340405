import { createSelector } from "reselect";

import { sortByDate } from "./model";

export const getCollectionDetails = state => state.ui.collView;

export const getCollection = createSelector(
  getCollectionDetails,
  ({ collection }) => ({
    ...collection,
    collectionEvents: sortByDate(collection.collectionEvents),
  })
);

export const getCollectionActions = createSelector(
  getCollectionDetails,
  ({ actions }) => actions
);
