import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { CellPropsTypes } from "@dpdgroupuk/mydpd-ui";

import { CollectionTypes } from "../../../../models/types";
import { COLLECTION_HISTORY_COMPONENT } from "../../../../constants/analytics";
import MapView from "../../../../components/MapView";
import { getEventLocationMapUrl } from "../../model";
import mapImg from "../../../../assets/icons/map.svg";

const MapViewCell = ({ row, onClickCollectionMap, onCloseCollectionMap }) => {
  const [showMap, setShowMap] = useState(false);
  const { latitude, longitude } = row;

  return (
    <>
      {latitude && longitude && (
        <img
          alt="map"
          className="cursor-pointer"
          src={mapImg}
          onClick={() => {
            onClickCollectionMap();
            setShowMap(true);
          }}
        />
      )}
      <MapView
        show={showMap}
        src={getEventLocationMapUrl({ longitude, latitude })}
        onClose={() => {
          onCloseCollectionMap();
          setShowMap(false);
        }}
      />
    </>
  );
};

MapViewCell.propTypes = {
  onClick: PropTypes.func,
  ...CellPropsTypes,
  row: PropTypes.shape(CollectionTypes.CollectionEvent),
};

export default withTrackProps({
  onClickCollectionMap: COLLECTION_HISTORY_COMPONENT.CLICK_MAP,
  onCloseCollectionMap: COLLECTION_HISTORY_COMPONENT.CLOSE_MAP,
})(MapViewCell);
