import firebase from "firebase/app";

import "firebase/firestore";
import "firebase/analytics";

const { REACT_APP_FIREBASE_PROJECT_ID } = process.env;

const config = {
  authDomain: `${REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(config);

window.firebase = firebase; // TODO: Remove

export default firebase;
