import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const Link = ({ href, name, isActive, ...restProps }) => (
  <NavLink
    to={href}
    activeClassName="active"
    isActive={typeof isActive === "function" ? isActive : undefined}
    {...restProps}
  >
    {name}
  </NavLink>
);

Link.propTypes = {
  href: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
  isActive: PropTypes.oneOfType(PropTypes.bool, PropTypes.func),
};

export default Link;
