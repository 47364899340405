import { compose, lifecycle } from "recompose";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { SubmissionError } from "redux-form";
import { withLoader, withOverlay, withPrompt } from "@dpdgroupuk/mydpd-ui";

import { COLLECTIONS } from "../../router";
import { withAuthUser } from "../../components/AuthUser";
import {
  getFindByCode,
  getTotalCollectionsItems,
  getViewCollections,
} from "../../redux/collections/selectors";
import {
  createLocationState,
  getFilters,
  getLocationState,
  getQueryPagination,
  getSearchQuery,
  stringifyQuery,
} from "../../utils/query";
import withReportFeedbackHandler from "../../HOCS/withReportFeedbackHandler";
import withOnLoadFailure from "../../HOCS/withOnLoadFailure";

export default compose(
  withRouter,
  withReportFeedbackHandler,
  withOnLoadFailure,
  withLoader({
    loadFn: async (
      {
        searchCollections,
        fetchCollections,
        filtersCollections,
        location,
        match,
        history,
        ...rest
      },
      fetchOptions
    ) => {
      const query = getSearchQuery(location);
      let state = getLocationState(location);
      const newState = {};
      const page = getQueryPagination(location);
      if (!state.searchFindByCode) {
        const { findByCode, expiresIn } = await searchCollections(
          query,
          fetchOptions
        );
        if (findByCode) {
          newState.searchFindByCode = findByCode;
          newState.expiresIn = expiresIn;
        }
      }
      if (!state.filterFindByCode) {
        const filters = getFilters(location);
        if (filters) {
          const { findByCode } = await filtersCollections(
            newState.searchFindByCode || state.searchFindByCode,
            filters,
            fetchOptions
          );
          newState.filterFindByCode = findByCode;
        }
      }
      state = createLocationState({
        ...state,
        ...newState,
      });
      if (!isEmpty(newState)) {
        history.replace({
          search: location.search,
          state,
        });
      }

      if (state.filterFindByCode || state.searchFindByCode) {
        return fetchCollections(
          state.filterFindByCode || state.searchFindByCode,
          page,
          fetchOptions
        );
      }
    },
  }),
  withPrompt,
  withOverlay,
  withAuthUser,
  connect(
    state => ({
      totalCount: getTotalCollectionsItems(state),
      collections: getViewCollections(state),
      findByCode: getFindByCode(state),
    }),
    (
      dispatch,
      {
        history,
        location,
        overlay,
        resetFilters,
        filtersCollections,
        searchCollections,
      }
    ) => {
      const onNextOrPrev = targetPage => {
        const query = getSearchQuery(location);
        const filters = getFilters(location);

        history.push({
          search: stringifyQuery({
            ...query,
            ...filters,
            page: targetPage,
          }),
          state: getLocationState(location),
        });
      };

      return {
        onFilter: async values => {
          try {
            overlay.show();
            const query = getSearchQuery(location);
            const state = getLocationState(location);
            if (!state.searchFindByCode) {
              const result = await searchCollections(query);
              state.searchFindByCode = result.findByCode;
              state.filterFindByCode = result.findByCode; // reset filter to search state
              state.expiresIn = result.expiresIn;
            }

            if (!isEmpty(values)) {
              const { findByCode } = await filtersCollections(
                state.searchFindByCode,
                values
              );
              state.filterFindByCode = findByCode;
            } else {
              state.filterFindByCode = null;
            }

            overlay.hide();

            history.push({
              search: stringifyQuery({
                ...query,
                ...values,
                accountCode: values.accountCode,
              }),
              state: createLocationState(state),
            });
          } catch (e) {
            overlay.hide();
            throw new SubmissionError({
              _error: "No results found",
            });
          }
        },
        onNext: onNextOrPrev,
        onPrevious: onNextOrPrev,
        onReset: async () => {
          resetFilters();
          const query = getSearchQuery(location);
          const state = getLocationState(location);
          state.filterFindByCode = null;
          if (!state.searchFindByCode) {
            overlay.show();
            const { findByCode, expiresIn } = await searchCollections(query);
            state.expiresIn = expiresIn;
            state.searchFindByCode = findByCode;
          }
          overlay.hide();

          history.push({
            search: stringifyQuery(query),
            state: createLocationState(state),
          });
        },
        onClickRow: ({ collectionCode }) =>
          history.push(`${COLLECTIONS}/${collectionCode}`),
      };
    }
  ),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { key, state } = this.props.location;
      const { key: prevKey, state: prevState } = prevProps.location;
      if (
        prevKey !== key ||
        JSON.stringify(prevState) !== JSON.stringify(state)
      ) {
        this.props.overlay.show();
        const { reloadFn } = this.props;
        reloadFn(true).finally(this.props.overlay.hide);
      }
    },

    componentWillUnmount() {
      this.props.clearFindByCodeCache();
    },
  })
);
