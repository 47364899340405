import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { Trackable, trackProps } from "@dpdgroupuk/react-event-tracker";
import { bindActionCreators } from "redux";

import {
  withCollectionAnalytics,
  withCollectionHandlers,
} from "../../features/CollectionListView";
import { CollectionActions } from "../../redux/collections";
import * as watchListSelectors from "./selectors";
import WatchListCollectionView from "./WatchListCollectionView";
import { getTitle, trackLoadAction } from "../DashboardCollectionsResult/utils";
import {
  createLocationState,
  getCurrentPageByTotal,
  getFilters,
  getSearchQuery,
  stringifyQuery,
} from "../../utils/query";
import { removeFromWatchList, resetForm } from "./actions";

const TrackableCollectionPage = ({ loadId, interfaceId, ...rest }) => {
  const pageTitle = getTitle(rest.match.params.collectionType);

  return (
    <Trackable loadId={loadId} interfaceId={interfaceId}>
      <WatchListCollectionView {...rest} pageTitle={pageTitle} />
    </Trackable>
  );
};

TrackableCollectionPage.propTypes = {
  loadId: PropTypes.string,
  interfaceId: PropTypes.string,
  watchListItems: PropTypes.array,
};

const mapDispatchToProps = (dispatch, { match }) => ({
  ...bindActionCreators(
    { removeFromWatchList, resetForm, ...CollectionActions },
    dispatch
  ),
  searchCollections: (query, fetchOptions) =>
    dispatch(
      CollectionActions.searchCollectionsByType(
        query,
        match.params.collectionType,
        fetchOptions
      )
    ),
});

export default compose(
  connect(null, mapDispatchToProps),
  withCollectionHandlers,
  withProps(
    ({
      match,
      history,
      searchCollections,
      filtersCollections,
      removeFromWatchList,
      overlay,
      location,
      resetForm,
    }) => ({
      ...trackLoadAction(match.params.collectionType, trackProps),
      onRemoveClick: async ids => {
        const searchQuery = getSearchQuery(location);
        try {
          overlay.show();
          await removeFromWatchList(ids);
          resetForm();

          const {
            findByTotal,
            findByCode: searchFindByCode,
            collectionCodes,
            expiresIn,
          } = await searchCollections(searchQuery);

          const refresh = (query = {}, filterFindByCode, filterFindByTotal) => {
            history.replace({
              search: stringifyQuery({
                ...searchQuery,
                ...query,
                page: getCurrentPageByTotal(
                  location,
                  filterFindByTotal || findByTotal
                ),
              }),
              state: createLocationState({
                searchFindByCode,
                filterFindByCode: filterFindByCode || searchFindByCode,
                expiresIn,
              }),
            });
          };

          if (findByTotal === 0) {
            history.push("/dashboard");
          } else if (findByTotal === 1) {
            history.push(`/collections/${collectionCodes[0]}`);
          } else {
            const filters = getFilters(location);
            if (filters) {
              const {
                findByCode: filterFindByCode,
                findByTotal,
              } = await filtersCollections(searchFindByCode, filters);
              refresh(filters, filterFindByCode, findByTotal);
            } else {
              refresh();
            }
          }
          overlay.hide();
        } catch (e) {
          overlay.hide();
          history.replace({
            search: stringifyQuery(searchQuery),
          });
        }
      },
    })
  ),
  withCollectionAnalytics,
  connect(state => ({
    selectedItems: watchListSelectors.getSelectedItems(state),
  }))
)(TrackableCollectionPage);
