import React from "react";
import PropTypes from "prop-types";

import styles from "./Error.module.scss";

const NoAddressesError = ({ message }) => (
  <div className={styles.error}>
    <div className={styles.error_text}>{message}</div>
    <div className={styles.error_img} />
  </div>
);

NoAddressesError.defaultProps = {
  message:
    "Unfortunately we aren’t able \n to locate that address, \n please try again",
};

NoAddressesError.propTypes = {
  message: PropTypes.string,
};

export default NoAddressesError;
