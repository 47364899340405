import React from "react";
import { compose } from "recompose";
import { trackProps, withTrack } from "@dpdgroupuk/react-event-tracker";
import PropTypes from "prop-types";
import { Page } from "@dpdgroupuk/mydpd-ui";

import { OUT_FOR_COLLECTION } from "../../constants/analytics";
import Delimiter from "../../components/Delimiter";
import MapView from "./components/MapView";
import CollectionStatus from "./components/CollectionStatus";
import { CollectionTypes } from "../../models/types";
import { getEtaProgressData, getMapUrl, getPageTitle } from "./model";
import CollectionDetails from "./components/CollectionDetails";
import EtaBar from "./components/EtaBar";
import CollectionHistory from "./components/CollectionHistory";
import ShipmentDetails from "./components/ShipmentDetails";
import { useAuthUser } from "../../components/AuthUser";

const OutForCollection = ({ collection, children, ...rest }) => {
  const { username } = useAuthUser();

  const { showProgressBar, ...progressData } = React.useMemo(
    () => getEtaProgressData(collection),
    [collection]
  );

  return (
    <Page title={getPageTitle(collection)} username={username}>
      <Page.Section>
        <CollectionStatus collection={collection} />
        <MapView src={getMapUrl(collection.collectionCode)} />
        {showProgressBar && <EtaBar {...progressData} />}
        <CollectionDetails collection={collection} />
      </Page.Section>
      <Delimiter />
      {React.cloneElement(children, { ...rest })}
      <Delimiter />
      {collection.collectionEvents.length > 0 && (
        <>
          <Page.Section title="Collection History" closable>
            <CollectionHistory data={collection.collectionEvents} />
          </Page.Section>
          <Delimiter />
        </>
      )}
      {collection.deliveries.length > 0 && (
        <>
          <Page.Section title="Shipment Details">
            <ShipmentDetails deliveries={collection.deliveries} />
          </Page.Section>
          <Delimiter />
        </>
      )}
    </Page>
  );
};

OutForCollection.propTypes = {
  collection: PropTypes.shape(CollectionTypes.Collection),
  children: PropTypes.node,
};

export default compose(withTrack(trackProps(OUT_FOR_COLLECTION)))(
  OutForCollection
);
