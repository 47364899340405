import React from "react";
import PropTypes from "prop-types";
import { FallBackImage, Modal } from "@dpdgroupuk/mydpd-ui";

import styles from "./MapViewModal.module.scss";

const MapView = ({ src, show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <div className={styles.container}>
        <Modal.Header variant="brand" closeButton>
          Map View
        </Modal.Header>
        <Modal.Body className={styles.content}>
          <FallBackImage className={styles.img} src={src} />
        </Modal.Body>
      </div>
    </Modal>
  );
};

MapView.propTypes = {
  src: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MapView;
