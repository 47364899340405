import React from "react";
import PropTypes from "prop-types";
import { CellPropsTypes, useMyDpdTheme } from "@dpdgroupuk/mydpd-ui";

import {
  calculateDeliveriesQuantity,
  getQuantityUrl,
} from "../../../models/collection";
import { CollectionTypes } from "../../../models/types";

const QuantityCell = ({ row, onClickCollectedQuantity }) => {
  const { collectedQuantity, deliveries, deliverQuantity } = row;
  const { dpdDomain } = useMyDpdTheme();
  return (
    <span className="d-flex justify-content-center">
      {collectedQuantity > 0 ? (
        <a
          href={getQuantityUrl(row.collectionNumber, dpdDomain)}
          onClick={onClickCollectedQuantity}
        >
          {collectedQuantity}
        </a>
      ) : (
        calculateDeliveriesQuantity(deliveries) || deliverQuantity
      )}
    </span>
  );
};

QuantityCell.defaultProps = {
  onClickCollectedQuantity: () => null,
};

QuantityCell.propTypes = {
  onClick: PropTypes.func,
  ...CellPropsTypes,
  row: PropTypes.shape(CollectionTypes.Collection),
};

export default QuantityCell;
