import React from "react";
import { Field } from "redux-form";
import classNames from "classnames";

import InputWithTitle from "../../../../components/InputWithTitle";
import styles from "./NotificationDetails.module.scss";
import { Fields } from "../../../../constants/forms";
import {
  CONTACT_NAME,
  NOTIFICATION_DETAILS,
  NOTIFICATION_EMAIL,
  NOTIFICATION_MOBILE,
} from "../../../../constants/strings";

const NotificationDetails = () => {
  return (
    <div className={styles.notification_details}>
      <div className={classNames(styles.title)}>{NOTIFICATION_DETAILS}</div>
      <Field
        name={Fields.CONTACT_NAME}
        component={InputWithTitle}
        classNameField={styles.input}
        classNameContainer={classNames(
          styles.input_container,
          styles.vertical_margin
        )}
        label={`${CONTACT_NAME}:`}
        maxLength={255}
        normalize={(value, previousValue) => {
          const v = value === undefined ? previousValue : value;
          return (v || "").replace(/[^A-z\s]/g, "");
        }}
      />
      <Field
        name={Fields.PHONE}
        component={InputWithTitle}
        classNameField={styles.input}
        classNameContainer={classNames(
          styles.input_container,
          styles.vertical_margin
        )}
        label={`${NOTIFICATION_MOBILE}:`}
        maxLength={255}
      />
      <Field
        name={Fields.EMAIL}
        component={InputWithTitle}
        classNameField={styles.input}
        classNameContainer={classNames(
          styles.input_container,
          styles.vertical_margin
        )}
        label={`${NOTIFICATION_EMAIL}:`}
        maxLength={255}
      />
    </div>
  );
};

export default NotificationDetails;
