import React from "react";
import PropTypes from "prop-types";

export default function SafePlace({ fill = "#DC0032" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 68 68"
      style={{
        enableBackground: "new 0 0 68 68",
      }}
      xmlSpace="preserve"
    >
      <g id="Delivery_x5F_To_x5F_a_x5F_safe_x5F_place_x5F_blackred_x5F_pos_x5F_rgb">
        <g id="To_a_safe_Place_1_">
          <rect id="XMLID_2038_" fill="none" width="68" height="68" />
          <g id="XMLID_2036_">
            <path
              fill={fill}
              d="M29.998,51.745l-8.707-8.708c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l7.293,7.294
				l15.296-15.295c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L29.998,51.745z"
            />
          </g>
          <path
            fill="#414042"
            d="M55.002,34.672c-0.256,0-0.512-0.098-0.707-0.293L34,14.083L13.705,34.379
			c-0.391,0.391-1.023,0.391-1.414,0s-0.391-1.023,0-1.414L34,11.255l21.709,21.71c0.391,0.391,0.391,1.023,0,1.414
			C55.514,34.574,55.258,34.672,55.002,34.672z"
          />
          <path
            fill="#414042"
            d="M52.002,56.331H15.997V35.328c0-0.552,0.448-1,1-1s1,0.448,1,1v19.003h32.005V35.328
			c0-0.552,0.447-1,1-1s1,0.448,1,1V56.331z"
          />
        </g>
      </g>
      <g id="Layer_1" />
    </svg>
  );
}

SafePlace.propTypes = {
  fill: PropTypes.string,
};
