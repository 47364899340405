import { compose } from "recompose";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { SEARCH } from "../../constants/analytics";

export default compose(
  withTrack(SEARCH.LOAD),
  withTrackProps({
    onSubmit: SEARCH.CLICK_SEARCH,
    onSearchTypeChange: SEARCH.CLICK_SEARCH_SELECTOR,
  })
);
