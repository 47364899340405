import React from "react";
export default function Cancel() {
  return (
    <svg
      width="21px"
      height="21px"
      viewBox="0 0 21 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Delete_Cancel_blackred_pos_rgb</title>
      <desc>Created with Sketch.</desc>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Available-inflights"
          transform="translate(-757.000000, -329.000000)"
        >
          <g
            id="Delete_Cancel_blackred_pos_rgb"
            transform="translate(747.000000, 319.000000)"
          >
            <rect id="XMLID_1799_" x="0" y="0" width="39.6" height="39.6" />
            <path
              d="M30.0019251,30.6 C29.8488562,30.6 29.6957873,30.5414011 29.5791919,30.4248013 L10.9753416,11.8208466 C10.7415528,11.587049 10.7415528,11.2091459 10.9753416,10.9753482 C11.2091304,10.7415506 11.5870193,10.7415506 11.8208081,10.9753482 L30.4246584,29.5793028 C30.6584472,29.8131005 30.6584472,30.1910036 30.4246584,30.4248013 C30.307465,30.5414011 30.154994,30.6 30.0019251,30.6 Z"
              id="Path"
              fill="#414042"
              fillRule="nonzero"
            />
            <path
              d="M11.3980749,30.6 C11.245006,30.6 11.0919371,30.5414011 10.9753416,30.4248013 C10.7415528,30.1910036 10.7415528,29.8131005 10.9753416,29.5793028 L29.5791919,10.9753482 C29.8129807,10.7415506 30.1908696,10.7415506 30.4246584,10.9753482 C30.6584472,11.2091459 30.6584472,11.587049 30.4246584,11.8208466 L11.8208081,30.4248013 C11.7042127,30.5414011 11.5511438,30.6 11.3980749,30.6 Z"
              id="Path"
              fill="#414042"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
