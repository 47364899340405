import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Popover } from "@dpdgroupuk/mydpd-ui";

import { getDay, getDayOfWeek, getMontAndYear } from "../../utils/date";
import "./DayPicker.scss";

const DayPicker = ({
  dates = [],
  selected,
  onSelect,
  defaultHeaderDate,
  meta,
  ...rest
}) => (
  <div className="day-picker">
    <div className="selected-date">
      {(selected || defaultHeaderDate) &&
        getMontAndYear(selected || defaultHeaderDate)}
    </div>
    <Popover isOpen={meta.error && meta.touched} content={meta.error}>
      <div className="days-container">
        {dates.map((date, i) => (
          <div className="day-column" key={i}>
            <div className="day-header">{getDayOfWeek(date)}</div>
            <div
              tabIndex={i}
              {...rest}
              className={classNames("day-body", {
                active: date === selected,
              })}
              onClick={() => {
                onSelect(date);
              }}
            >
              {getDay(date)}
            </div>
          </div>
        ))}
      </div>
    </Popover>
  </div>
);

DayPicker.propTypes = {
  selected: PropTypes.string,
  defaultHeaderDate: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  meta: PropTypes.object,
};

export const ReduxifiedDayPicker = ({ input, values, ...inputProps }) => (
  <DayPicker
    selected={input.value}
    dates={values}
    onSelect={input.onChange}
    {...input}
    {...inputProps}
  />
);

ReduxifiedDayPicker.propTypes = {
  input: PropTypes.object,
  values: PropTypes.arrayOf(PropTypes.string),
};

export default DayPicker;
