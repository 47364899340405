import { createSelector } from "reselect";
import { getFormValues } from "redux-form";

import { COLLECTION_SEARCH_FORM, Fields } from "../../constants/forms";
import {
  isDateWithinLast10Months,
  isValidDate,
} from "../../models/validators/date";

const emptyObject = {};

export const isValidStartDate = createSelector(
  getFormValues(COLLECTION_SEARCH_FORM),
  (values = emptyObject) => {
    const startDate =
      values[Fields.COLLECTION_START_DATE] || values[Fields.CREATE_START_DATE];
    return !!(
      startDate &&
      isValidDate(startDate) &&
      isDateWithinLast10Months(startDate)
    );
  }
);
