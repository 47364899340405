import { withTrackProps } from "@dpdgroupuk/react-event-tracker";
import { ImageView } from "@dpdgroupuk/mydpd-ui";

import { COLLECTION_IMAGE_VIEW } from "../../../../constants/analytics";

export default withTrackProps({
  onShow: COLLECTION_IMAGE_VIEW.LOAD,
  onClose: COLLECTION_IMAGE_VIEW.CLOSE_IMAGE,
  onImageSelect: (activeIndex, event) => {
    const className = event.target.className;
    if (className.includes("next")) {
      return COLLECTION_IMAGE_VIEW.CLICK_NEXT;
    } else {
      return COLLECTION_IMAGE_VIEW.CLICK_PREV;
    }
  },
})(ImageView);
