import React, { useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Card from "react-bootstrap/Card";

import Error from "../Error";
import AddressList from "../AddressList";
import { AddressTypes } from "../../../../models/types";
import styles from "./Results.module.scss";

const ResultsPanel = ({ addresses, error, onSelect }) => {
  const scrollableContainerRef = React.useRef(null);
  const title = React.useMemo(() => {
    let result = "Results";
    if (addresses) {
      result += ` (${addresses.length})`;
    }
    return result;
  }, [addresses]);

  // Scroll to top if search items have been changed
  useEffect(() => {
    if (scrollableContainerRef && scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTop = 0;
    }
  }, [addresses]);
  return (
    <Card className={styles.results_panel}>
      <Card.Header
        className={classNames("cc-modal-header", styles.results_title)}
      >
        {title}
      </Card.Header>
      <div className={styles.results_body} ref={scrollableContainerRef}>
        {error ? (
          <Error />
        ) : addresses ? (
          <AddressList addresses={addresses} onSelect={onSelect} />
        ) : null}
      </div>
    </Card>
  );
};

ResultsPanel.propTypes = {
  onSelect: PropTypes.func,
  error: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.shape(AddressTypes.Address)),
};

export default ResultsPanel;
