import { POSTCODE } from "./regex";
import { isDateWithinLast10Months, isValidDate } from "./date";

export default values => {
  const errors = {};

  if (values.collectDate) {
    if (!isValidDate(values.collectDate)) {
      errors.collectDate = "Please enter valid date";
    }

    if (!isDateWithinLast10Months(values.collectDate)) {
      errors.collectDate = "Please enter a date within the last 10 months";
    }
  }

  if (values.collectPostcode && !POSTCODE.test(values.collectPostcode)) {
    errors.collectPostcode = "Invalid Postcode";
  }
  return errors;
};
