import React from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { propTypes, reduxForm } from "redux-form";
import classNames from "classnames";
import { isEmpty, omitBy } from "lodash";
import {
  COLLECTION_TYPES_ENUM,
  COUNTRIES_ENUM,
} from "@dpdgroupuk/redback-enums";
import { compose, withProps } from "recompose";
import { Button, Popover } from "@dpdgroupuk/mydpd-ui";

import Dropdown, {
  DropdownRadio,
  valuesPropType,
} from "../../components/Dropdown";
import * as M from "../../constants/strings";
import CustomDatePicker from "../../components/CustomDatePicker";
import styles from "./FilterForm.module.scss";
import Field from "../../components/Field";
import PostcodeFinderInput from "../../features/PostcodeFinder";
import {
  COLLECTION_SEARCH_FILTER_FORM,
  FilterFields,
} from "../../constants/forms";
import * as validators from "../../models/validators";
import { withAuthUser } from "../../components/AuthUser";
import { SORT_BY } from "../../models/enum";

const FilterForm = ({
  handleSubmit,
  onReset,
  onFilter,
  onFieldEntry,
  submitting,
  invalid,
  sortBy,
  countries,
  collectionTypes,
  accounts,
  emptyFilters,
  dirty,
  error,
}) => (
  <Container fluid className={styles.filter_form}>
    <Row>
      <Col>
        <Field
          component={Dropdown}
          placeholder={M.ALL_ACCOUNTS}
          label={`${M.ACCOUNT}:`}
          classNameField={styles.input}
          name={FilterFields.ACCOUNT_CODE}
          values={accounts}
          onBlur={onFieldEntry}
        />
      </Col>
      <Col className={classNames(styles.mid_col, styles.date_container)}>
        <Field
          name={FilterFields.COLLECT_DATE}
          component={CustomDatePicker}
          classNameField={styles.input}
          classNameLabel={classNames(styles.label_right, styles.toggle_label)}
          classNameContainer={styles.mid_col_container}
          label={`${M.DATE}:`}
          placeholderText={M.COLLECTION_DATE}
          showDisabledMonthNavigation
          onBlur={onFieldEntry}
        />
      </Col>
      <Col className={styles.last_col}>
        <Field
          component={Dropdown}
          placeholder={M.ALL_COLLECTION_TYPES}
          label={`${M.COLLECTION_TYPE}:`}
          classNameField={styles.input}
          classNameLabel={styles.label_right}
          name={FilterFields.COLLECT_TYPE_CODE}
          values={collectionTypes}
          onBlur={onFieldEntry}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          component={Dropdown}
          placeholder={M.ALL_COUNTRIES}
          label={`${M.COLLECTION_COUNTRY}:`}
          classNameField={styles.input}
          name={FilterFields.COLLECT_COUNTRY}
          values={countries}
          onBlur={onFieldEntry}
        />
      </Col>
      <Col className={styles.mid_col}>
        <Field
          component={DropdownRadio}
          label={`${"OFC Today"}:`}
          classNameField={styles.toggle_input}
          classNameContainer={styles.toggle_container}
          classNameLabel={classNames(styles.label_right, styles.toggle_label)}
          name={FilterFields.OFC_TODAY}
          onBlur={onFieldEntry}
        />
      </Col>
      <Col className={styles.last_col}>
        <Field
          component={Dropdown}
          placeholder={M.NOT_SPECIFIED}
          classNameLabel={styles.label_right}
          classNameField={styles.input}
          label={`${M.SORT_BY}:`}
          name={FilterFields.SORT_BY}
          values={sortBy}
          onBlur={onFieldEntry}
        />
      </Col>
    </Row>
    <Row>
      <Col className={styles.first_col}>
        <Field
          name={FilterFields.COLLECT_POSTCODE}
          component={PostcodeFinderInput}
          label={`${M.COLLECTION_POSTCODE}:`}
          maxLength={255}
          onBlur={onFieldEntry}
        />
      </Col>
      <Col className={styles.mid_col}>
        <Field
          component={DropdownRadio}
          label="Collected:"
          classNameField={styles.toggle_input}
          classNameContainer={styles.toggle_container}
          classNameLabel={classNames(styles.label_right, styles.toggle_label)}
          name={FilterFields.COLLECTED}
          onBlur={onFieldEntry}
        />
      </Col>
      <Col className="text-right">
        {(!emptyFilters || dirty) && (
          <Button
            className={styles.reset_button}
            variant="dark"
            onClick={onReset}
          >
            Reset
          </Button>
        )}
        <Popover isOpen={error} content={error}>
          <Button
            onClick={handleSubmit(values => onFilter(omitBy(values, isEmpty)))}
            disabled={submitting || invalid || !dirty}
          >
            {M.FILTER}
          </Button>
        </Popover>
      </Col>
    </Row>
  </Container>
);

FilterForm.defaultProps = {
  onFilter: () => null,
  onReset: () => null,
  onFieldEntry: () => null,
  sortBy: SORT_BY,
  emptyFilters: true,
  countries: COUNTRIES_ENUM,
  collectionTypes: COLLECTION_TYPES_ENUM,
};

FilterForm.propTypes = {
  ...propTypes,
  emptyFilters: PropTypes.bool,
  accounts: valuesPropType,
  countries: valuesPropType,
  collectionTypes: valuesPropType,
  sortByValues: valuesPropType,
  onReset: PropTypes.func,
  onFilter: PropTypes.func,
  onFieldEntry: PropTypes.func,
};

export default compose(
  withAuthUser,
  withProps(({ authUser }) => ({
    accounts: authUser.accounts,
  })),
  reduxForm({
    enableReinitialize: true,
    form: COLLECTION_SEARCH_FILTER_FORM,
    validate: validators.filterCollections,
  })
)(FilterForm);
