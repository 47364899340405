import { map } from "lodash";
import {
  SAFE_PLACE_NAME,
  SAFE_PLACE_TYPE,
  utils,
} from "@dpdgroupuk/redback-enums";

export const SORT_BY = [
  utils.createEnum("COLLECT.DATE", "Collection Date"),
  utils.createEnum("CUSTOMER.REF", "Customer Reference"),
  utils.createEnum("CUSTOMER.CODE", "Account Number"),
  utils.createEnum("I_REQUEST_NO", "Collection Number"),
  utils.createEnum("COLLECT.TYPE.NAME", "Collection Type"),
  utils.createEnum("COLLECT.READY.TIME", "Ready Time"),
  utils.createEnum("COLLECT.CLOSE.TIME", "Close Time"),
  utils.createEnum("COLLECT.STATUS", "Status"),
  utils.createEnum("COLLECT.POSTCODE", "Collection Postcode"),
  utils.createEnum("PARCEL.QTY", "Parcel Quantity"),
];

export const COLLECTION_DATE_OPTIONS = [
  {
    value: "create",
    label: "Creation Date",
  },
  {
    value: "collection",
    label: "Collection Date",
  },
];

export const getSafePlaceObjects = () =>
  map(
    [
      SAFE_PLACE_TYPE.FPC,
      SAFE_PLACE_TYPE.GAR,
      SAFE_PLACE_TYPE.OBL,
      SAFE_PLACE_TYPE.RPC,
      SAFE_PLACE_TYPE.OTH,
    ],
    type => ({
      name: SAFE_PLACE_NAME[type],
      type,
    })
  );
