import React from "react";

import Dropdown from "./Dropdown";

const DropdownRadio = props => (
  <Dropdown
    placeholder={"--"}
    values={[
      {
        value: true,
        label: "Yes",
      },
      {
        value: false,
        label: "No",
      },
    ]}
    {...props}
  />
);

export default DropdownRadio;
