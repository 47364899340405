import queryString from "query-string";
import { get, isEmpty, pick, valuesIn } from "lodash";

import { Fields, FilterFields } from "../constants/forms";
import { APIS_ORIGIN_URL } from "../apis";
import { omitNilValues } from "./object";

export const INITIAL_PAGE_QUERY = { page: 1, pageSize: 5 };

export const parseQuery = (location, parseNumbers) => {
  const query = queryString.parse(location.search, {
    parseNumbers,
  });

  return {
    ...INITIAL_PAGE_QUERY,
    ...query,
  };
};
export const stringifyQuery = (query, encode = true) =>
  queryString.stringify(query, {
    encode,
  });

export const transformQuery = (findByCode, values = {}) => ({
  findByCode,
  ...values,
});

export const getSearchQuery = location => {
  const query = parseQuery(location);
  const res = pick(query, [
    "reference", // from external, can be customerReference or collectionReference
    Fields.ACCOUNT,
    Fields.REFERENCE,
    Fields.CALLING_CARD_REFERENCE,
    Fields.EMAIL,
    Fields.PHONE,
    Fields.POSTCODE,
    Fields.COLLECTION_START_DATE,
    Fields.COLLECTION_END_DATE,
    Fields.CREATE_START_DATE,
    Fields.CREATE_END_DATE,
    Fields.DATE,
  ]);

  return isEmpty(res) ? null : omitNilValues(res);
};

export const getLocationState = location => {
  const locationState = get(location, "state", {}) || {};
  if (locationState.expiresIn && locationState.expiresIn > Date.now()) {
    return locationState;
  }
  return {};
};

export const createLocationState = (state = {}) => {
  return omitNilValues(state);
};

export const getFilters = location => {
  const query = parseQuery(location);
  const res = pick(query, valuesIn(FilterFields));

  return isEmpty(res) ? null : omitNilValues(res);
};

export const getFiltersInitialValues = location => {
  const query = parseQuery(location);
  const filters = pick(query, valuesIn(FilterFields));
  const { account: searchAccountCode } = pick(query, Fields.ACCOUNT);
  return omitNilValues({
    ...filters,
    accountCode: filters.accountCode || searchAccountCode,
  });
};

export const areEmptyFilters = location => getFilters(location) === null;

export const getQueryPagination = location => {
  const query = parseQuery(location, true);
  const res = pick(query, ["page", "pageSize"]);

  return isEmpty(res) ? null : res;
};

export const getCurrentPageByTotal = (location, total) => {
  const { page, pageSize } = getQueryPagination(location);
  if (typeof total === "number") {
    const lastPage = Math.ceil(total / pageSize);
    if (page > lastPage) {
      return lastPage;
    }
  }

  return page;
};

export const getExportCSVLink = findByCode =>
  `${APIS_ORIGIN_URL}/collections/csv?findByCode=${findByCode}`;
