import { compose } from "recompose";
import { withTrack } from "@dpdgroupuk/react-event-tracker";
import { connect } from "react-redux";

import { SEARCH_RESULT_LIST } from "../../constants/analytics";
import CollectionListView, {
  withCollectionAnalytics,
  withCollectionHandlers,
} from "../../features/CollectionListView";
import { CollectionActions } from "../../redux/collections";

export default compose(
  withTrack(SEARCH_RESULT_LIST),
  connect(null, CollectionActions),
  withCollectionHandlers,
  withCollectionAnalytics
)(CollectionListView);
