import { COLLECTION_DASH_TYPE } from "@dpdgroupuk/redback-enums";

import {
  CANCELED_REBOOKED_COLLECTIONS_VIEW,
  CARD_LEFT_COLLECTIONS_VIEW,
  COLLECTED_COLLECTIONS_VIEW,
  FAILED_COLLECTIONS_VIEW,
  PENDING_COLLECTIONS_VIEW,
  UNSUCCESSFUL_COLLECTIONS_VIEW,
  WATCHED_COLLECTIONS_VIEW,
} from "../../constants/analytics";
import {
  CANCELLED_REBOOKED,
  CARD_LEFT,
  COLLECTED,
  FAILED,
  OUT_FOR_COLLECTION,
  PENDING,
  UNSUCCESSFUL,
  WATCH_LIST,
} from "../../constants/strings";

export const trackLoadAction = (collectionType, handler) => {
  switch (collectionType) {
    case COLLECTION_DASH_TYPE.PEND:
      return handler(PENDING_COLLECTIONS_VIEW);
    case COLLECTION_DASH_TYPE.CARD:
      return handler(CARD_LEFT_COLLECTIONS_VIEW);
    case COLLECTION_DASH_TYPE.UNS:
      return handler(UNSUCCESSFUL_COLLECTIONS_VIEW);
    case COLLECTION_DASH_TYPE.COLL:
      return handler(COLLECTED_COLLECTIONS_VIEW);
    case COLLECTION_DASH_TYPE.FAIL:
      return handler(FAILED_COLLECTIONS_VIEW);
    case COLLECTION_DASH_TYPE.CANC:
      return handler(CANCELED_REBOOKED_COLLECTIONS_VIEW);
    case COLLECTION_DASH_TYPE.WTCH:
      return handler(WATCHED_COLLECTIONS_VIEW);
  }
};

export const getTitle = collectionType => {
  switch (collectionType) {
    case COLLECTION_DASH_TYPE.PEND:
      return PENDING;
    case COLLECTION_DASH_TYPE.OFC:
      return OUT_FOR_COLLECTION;
    case COLLECTION_DASH_TYPE.CARD:
      return CARD_LEFT;
    case COLLECTION_DASH_TYPE.UNS:
      return UNSUCCESSFUL;
    case COLLECTION_DASH_TYPE.COLL:
      return COLLECTED;
    case COLLECTION_DASH_TYPE.FAIL:
      return FAILED;
    case COLLECTION_DASH_TYPE.CANC:
      return CANCELLED_REBOOKED;
    case COLLECTION_DASH_TYPE.WTCH:
      return WATCH_LIST;
  }
};
