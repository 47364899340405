import React from "react";

import { CollectionTypes } from "../../../../../models/types";
import styles from "../Progress/Progress.module.scss";

const Status = ({ routeStatus }) => {
  return <div className={styles.container}>{routeStatus}</div>;
};

Status.propTypes = {
  routeStatus: CollectionTypes.Route.routeStatus,
};

export default Status;
