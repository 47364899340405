import { zipWith } from "lodash";
import moment from "moment-timezone";

import { DEFAULT_DATE_TIME_FORMAT } from "../../constants/dateFormats";
import {
  calculatePositionInPeriod,
  chooseSmallerDate,
  formatCurrentDateTime,
  formatInternalTime,
  getCurrentDateTime,
} from "../../utils/date";
import {
  AddWishList,
  Cancel,
  ChangeDate,
  Document,
  Neighbour,
  Rebook,
  RemoveWishList,
  SafePlace,
} from "../../components/Icon";
import { APIS_ORIGIN_URL } from "../../apis";

const icons = {
  CHD: ChangeDate,
  CAN: Cancel,
  INS: Document,
  WTC: AddWishList,
  WTR: RemoveWishList,
  SFP: SafePlace,
  CFN: Neighbour,
  REB: Rebook,
};

const actionsOreder = {
  SFP: 1,
  CFN: 2,
  CHD: 3,
  CAN: 4,
  WTC: 5,
  WTR: 6,
  INS: 7,
  REB: 8,
};

const DEFAULT_SIZE = [940, 432];
const DEFAULT_ZOOM = 16;

const getDate = event =>
  moment(`${event.date} ${event.time}`, DEFAULT_DATE_TIME_FORMAT);

export const getMapUrl = (collectionCode, [width, height] = DEFAULT_SIZE) =>
  `${APIS_ORIGIN_URL}/map/route?collectionCode=${collectionCode}&size=${width}*${height}`;

export const getPageTitle = collection => {
  return `Collection ${collection.collectionReference} (${collection.customer.name})`;
};

export const getEventLocationMapUrl = (
  { latitude, longitude },
  [width, height] = DEFAULT_SIZE
) =>
  `${APIS_ORIGIN_URL}/map/coordinates?latLong=${[
    latitude,
    longitude,
  ].toString()}&size=${width}*${height}&zoom=${DEFAULT_ZOOM}`;

export const getImageUrl = (imageKey, imageType) =>
  `${APIS_ORIGIN_URL}/images/${imageKey}?imageType=${imageType}`;

export const getEtaProgressData = collection => {
  const {
    routeDetails: route,
    etaStartTime,
    etaEndTime,
    readyTime,
    closeTime,
    routeTime,
  } = collection;
  const currentDriverTime = getCurrentDateTime();

  const isEtaTimesAvailable = etaStartTime && etaEndTime;

  const startTime = isEtaTimesAvailable ? etaStartTime : readyTime;
  const endTime = isEtaTimesAvailable ? etaEndTime : closeTime;
  const startDateTime = formatCurrentDateTime(startTime);
  const endDateTime = formatCurrentDateTime(endTime);

  const routeDateTime = routeTime ? formatInternalTime(routeTime) : null;

  const driverStartTime = chooseSmallerDate(routeDateTime, startDateTime);

  let driverPosition = calculatePositionInPeriod(
    currentDriverTime,
    driverStartTime,
    endDateTime
  );
  // add min value as 5 to avoid negative values in progress
  driverPosition = Math.max(driverPosition, 5);

  const deliveryStartPosition = calculatePositionInPeriod(
    startDateTime,
    driverStartTime,
    endDateTime
  );

  const showProgressBar = driverPosition < 100;
  return {
    driverPosition,
    deliveryStartPosition,
    startTime,
    endTime,
    routeStatus: route.routeStatus,
    showProgressBar,
  };
};

const zipActions = disabled => (a, b) =>
  zipWith(a, b, (action, title) => ({
    icon: icons[action],
    action,
    title,
    disabled,
  }));

export const transformActions = ({
  availableActions = [],
  availableActionName = [],
  unavailableActions = [],
  unavailableActionsName = [],
}) =>
  [
    ...zipActions(false)(availableActions, availableActionName),
    ...zipActions(true)(unavailableActions, unavailableActionsName),
  ].sort((a, b) => actionsOreder[a.action] - actionsOreder[b.action]);

export const sortByDate = (data = []) =>
  data.sort((a, b) => getDate(b) - getDate(a));
