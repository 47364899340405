import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { Field, propTypes as ReduxFormPropTypes } from "redux-form";
import { Section } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { onCollectFromNeighbourSubmit } from "./actions";
import EditPanel from "./components/EditPanel";
import DayPickerWithInstruction from "./components/DayPickerWithInstruction";
import { collectEditValidators } from "../../models/validators";
import styles from "./CollectFromNeighbour.module.scss";
import {
  hadleInputFocus,
  loadActionDates,
  loadNeighbourAddresses,
  withCollectionEdit,
  withInitialCollectionDate,
} from "./hocs";
import { AddressTypes } from "../../models/types";
import Dropdown from "../../components/Dropdown";
import { COLLECT_FROM_NEIGHBOUR_COMPONENT } from "../../constants/analytics";
import TermsAndConditions from "./components/TermsAndConditions";

const CollectNeighbourSafePlace = ({
  onBackClick,
  handleSubmit,
  onSubmit,
  addresses,
  error,
  ...rest
}) => {
  return (
    <Section title="Collect from neighbour">
      <EditPanel
        title={"Choose a neighbour for us to collect from"}
        onBackClick={onBackClick}
        onSubmit={handleSubmit(onSubmit)}
        error={error}
      >
        <div>
          <Field
            component={Dropdown}
            name={"addressKey"}
            placeholder={"Please select a neighbour"}
            classNameContainer={styles.option_container}
            classNameField={styles.option}
            values={addresses.map(
              ({ quickviewAddress: label, addressKey: value }) => ({
                value,
                label,
              })
            )}
          />
          <DayPickerWithInstruction
            className={styles.date_picker_container}
            {...rest}
          />
          <Field
            name="agreement"
            type="radio"
            value={"true"}
            label={
              "Please confirm that the parcel is adequately packaged with the delivery address clearly visible"
            }
            component={TermsAndConditions}
          />
        </div>
      </EditPanel>
    </Section>
  );
};

CollectNeighbourSafePlace.defaultProps = {
  onBackClick: () => null,
  onSubmit: () => null,
};

CollectNeighbourSafePlace.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.string),
  addresses: PropTypes.arrayOf(PropTypes.shape(AddressTypes.Address)),
  onBackClick: PropTypes.func,
  ...ReduxFormPropTypes,
};

export default compose(
  loadNeighbourAddresses,
  loadActionDates,
  withInitialCollectionDate,
  withCollectionEdit({
    onSubmit: onCollectFromNeighbourSubmit,
    validate: collectEditValidators.collectFromNeighbourValidator,
  }),
  withTrack(COLLECT_FROM_NEIGHBOUR_COMPONENT.LOAD),
  withTrackProps({
    onBackClick: () => COLLECT_FROM_NEIGHBOUR_COMPONENT.CLICK_BACK,
    onSubmit: () => COLLECT_FROM_NEIGHBOUR_COMPONENT.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case "sms":
          return COLLECT_FROM_NEIGHBOUR_COMPONENT.CHANGE_MOBILE_NUMBER;
        case "email":
          return COLLECT_FROM_NEIGHBOUR_COMPONENT.CHANGE_EMAIL_ADDRESS;
        case "contactName":
          return COLLECT_FROM_NEIGHBOUR_COMPONENT.CHANGE_CONTACT_NAME;
        case "instructions":
          return COLLECT_FROM_NEIGHBOUR_COMPONENT.CHANGE_INSTRUCTIONS;
        case "addressKey":
          return COLLECT_FROM_NEIGHBOUR_COMPONENT.SELECT_ADDRESS;
        case "collectionDate":
          return COLLECT_FROM_NEIGHBOUR_COMPONENT.SELECT_DATE;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(CollectNeighbourSafePlace);
