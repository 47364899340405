import { connect } from "react-redux";
import { compose } from "recompose";
import { withLoader } from "@dpdgroupuk/mydpd-ui";

import { fetchNeighbourAddresses } from "../actions";
import { getNeighbourAddresses } from "../selectors";

export const loadNeighbourAddresses = compose(
  connect(),
  withLoader(({ collection, dispatch }, options) => {
    return dispatch(
      fetchNeighbourAddresses(collection.collectionAddress, options)
    );
  }),
  connect(state => ({
    addresses: getNeighbourAddresses(state),
  }))
);
