import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import { Loader } from "@dpdgroupuk/mydpd-ui";

import AuthUserContext from "./AuthUserContext";

const AuthUserProvider = ({
  children,
  initialUser,
  getUserFn,
  onAuthStateChanged,
}) => {
  const [currentUser, setUser] = React.useState(initialUser);

  const fetchUserFn = React.useCallback(
    async () =>
      getUserFn().then(user => {
        setUser(user);
        return user;
      }),
    [getUserFn, setUser, onAuthStateChanged]
  );

  React.useEffect(() => {
    onAuthStateChanged(currentUser);
  }, [currentUser]);

  return (
    <AuthUserContext.Provider value={currentUser}>
      <Loader loadFn={fetchUserFn}>{children}</Loader>
    </AuthUserContext.Provider>
  );
};

AuthUserProvider.defaultProps = {
  onAuthUserChanged: noop,
  initialUser: null,
};

AuthUserProvider.propTypes = {
  children: PropTypes.node,
  initialUser: PropTypes.object,
  getUserFn: PropTypes.func.isRequired,
  onAuthStateChanged: PropTypes.func,
};

export default AuthUserProvider;
