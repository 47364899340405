import { ActionTypes } from "./actions";

export const initialState = {
  error: "",
  addresses: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.SEARCH.SUCCESS:
      return {
        ...state,
        error: action.payload.length === 0 ? "Not found" : "",
        addresses: action.payload,
      };
    case ActionTypes.SEARCH.FAILURE: {
      const error = action.payload.error;
      return {
        ...state,
        error: error.errorType || error.message,
        addresses: null,
      };
    }
    case ActionTypes.CLEAR:
      return initialState;
    default:
      return state;
  }
};
