// Collections menu
export const SEARCH = "Search";
export const CREATE_COLLECTION = "Create Collection";
export const FILTER = "Filter";
export const RESET = "Reset";
export const WATCH_LIST = "Watch List";
export const DASHBOARD = "Dashboard";
export const COLLECTIONS = "Collections";

// Titles
export const NOTIFICATION_DETAILS = "Notification details";
export const COLLECTION_SEARCH = "Collection Search";
export const ADDRESS_LOOKUP = "Address Lookup";
export const PENDING = "Pending";
export const OUT_FOR_COLLECTION = "Out For Collection";
export const CARD_LEFT = "Card Left";
export const UNSUCCESSFUL = "Unsuccessful";
export const COLLECTED = "Collected";
export const FAILED = "Failed";
export const CANCELLED_REBOOKED = "Cancelled/Rebooked";

// Labels
export const ACCOUNT = "Account";
export const ALL_ACCOUNTS = "All Accounts";
export const ALL_COUNTRIES = "All Countries";
export const ALL_COLLECTION_TYPES = "All Collection Types";
export const COLLECTION_CALLING_CARD_REFERENCE =
  "Collection/Calling Card Reference";
export const CUSTOMER_REFERENCE = "Customer Reference";
export const COLLECTION_DATE = "Collection Date";
export const COLLECTION_TYPE = "Collection Type";
export const COLLECTION_COUNTRY = "Collection Country";
export const COLLECTION_POSTCODE = "Collection Postcode";
export const SORT_BY = "Sort By";
export const DATE = "Date";
export const FROM_DATE = "From Date";
export const TO_DATE = "To Date";
export const EMAIL_ADDRESS = "Email Address";
export const PHONE_NUMBER = "Phone Number";
export const POSTCODE = "Postcode";
export const PLEASE_CHOOSE_A_FROM_DATE = "Please choose a From date";
export const PLEASE_CHOOSE_A_TO_DATE = "Please choose a To date";
export const ORGANISATION = "Organisation";
export const PROPERTY = "Property";
export const STREET = "Street";
export const LOCALITY = "Locality";
export const TOWN = "Town";
export const COUNTY = "County";
export const NOT_SPECIFIED = "Not Specified";
export const OUT_FOR_COLLECTION_TODAY = "Out For Collection Today";
export const CONTACT_NAME = "Contact Name";
export const NOTIFICATION_MOBILE = "Notification Mobile";
export const NOTIFICATION_EMAIL = "Notification Email";

// Messages

export const ACTION_CAN_NOT_BE_COMPLETED =
  "We can’t complete this action right now, please try again";
export const ADD_COLLECTION_TO_WTC =
  "Add this\n collection to the\n watch list";
export const REMOVE_COLLECTION_FROM_WTC =
  "Clicking Confirm\n will remove this\n collection from\n the watch list";
export const COLLECTION_DATE_IS_MANDATORY = "Collection Date is mandatory";
export const INSTRUCTION_PLACEHOLDER =
  "Add any instructions that may help the driver\nmax 200 characters";
export const PLEASE_CHOOSE_A_DATE_BELOW = "Please choose a date below";
