import React from "react";
import PropTypes from "prop-types";
import { compose } from "recompose";
import { propTypes as ReduxFormPropTypes } from "redux-form";
import { Section } from "@dpdgroupuk/mydpd-ui";
import { withTrack, withTrackProps } from "@dpdgroupuk/react-event-tracker";

import { onCancelSubmit } from "./actions";
import EditPanel from "./components/EditPanel";
import { collectEditValidators } from "../../models/validators";
import { hadleInputFocus, withCollectionEdit } from "./hocs";
import { CANCEL_COLLECTION_COMPONENT } from "../../constants/analytics";

const CancelCollection = ({ onBackClick, handleSubmit, onSubmit, error }) => {
  return (
    <Section title="Cancel Collection">
      <EditPanel
        title={"Please cancel this collection"}
        onBackClick={onBackClick}
        onSubmit={handleSubmit(onSubmit)}
        error={error}
      />
    </Section>
  );
};

CancelCollection.propTypes = {
  onBackClick: PropTypes.any,
  ...ReduxFormPropTypes,
};

export default compose(
  withCollectionEdit({
    validate: collectEditValidators.notificationDetailsValidator,
    onSubmit: onCancelSubmit,
    onSubmitSuccess: async (
      result,
      dispatch,
      { reloadCollectionFn, history }
    ) => reloadCollectionFn(true).then(history.goBack),
  }),
  withTrack(CANCEL_COLLECTION_COMPONENT.LOAD),
  withTrackProps({
    onBackClick: () => CANCEL_COLLECTION_COMPONENT.CLICK_BACK,
    onSubmit: () => CANCEL_COLLECTION_COMPONENT.CLICK_SUBMIT,
    onInputFocus: fieldName => {
      switch (fieldName) {
        case "sms":
          return CANCEL_COLLECTION_COMPONENT.CHANGE_MOBILE_NUMBER;
        case "email":
          return CANCEL_COLLECTION_COMPONENT.CHANGE_EMAIL_ADDRESS;
        case "contactName":
          return CANCEL_COLLECTION_COMPONENT.CHANGE_CONTACT_NAME;
        default:
          break;
      }
    },
  }),
  hadleInputFocus
)(CancelCollection);
