export const COLLECTION_REFERENCE_SEARCH_FORM =
  "COLLECTION_REFERENCE_SEARCH_FORM";
export const COLLECTION_SEARCH_FORM = "COLLECTION_SEARCH_FORM";
export const COLLECTION_SEARCH_FILTER_FORM = "COLLECTION_SEARCH_FILTER_FORM";
export const ADDRESS_LOOKUP_FORM = "ADDRESS_LOOKUP_FORM";
export const DASHBOARD_FORM = "DASHBOARD_FORM";
export const COLLECTION_EDIT_FORM = "CANCEL_COLLECTION_FORM";
export const WATCH_LIST_COLLECTION_FORM = "WATCH_LIST_COLLECTION_FORM";
// Fields
export const Fields = {
  ACCOUNT: "account",
  DATE: "date",
  REFERENCE: "customerReference",
  CALLING_CARD_REFERENCE: "collectionReference",
  COLLECTION_DATE: "collectionDate",
  FROM_DATE: "StartDate",
  TO_DATE: "EndDate",
  EMAIL: "email",
  PHONE: "sms",
  POSTCODE: "postcode",
  PARTIAL_POSTCODE: "partialPostcode",
  ORGANISATION: "organisation",
  PROPERTY: "property",
  STREET: "street",
  LOCALITY: "locality",
  TOWN: "town",
  COUNTY: "county",
  CONTACT_NAME: "contactName",
  COLLECTION_START_DATE: "collectionStartDate",
  COLLECTION_END_DATE: "collectionEndDate",
  CREATE_START_DATE: "createStartDate",
  CREATE_END_DATE: "createEndDate",
};

export const FilterFields = {
  ACCOUNT_CODE: "accountCode",
  COLLECT_DATE: "collectDate",
  COLLECT_TYPE_CODE: "collectTypeCode",
  COLLECT_COUNTRY: "collectCountry",
  OFC_TODAY: "ofcToday",
  SORT_BY: "sortBy",
  COLLECT_POSTCODE: "collectPostcode",
  COLLECTED: "collected",
};
