import React from "react";
import PropTypes from "prop-types";
import { BorderedTable } from "@dpdgroupuk/mydpd-ui";
import { withTrack } from "@dpdgroupuk/react-event-tracker";

import { formatFullAddressString } from "../../../../models/address";
import { CollectionTypes } from "../../../../models/types";
import { getLiability } from "../../../../models/collection";
import { SHIPMENT_DETAILS } from "../../../../constants/analytics";

const columns = [
  {
    text: "Account",
    dataIndex: "accountCode",
    style: {
      width: "82px",
    },
  },
  {
    text: "Reference",
    dataIndex: "customerReference",
    style: {
      width: "97px",
    },
  },
  {
    text: "Delivery address",
    render: ({ address }) => formatFullAddressString(address),
    style: {
      width: "145px",
    },
  },
  {
    text: "Contact",
    render: ({ contact, phone }) => [contact, phone].join("\n"),
    style: {
      width: "140px",
    },
  },
  {
    text: "Items",
    render: ({ quantity, weight }) => [quantity, `${weight}kg`].join("\n"),
    style: {
      width: "70px",
    },
  },
  {
    text: "Service",
    render: ({ productName, serviceName }) =>
      [productName, serviceName].join("\n"),
    style: {
      width: "90px",
    },
  },
  {
    text: "Liability",
    render: getLiability,
    style: {
      width: "80px",
    },
  },
  {
    text: "Notification",
    render: ({ notifyEmail, notifySms }) => [notifyEmail, notifySms].join("\n"),
  },
];
const ShipmentDetails = ({ deliveries }) => (
  <BorderedTable columns={columns} data={deliveries} />
);

ShipmentDetails.propTypes = {
  deliveries: PropTypes.arrayOf(PropTypes.shape(CollectionTypes.Delivery)),
};

export default withTrack(SHIPMENT_DETAILS.LOAD)(ShipmentDetails);
