import { connect } from "react-redux";

import AuthUserProvider from "../../components/AuthUser";
import { getAuthUser } from "./selectors";
import { fetchUser } from "./actions";

const mapStateToProps = state => ({
  initialUser: getAuthUser(state),
});

const mapDispatchToProps = {
  getUserFn: fetchUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthUserProvider);
