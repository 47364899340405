import api from "./api";

export const searchCollections = (query, options) =>
  api.get({
    path: "/collections",
    query,
    options,
  });

export const getCollection = (collectionCode, options) =>
  api.get({
    path: `/collections/${collectionCode}`,
    options,
  });

export const getCollectionActions = (collectionCode, options) =>
  api.get({
    path: `/collections/${collectionCode}/actions`,
  });

export const addCollectionToWatchList = collectionCode =>
  api.post({
    path: `/collections/${collectionCode}/actions/wtc`,
  });

export const removeCollectionFromWatchList = collectionCode =>
  api.delete({
    path: `/collections/${collectionCode}/actions/wtc`,
  });

export const cancelCollection = (collectionCode, { sms, email, contactName }) =>
  api.post({
    path: `/collections/${collectionCode}/actions/can`,
    body: {
      notificationDetails: {
        sms,
        email,
        contactName,
      },
    },
  });

export const changeCollectionDate = (
  collectionCode,
  { sms, email, contactName, collectionDate, instructions }
) => {
  return api.post({
    path: `/collections/${collectionCode}/actions/chd`,
    body: {
      collectionDate,
      instructions,
      notificationDetails: {
        sms,
        email,
        contactName,
      },
    },
  });
};

export const changeSafePlace = (
  collectionCode,
  { sms, email, contactName, collectionDate, safePlaceCode, instructions }
) => {
  return api.post({
    path: `/collections/${collectionCode}/actions/sfp`,
    body: {
      collectionDate,
      safePlaceCode,
      instructions,
      notificationDetails: {
        sms,
        email,
        contactName,
      },
    },
  });
};

export const collectFromNeighbour = (
  collectionCode,
  { sms, email, contactName, collectionDate, addressKey, instructions }
) =>
  api.post({
    path: `/collections/${collectionCode}/actions/cfn`,
    body: {
      collectionDate,
      addressKey,
      instructions,
      notificationDetails: {
        sms,
        email,
        contactName,
      },
    },
  });

export const changeInstructions = (
  collectionCode,
  { sms, email, contactName, instructions }
) => {
  return api.post({
    path: `/collections/${collectionCode}/actions/ins`,
    body: {
      instructions,
      notificationDetails: {
        sms,
        email,
        contactName,
      },
    },
  });
};

export const getCollectionActionDates = (
  { collectionCode, actionCode },
  options
) =>
  api.get({
    path: `/collections/${collectionCode}/actions/${actionCode}/dates`,
    options,
  });

export const getCollectionsPage = (query, options) =>
  api
    .get({
      path: "/collections/page",
      query,
      options,
    })
    .then(({ data: { findByTotal, collections } }) => ({
      data: {
        findByCode: query.findByCode,
        findByTotal,
        collections: query.startPos > findByTotal ? [] : collections,
      },
    }));

export const filterCollections = (query, options) =>
  api.get({
    path: "/collections/filter",
    query,
    options,
  });

export const getDashboardStats = (query, options) =>
  api.get({
    path: "/collections/dashboard/stats",
    query,
    options,
  });

export const getCollectionFindByCodeByType = (type, query, options) =>
  api.get({
    path: `/collections/dashboard/${type}`,
    query,
    options,
  });
