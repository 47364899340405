import ActionTypes from "./actionTypes";

export const initialState = {
  currentUser: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_USER.SUCCESS:
      return { ...state, currentUser: action.payload };
    case ActionTypes.LOGOUT_USER.SUCCESS: {
      return { ...state, currentUser: undefined };
    }
    default:
      return state;
  }
};
