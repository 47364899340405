import React from "react";
import { Redirect, Route } from "react-router";
import { Switch } from "react-router-dom";

import * as routes from "./constants";
import CollectionsSearch from "../pages/CollectionsSearch";
import CollectionsSearchResult from "../pages/CollectionsSearchResult";
import Dashboard from "../pages/Dashboard";
import DashboardCollectionsResult from "../pages/DashboardCollectionsResult";
import CollectionDetails from "../pages/Collection";

export const Routes = () => (
  <Switch>
    <Redirect exact from="/" to={routes.SEARCH} />
    <Route exact strict path={routes.SEARCH} component={CollectionsSearch} />
    <Route
      exact
      strict
      path={routes.COLLECTIONS}
      component={CollectionsSearchResult}
    />
    <Route exact path={routes.DASHBOARD} component={Dashboard} />
    <Route
      exact
      path={`${routes.DASHBOARD}/:collectionType`}
      component={DashboardCollectionsResult}
    />
    <Route path={routes.COLLECTION} component={CollectionDetails} />
  </Switch>
);
