import api from "./api";
import authApi from "./authApi";

export const getMe = () =>
  api.get({
    path: "/auth/me",
  });

export const refreshToken = () =>
  authApi.get({
    path: "/auth/refreshToken",
  });
