import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Title.scss";

const Title = ({ title, containerStyle = {}, children }) => {
  return (
    <div className={classNames("cc-modal-header", containerStyle)}>
      <div>{title}</div>
      {children && children}
    </div>
  );
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  containerStyle: PropTypes.any,
  children: PropTypes.any,
};

export default Title;
