import React from "react";
import PropTypes from "prop-types";

export default function Neighbour({ fill = "#DC0032" }) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 68 68"
      style={{
        enableBackground: "new 0 0 68 68",
      }}
      xmlSpace="preserve"
    >
      <g id="Delivery_x5F_To_x5F_a_x5F_neighbour_x5F_blackred_x5F_pos_x5F_rgb">
        <g id="To_a_Neighbor_2_">
          <rect id="XMLID_2046_" fill="none" width="68" height="68" />
          <path
            fill="#414042"
            d="M8,52.994c-0.552,0-1-0.447-1-1c0-8.822,7.178-16,16-16c3.76,0,7.414,1.33,10.289,3.746
			c0.422,0.355,0.478,0.986,0.122,1.409c-0.355,0.423-0.986,0.477-1.409,0.122c-2.515-2.113-5.712-3.277-9.001-3.277
			c-7.72,0-14,6.28-14,14C9,52.547,8.552,52.994,8,52.994z"
          />
          <path
            fill="#414042"
            d="M25.999,33.434c-0.403,0-0.784-0.247-0.935-0.646c-0.196-0.517,0.064-1.094,0.581-1.29
			c2.949-1.117,4.854-3.862,4.854-6.993c0-4.136-3.364-7.5-7.5-7.5s-7.5,3.364-7.5,7.5c0,3.131,1.905,5.875,4.854,6.993
			c0.516,0.196,0.776,0.773,0.581,1.29s-0.775,0.777-1.29,0.581c-3.675-1.393-6.145-4.954-6.145-8.863c0-5.238,4.262-9.5,9.5-9.5
			s9.5,4.262,9.5,9.5c0,3.909-2.47,7.47-6.145,8.863C26.236,33.413,26.116,33.434,25.999,33.434z"
          />
          <path
            fill="#414042"
            d="M33.265,42.493c-0.445-0.481-0.858-0.881-1.264-1.222l1.286-1.531
			c0.471,0.396,0.944,0.852,1.446,1.396L33.265,42.493z"
          />
          <path
            fill={fill}
            d="M60,52.994c-0.553,0-1-0.447-1-1c0-7.72-6.28-14-14-14c-3.291,0-6.487,1.164-9.002,3.277
			c-0.424,0.354-1.054,0.3-1.409-0.122c-0.355-0.423-0.301-1.054,0.122-1.409c2.875-2.416,6.528-3.746,10.289-3.746
			c8.822,0,16,7.178,16,16C61,52.547,60.553,52.994,60,52.994z"
          />
          <path
            fill={fill}
            d="M42.997,52.408L39.29,48.7c-0.391-0.391-0.391-1.023,0-1.414s1.023-0.391,1.414,0l2.293,2.294
			l6.294-6.294c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414L42.997,52.408z"
          />
          <path
            fill={fill}
            d="M47.998,33.434c-0.403,0-0.783-0.247-0.936-0.646c-0.195-0.517,0.064-1.094,0.581-1.29
			c2.949-1.117,4.854-3.862,4.854-6.993c0-4.136-3.364-7.5-7.5-7.5s-7.5,3.364-7.5,7.5c0,3.131,1.905,5.875,4.854,6.993
			c0.517,0.196,0.776,0.773,0.581,1.29c-0.197,0.517-0.771,0.777-1.29,0.581c-3.675-1.392-6.145-4.954-6.145-8.863
			c0-5.238,4.262-9.5,9.5-9.5s9.5,4.262,9.5,9.5c0,3.909-2.47,7.47-6.146,8.863C48.235,33.413,48.116,33.434,47.998,33.434z"
          />
          <path
            fill={fill}
            d="M30,52.994c-0.552,0-1-0.447-1-1c0-4.737,2.082-9.204,5.711-12.254
			c0.423-0.355,1.053-0.301,1.409,0.122c0.355,0.423,0.301,1.054-0.122,1.409C32.822,43.94,31,47.849,31,51.994
			C31,52.547,30.552,52.994,30,52.994z"
          />
        </g>
      </g>
      <g id="Layer_1" />
    </svg>
  );
}

Neighbour.propTypes = {
  fill: PropTypes.string,
};
