import { compose, withProps } from "recompose";
import { reduxForm } from "redux-form";
import { withOverlay, withPrompt } from "@dpdgroupuk/mydpd-ui";

import { COLLECTION_EDIT_FORM } from "../../../constants/forms";
import { ACTION_CAN_NOT_BE_COMPLETED } from "../../../constants/strings";
import { getNotificationDetails } from "../../../models/collection";
import { omitNilValues } from "../../../utils/object";
import { replaceLinebreak } from "../../../utils/string";

const createEditCollectionPayload = values => {
  const filledValues = omitNilValues(values);
  if (filledValues.instructions) {
    filledValues.instructions = replaceLinebreak(filledValues.instructions);
  }
  return filledValues;
};

export const withCollectionEdit = ({ onSubmit, validate, ...restPrams }) =>
  compose(
    withOverlay,
    withPrompt,
    withProps(({ collection, history, initialCollectionDate }) => ({
      initialValues: {
        collectionDate: initialCollectionDate,
        ...getNotificationDetails(collection),
      },
      onBackClick: () => {
        history.goBack();
      },
    })),
    reduxForm({
      form: COLLECTION_EDIT_FORM,
      validate,
      async onSubmit(values, dispatch, { overlay, match }) {
        overlay.show();
        return dispatch(
          onSubmit(
            match.params.collectionCode,
            createEditCollectionPayload(values)
          )
        ).finally(() => {
          overlay.hide();
        });
      },
      async onSubmitSuccess(
        collectionCode,
        dispatch,
        { history, match, reloadCollectionFn }
      ) {
        match.params.collectionCode === collectionCode &&
          (await reloadCollectionFn(true));
        history.push(`/collections/${collectionCode}`);
      },
      onSubmitFail(errors, dispatch, submitError, props) {
        if (submitError && !errors) {
          props.prompt.showInfo(ACTION_CAN_NOT_BE_COMPLETED);
        }
      },
      ...restPrams,
    }),
    withProps(({ submitFailed, invalid }) => ({
      error: submitFailed && invalid && "Please complete all required fields",
    }))
  );
