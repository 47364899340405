import FetchClient from "@dpdgroupuk/fetch-client";

import { getApiOrigin } from "../utils/config";
import { refreshToken } from "./auth";

export const APIS_ORIGIN_URL = getApiOrigin();

export default new FetchClient({
  mode: "cors",
  credentials: "include",
  baseUrl: APIS_ORIGIN_URL,
  async onRetryError(retry, err) {
    if (err.statusCode === 401) {
      return refreshToken()
        .then(() => {
          return retry();
        })
        .catch(e => {
          throw e;
        });
    }
    throw err;
  },
});
