import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import InputWithTitle from "../../components/InputWithTitle";
import styles from "./FindPostcode.module.scss";

const FindPostcode = ({ onFindClick, classNameInput, ...props }) => (
  <div className={styles.postcode_section}>
    <InputWithTitle
      {...props}
      classNameField={classNames(styles.postcode_field, classNameInput)}
    />
    <p className={styles.or_word}>or</p>
    <button
      className={styles.find_button}
      onClick={onFindClick}
      disabled={props.disabled}
    >
      Find Postcode
    </button>
  </div>
);

export const propTypes = {
  classNameInput: PropTypes.any,
  onFindClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FindPostcode.propTypes = propTypes;

export default FindPostcode;
