import { reset } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { searchAddresses } from "../../apis/location";
import { ADDRESS_LOOKUP_FORM } from "../../constants/forms";

export const ActionTypes = createActionTypes("ADDRESS_LOOKUP", {
  CLEAR: "CLEAR",
  SEARCH: createAsyncActionTypes("SEARCH"),
});

const clear = () => ({
  type: ActionTypes.CLEAR,
});

export const search = createAsyncAction(
  data =>
    searchAddresses({
      ...data,
      searchPageSize: 50,
    }).then(({ data }) => data),
  ActionTypes.SEARCH
);

export const destroy = () => dispatch => {
  dispatch(resetForm());
  dispatch(clear());
};

export const resetForm = () => reset(ADDRESS_LOOKUP_FORM);
