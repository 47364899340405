import { branch, renderComponent } from "recompose";

import NoResultsView from "./NoResultsView";
import CollectionListView from "./CollectionListView";
export {
  CollectionListColumns,
  CollectionListPropTypes,
} from "./CollectionList";
export { NoResultsView };
export { default as withCollectionHandlers } from "./withCollectionHandlers";
export { default as withCollectionAnalytics } from "./withTrackAnalytics";
export { default as reducer } from "../../redux/collections/reducer";

export default branch(
  ({ collections }) => collections.length === 0,
  renderComponent(NoResultsView)
)(CollectionListView);
