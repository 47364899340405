import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

const ErrorAlert = ({ error, onReportFeedbackClick }) => (
  <Alert variant="danger">
    <Alert.Heading>{error.name}</Alert.Heading>
    <p>{error.message}</p>
    {onReportFeedbackClick && (
      <>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={onReportFeedbackClick} variant="outline-danger">
            Report feedback
          </Button>
        </div>
      </>
    )}
  </Alert>
);

ErrorAlert.propTypes = {
  error: PropTypes.instanceOf(Error),
  onReportFeedbackClick: PropTypes.func,
};

export default ErrorAlert;
