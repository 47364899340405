import { Fields } from "../../constants/forms";
import { isDateWithinLast10Months, isValidDate } from "./date";

export default values => {
  const errors = {};

  const date = values[Fields.DATE];
  if (date) {
    if (!isValidDate(date)) {
      errors[Fields.DATE] = "Please enter a valid value for From Date";
    }
    if (!isDateWithinLast10Months(date)) {
      errors[Fields.DATE] = "Please enter a date within the last 10 months";
    }
  }

  return errors;
};
