import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";

import { collectionApi } from "../../apis";

export const ActionTypes = createActionTypes("COLL_VIEW", {
  GET_COLLECTION: createAsyncActionTypes("GET_COLLECTION"),
  GET_COLLECTION_ACTIONS: createAsyncActionTypes("GET_COLLECTION_ACTIONS"),
});

export const fetchCollection = createAsyncAction(
  collectionApi.getCollection,
  ActionTypes.GET_COLLECTION
);

export const fetchCollectionActions = createAsyncAction(
  collectionApi.getCollectionActions,
  ActionTypes.GET_COLLECTION_ACTIONS
);
