import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Field } from "redux-form";

import InputWithTitle from "../../../../components/InputWithTitle";
import { ReduxifiedDayPicker } from "../../../../components/DayPicker";
import styles from "./DayPickerWithInstruction.module.scss";
import { INSTRUCTION_PLACEHOLDER } from "../../../../constants/strings";

const DayPickerWithInstruction = ({
  dates,
  title,
  defaultHeaderDate,
  className,
}) => (
  <div className={classNames(styles.day_picker_with_instruction, className)}>
    {dates && dates.length > 0 && (
      <div>
        <div className={styles.title}>{title}</div>
        <Field
          name={"collectionDate"}
          component={ReduxifiedDayPicker}
          values={dates}
          defaultHeaderDate={defaultHeaderDate}
        />
      </div>
    )}
    <Field
      name={"instructions"}
      classNameField={styles.instruction}
      component={InputWithTitle}
      multiline
      maxLength={200}
      rows="4"
      placeholder={INSTRUCTION_PLACEHOLDER}
    />
  </div>
);

DayPickerWithInstruction.propTypes = {
  selected: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.any,
  title: PropTypes.string,
  defaultHeaderDate: PropTypes.string,
};

DayPickerWithInstruction.defaultProps = {
  title: "Please collect the parcel on the following date",
};

export default DayPickerWithInstruction;
