import { createSelector } from "reselect";

import { COLLECTION_EDIT_FORM } from "../../constants/forms";

export const getCollectionEdit = state => state.ui.collEdit;

export const getActionDates = createSelector(
  getCollectionEdit,
  ({ dates }) => dates
);

export const getNeighbourAddresses = createSelector(
  getCollectionEdit,
  ({ addresses }) => addresses
);

export const getCollectionFormState = state =>
  state && state.form && state.form[COLLECTION_EDIT_FORM];

export const getFoucusedCollectionFormField = createSelector(
  getCollectionFormState,
  state => state && state.active
);
