import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Popover } from "@dpdgroupuk/mydpd-ui";

import RadioInput from "../../../../components/RadioInput";
import styles from "./TermsAndConditions.module.scss";

const TermsAndConditions = ({ meta, description, ...inputProps }) => (
  <Popover
    align={"start"}
    isOpen={meta.error && meta.touched}
    content={meta.error}
  >
    <div className={styles.agreement}>
      <RadioInput {...inputProps} classNameLabel={styles.text} />
      <div className={classNames(styles.text_container, styles.text)}>
        {description}
      </div>
    </div>
  </Popover>
);

TermsAndConditions.propTypes = {
  meta: PropTypes.object,
  description: PropTypes.oneOf([PropTypes.element, PropTypes.string]),
};

export default TermsAndConditions;
